import { Button, Grid, Hidden } from '@mui/material';
import React from 'react';
import LinkedappBackend from '../../../../services/LinkedappBackend';
import { AuthContext, TemplateElement, TemplateElementConfigKey } from '../../../../types/apimodel';
import { extractFilenameFromAnswerElement, extractValueFromAnswerElement, findValueInConfig, log } from '../../../../util/util';
import GetAppIcon from '@mui/icons-material/GetApp';

declare global {
    interface Navigator {
        msSaveOrOpenBlob: any
    }
}


interface AnswerElementPdfDocumentProps {
    el: TemplateElement,
    linkId: string | null,
    authContext: AuthContext | null,
    value: string | null
}
interface AnswerElementPdfDocumentState {
    label: string|null,
    value: string | '',
    error: string | null,    
    isFetching: boolean,
    document: any,
    filename: string | null,
    embeddedPdfAsBlob: Blob | null,
    manualDownload: boolean | true
}
class AnswerElementPdfDocument extends React.Component<AnswerElementPdfDocumentProps, AnswerElementPdfDocumentState> {

    constructor(props:any) {
        super(props);
        this.downloadPdf = this.downloadPdf.bind(this);
        this.fetchFromServer = this.fetchFromServer.bind(this);
        this.downloadButtonClick = this.downloadButtonClick.bind(this);

        let value = this.props.value || '';
        let document: any = null;
        let error:string|null = null;
        let filename: string|null = null;
        let label:string|null = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.LABEL]);
        if (label === '') {
            label = null;
        }
        let manualDownload:boolean = "true" === findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.DOCUMENT_MANUAL_DOWNLOAD]);

        this.state = {label: label, value: value, error: error, isFetching: false, filename: filename, document: document, embeddedPdfAsBlob: null, manualDownload: manualDownload};

    }

    /**
     * Wandelt das Base-64 codierte Dokument vom Server in Bytes zurück und
     * macht dann daraus einen Blob, damit man daraus wiederum eine Object-URL
     * machen kann. 
     * Dies ist z.Z. die beste Möglichkeit, große Dokumente
     * im Browser darzustellen. Data-URLS sind in verschiedenen Browsern
     * (Chrome, Safari) in der größe Begrenzt.
     * 
     * @param base64Document 
     * @returns 
     */
    base64DocumentToBlob(base64Document:string):Blob {
        var byteCharacters = atob(base64Document);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' });
        return blob;
    }    

    fetchFromServer(linkId:string, elementExternalid:string, authContext:AuthContext):Promise<boolean> {
        return new Promise((resolve) => {
            this.setState({isFetching: true}, () => {
                LinkedappBackend.getDeferredLinkPart(linkId, elementExternalid, authContext).then((data) => {
                    if (data !== null) {
                        let document:string = extractValueFromAnswerElement(data.answerElement);
                        let filename:string | null = extractFilenameFromAnswerElement(data.answerElement) || null;
                        let embeddedPdfAsBlob = this.base64DocumentToBlob(document);
                        this.setState({document: document, filename: filename, embeddedPdfAsBlob: embeddedPdfAsBlob, isFetching: false});
                    } else {
                        this.setState({isFetching: false});
                    }
                    resolve(true);
                }).catch((error) => {
                    log('Fehler beim Holen des PDFs: ' + JSON.stringify(error));
                    let errorMsg = error.message ? error.message : 'Leider ist ein Fehler beim Laden des PDFs aufgetreten.';
                    this.setState({error: errorMsg, isFetching: false});
                    resolve(false);
                });
            });
        });
    }

    downloadButtonClick() {
        if (this.state.manualDownload) {
            if (this.props.linkId !== null && this.props.authContext !== null) {
                this.fetchFromServer(this.props.linkId, this.props.el.externalid, this.props.authContext).then((success:boolean) => {
                        if (success === true) {
                            this.downloadPdf();
                        }
                    }
                );
            } 
        } else {
            this.downloadPdf();
        }

    }

    downloadPdf() {
        if (this.state.embeddedPdfAsBlob === null) {
            return;
        }
        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        const fileName = this.state.filename != null ? this.state.filename : "dokument.pdf";
        const blob = this.state.embeddedPdfAsBlob;
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            return;
        }
        // Echte Browser
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
    }

    componentDidMount() {
        if (this.props.linkId !== null && this.props.authContext !== null && !this.state.manualDownload) {
            this.fetchFromServer(this.props.linkId, this.props.el.externalid, this.props.authContext);
        }
    }

    render() {    
         
        if (this.state && !this.state.isFetching && this.state.document && this.state.document.length > 0) {
            return (
                <Grid container spacing={3} className='control-wrapper'>
                    <Grid item xs={12}>
                        {this.state.label !== null && this.state.label}
                    </Grid>
                    <Grid item xs={12} className="center">
                        <Button onClick={this.downloadButtonClick} endIcon={<GetAppIcon />} title="Dokument als PDF herunterladen">Dokument herunterladen</Button>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={12} className="center">
                        {this.state.embeddedPdfAsBlob !== null &&
                            <object className="pdf-viewer" data={URL.createObjectURL(this.state.embeddedPdfAsBlob)} width="100%" height="100%">
                                <embed src={URL.createObjectURL(this.state.embeddedPdfAsBlob)}  width="100%" height="100%" />
                            </object>
                        }
                        </Grid>
                    </Hidden>
                </Grid>
            );
        } else if (!this.state.isFetching && this.state.manualDownload === true) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {this.state.label !== null && this.state.label}
                    </Grid>
                    <Grid item xs={12} className="center">
                        <Button onClick={this.downloadButtonClick} endIcon={<GetAppIcon />} title="Dokument als PDF herunterladen">Dokument herunterladen</Button>
                    </Grid>
                </Grid>
            )
        } else if (this.state.error !== null) {
            return (
                <div className="validation-error">
                    <p className="bold">Leider ist folgender Fehler aufgetreten:</p>
                    {this.state.error}
                    <p className="bold">Bitte versuchen Sie es zu einem späteren Zeitpunk noch einmal.</p>
                </div>
            );
        } else {
            return (
                <div><p>Ihr PDF wird geladen...</p><div className="spinner"></div></div>
            );
        }
    }
}
export default AnswerElementPdfDocument