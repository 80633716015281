import { Box, TextField, Grid } from '@mui/material';
import React from 'react';
import { BankData, ClientFieldValidationResult, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultProps, AnswerElementDefaultState } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';

interface AnswerElementBankDataProps extends AnswerElementDefaultProps {
    serverValidation: any,
}

interface AnswerElementBankDataState extends AnswerElementDefaultState {
    value: BankData,
    ibanError: string | null,
    bicError: string | null,
    bicEnabled: boolean,
    accountHolderNameError: string | null,
    hasServerValidation: boolean | false,
}

class AnswerElementBankData extends React.Component<AnswerElementBankDataProps, AnswerElementBankDataState> {
    
    constructor(props:any) {
        super(props);
        let value:BankData =  {accountHolderName: '', bic: '', iban: '', bankName: ''};
        if (this.props.value !== null && this.props.value.length > 0) {
            let parsed: BankData = JSON.parse(this.props.value);
            if (parsed !== null) {
                value = parsed;
            }
        }
        let hasServerValidation:boolean = Boolean(this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.SERVER_VALIDATED]) || false);
        this.state = {value: value, ibanError: null, bicError: null, accountHolderNameError: null, error: null, bicEnabled: !hasServerValidation, hasServerValidation: hasServerValidation};
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.keyPress = this.keyPress.bind(this);
        this.prefillBic = this.prefillBic.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    render() {    
        if (this.state && this.props.el) {
            return (
                <Grid container spacing={3} className='control-wrapper'>
                    <Grid item xs={12} >
                        {this.state.error !== null && <Box sx={{color: '#ff0000'}}>{this.state.error}</Box>}
                    </Grid>
                    <Grid item xs={12} >
                        <TextField label="IBAN" type="text" value={this.state.value.iban} required={this.props.el.mandatory}
                        onChange={this.handleChange} onBlur={this.onBlur} id={'iban-'+this.props.el.externalid}
                        error={(this.state.ibanError && this.state.ibanError !== null) || false} helperText={this.state.ibanError} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress} />
                    </Grid>
                    {(this.state.bicEnabled || this.props.readonly) && <Grid item xs={12} className="fade-in">
                        <TextField label="BIC" type="text" value={this.state.value.bic} required={this.props.el.mandatory}
                        onChange={this.handleChange} onBlur={this.onBlur} id={'bic-'+this.props.el.externalid}
                        error={(this.state.bicError && this.state.bicError !== null) || false} helperText={this.state.bicError} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress} />
                    </Grid>}
                    {(this.state.value.bankName !== null && this.state.value.bankName !== '') && <Grid item xs={12} className="fade-in">
                        <Box>Institut: {this.state.value.bankName}</Box>
                    </Grid>}
                    <Grid item xs={12} >
                        <TextField label="Kontoinhaber" type="text" value={this.state.value.accountHolderName} required={this.props.el.mandatory}
                        onChange={this.handleChange} onBlur={this.onBlur} id={'accountHolderName-'+this.props.el.externalid}
                        error={(this.state.accountHolderNameError && this.state.accountHolderNameError !== null) || false} helperText={this.state.accountHolderNameError} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress} />
                    </Grid>
                </Grid>
        );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Benötigt, weil ansonsten im Eingabefeld beim Druck auf Enter nicht onBlur() gefeuert und damit 
     * die Validierung und die Übernahme des Wertes nicht funktionieren.
     * 
     * @param event 
     * 
     */
    keyPress(event:any):any {
        if(event.key === 'Enter'){
            event.target.blur(); 
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    /**
     * Wenn die IBAN gefüllt und syntaktisch richtig ist, aber die BIC leer ist: 
     * probieren, diese über die Servervalidierung zu ziehen.
    */
    prefillBic(bankData:BankData, ibanValid:boolean):Promise<BankData> {
        return new Promise((resolve) => {
            if (ibanValid && bankData.bic === '') {
                this.props.serverValidation(this.props.el.externalid, JSON.stringify(this.state.value)).then((result:ClientFieldValidationResult) => {
                    let bic = result.payload?.value.find(kv => kv.key === 'bic' && kv.value !== null)?.value;
                    let bankName = result.payload?.value.find(kv => kv.key === 'bank' && kv.value !== null)?.value;

                    if (bic !== undefined && bic !== null) {
                        bankData.bic = bic;
                    }
                    if (bankName !== undefined && bankName !== null) {
                        bankData.bankName = bankName;
                    }
                    resolve(bankData);
                });
            } else {
                resolve(bankData);
            }
        });
    }

    /**
     * Validiert ein einzelnes Feld.
     * 
     * @param id 
     * @returns 
     */
    validateField(id:string):Promise<boolean> {
    
        let value:BankData = this.state.value;            
        let ibanRegex = new RegExp('^(DE){1}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?$');
        let bicRegex = new RegExp('^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$');

        return new Promise((resolve) => {
            if (id === 'iban-'+this.props.el.externalid) {
                let ibanValid: boolean = ibanRegex.test(value.iban);
            
                // Wenn die IBAN gefüllt und syntaktisch richtig ist, aber die BIC leer ist: 
                // probieren, diese über die Servervalidierung zu ziehen. Ansonsten steht dort
                // einfach der vorherige Wert drin.
                this.prefillBic(value, ibanValid).then((bankData:BankData) => {
                    value = bankData;                    
                    let valiMsgRegex:string = 'Ungültiger Wert.';
                    let bicEnabled = this.state.bicEnabled;
                    if (bankData.bic.length === 0 && (bankData.iban.length > 0 && ibanValid)) {
                        bicEnabled = true;
                    }
                    this.setState({ibanError: ibanValid ? null : valiMsgRegex, value: value, bicEnabled: bicEnabled}, () => {
                        resolve(ibanValid);
                    });
                });
            } else if (id === 'bic-'+this.props.el.externalid) {
                let bicValid: boolean = bicRegex.test(value.bic);
                let valiMsgRegex:string = 'Ungültiger Wert.';
                        
                this.setState({bicError: bicValid ? null : valiMsgRegex}, () => {
                    resolve(bicValid);
                });

            } else if (id === 'accountHolderName-'+this.props.el.externalid) {
                let accountHolderValid: boolean = value.accountHolderName !== null && value.accountHolderName.trim().length >= 2;
                let valiMsgRegex:string = 'Ungültiger Wert.';

                this.setState({accountHolderNameError: accountHolderValid ? null : valiMsgRegex}, () => {
                    resolve(accountHolderValid);
                });

            } else {
                resolve(true);
            }
        });
    }
    

    /**
     * Validierung des Inputs. Durch die chained promises leider
     * nicht sehr übersichtlich.
     * 
     */
    validate():Promise<boolean> {
        return new Promise((resolve) => {
            let value:BankData = this.state.value;            
            let someValuesPresent: boolean = value.accountHolderName.length > 0 || value.bic.length > 0 || value.iban.length > 0;

            if (someValuesPresent) {
                // Wert vorhanden und Regex vorhanden                
                let valiMsgRegex:string = 'Ungültiger Wert.';
                // Feldvalidierungen nacheinander ausführen, dann die ServerValidierung.
                let ibanValid = this.validateField('iban-'+this.props.el.externalid);
                let bicValid = this.validateField('bic-'+this.props.el.externalid);
                let accountHolderValid = this.validateField('accountHolderName-'+this.props.el.externalid);
                // Auf das Ergebnis aller Feldvalidierungen warten.
                Promise.all([ibanValid, bicValid, accountHolderValid]).then((validValues) => {
                    if (!validValues[0]) {
                        this.setState({error: null, ibanError: valiMsgRegex},() => {
                            resolve(false);
                        });
                    } else if (!validValues[1]) {
                        this.setState({error: null, bicError: valiMsgRegex},() => {
                            resolve(false);
                        });
                    } else if (!validValues[2]) {
                        this.setState({error: null, accountHolderNameError: valiMsgRegex},() => {
                            resolve(false);
                        });
                    } else {
                        // Alle Feldvalidierungen ok.
                        // Nun Server-Validierung, falls vorhanden.
                        this.serverValidation().then((result) => {
                            if (result.valid) {
                                this.setState({error: null, ibanError: null, bicError: null, accountHolderNameError: null},() => {
                                    resolve(true);
                                });
                            } else {
                                this.setState({error: result.error},() => {
                                    resolve(false);
                                });
                            }
                        });
                    }
                });
            } else {
                    // Ansonsten: ok. Nun Server-Validierung, falls vorhanden.
                    this.serverValidation().then((result) => {
                        if (result.valid) {
                            this.setState({error: null, ibanError: null, bicError: null, accountHolderNameError: null},() => {
                                resolve(true);
                            });
                        } else {
                            this.setState({error: result.error},() => {
                                resolve(false);
                            });
                        }
                });
            }
        });
    }

    
    serverValidation():Promise<{valid:boolean, error:string|null}> {
        return new Promise((resolve) => {
            if (this.state.hasServerValidation && this.state.value !== null) {
                this.props.serverValidation(this.props.el.externalid, JSON.stringify(this.state.value)).then( (result:ClientFieldValidationResult) => {
                    resolve({valid:result.valid, error: result.error || null});
                });
            } else {
                resolve({valid:true, error: null});
            }
        });
    }

    /**
     * Validierung der Werte. 
     * Nur, wenn die Validierung erfolgreich war, wird der Wert weiter an den Parent-Handler gereicht.
     * 
     * @param event 
     */
    onBlur(event:any) {
        let value:BankData = this.state.value;
        if (event.target.id === 'iban-'+this.props.el.externalid) {            
            value.iban = event.target.value.trim();
        } else if (event.target.id === 'bic-'+this.props.el.externalid) {
            value.bic = event.target.value.trim();
        } else if (event.target.id === 'accountHolderName-'+this.props.el.externalid) {
            value.accountHolderName = event.target.value.trim();
        }
        let reset:boolean = false;
        // Zurücksetzen
        if (value.iban === null || value.iban === '') {
            value.bic = '';
            value.bankName = '';
            reset = true;
        }
        this.setState({value: value, bicEnabled: reset ? false : this.state.bicEnabled}, () => {
            this.validateField(event.target.id).then( (success) => {
                if (success) {
                    this.props.handleChange(JSON.stringify(value), this.props.el.externalid);
                }
            });        
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {        
        let value:BankData = this.state.value;
        if (event.target.id === 'iban-'+this.props.el.externalid) {            
            value.iban = event.target.value.trim();
        } else if (event.target.id === 'bic-'+this.props.el.externalid) {
            value.bic = event.target.value.trim();
        } else if (event.target.id === 'accountHolderName-'+this.props.el.externalid) {
            value.accountHolderName = event.target.value;
        }
       this.setState({value: value});
    }

}
export default AnswerElementBankData