
import { ReactNode } from "react";
import { AppState } from "../../../../types/types";
import useAnswerCtx from "../useAnswerCtx";
import WizardSteps from "./WizardSteps";

interface WizardProps {
    appState: AppState,
    getSaveButton?: any,
    setPathToPage?: any,
    confirmationPage?: ReactNode,
    submitFormProgrammatically: () => void

}

function Wizard(props:WizardProps) {

    const {state} = useAnswerCtx();


    // Eigentlicher Wizard
    if (state.wizardState.wizardSteps !== undefined && state.wizardState.isWizard === true && state.answer !== undefined && state.answer !== null) {
        return (
            <>
                <WizardSteps 
                    wizardState={state?.wizardState} 
                    answerComplete={state?.answerComplete}
                    appState={props?.appState}
                    setPathToPage={props?.setPathToPage}
                    getSaveButton={props?.getSaveButton}
                    confirmationPage={props?.confirmationPage}
                    submitFormProgrammatically={props?.submitFormProgrammatically} />
            
        </>)
        } else {
            return <></>;
        }
    }

export default Wizard