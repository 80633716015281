import React from 'react';
import { TemplateElementConfigKey } from '../../../../types/apimodel';
import { findValueInConfig, log } from '../../../../util/util';
import { AnswerElementDefaultProps, AnswerElementDefaultState } from '../../../../types/types';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface AnswerElementTextInputProps extends AnswerElementDefaultProps {
    type: string | 'boolean',
}

interface AnswerElementTextInputState extends AnswerElementDefaultState {
    value: Date | null
}

class AnswerElementTextInput extends React.Component<AnswerElementTextInputProps, AnswerElementTextInputState> {
    constructor(props:any) {
        super(props);
        this.state = {value: this.getInput(this.props.value), error: null};
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.onBlur = this.onBlur.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.getInput = this.getInput.bind(this);
    }

    render() {    
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    <KeyboardDatePicker
                        margin="normal"
                        id={this.props.el.externalid}
                        onBlur={this.onBlur}
                        onClose={this.onBlur}
                        className="datepicker"
                        label={this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL])}
                        format="dd.MM.yyyy"                    
                        error={(this.state.error && this.state.error !== null) || false}
                        helperText={this.state.error}
                        required={this.props.el.mandatory}
                        disabled={this.props.readonly}
                        value={this.state.value}
                        onKeyPress={this.keyPress}
                        onChange={this.handleChange}
                        KeyboardButtonProps={{
                            'aria-label': `${this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL])}`,
                        }}
                    />
                </div>
            );
        } else {
            return (<div></div>);
        }
    }

    /**
     * Benötigt, weil ansonsten im Eingabefeld beim Druck auf Enter nicht onBlur() gefeuert und damit 
     * die Validierung und die Übernahme des Wertes nicht funktionieren.
     * 
     * @param event 
     * 
     */
     keyPress(event:any):any {
        if(event.key === 'Enter'){
            event.target.blur(); 
        }
    }

    /**
     * Wert aus dem State parsen und zurückgeben.
     * 
     * W@param el 
     */
    getInput(str:string|null):Date|null {
        // Wenn ISO: konvertieren. Ansonsten zurückgeben.            
        //let str:string = extractValueFromAnswerElement(el);
        if (str !== null && str.length === 24 ) {
            let dnumber = Date.parse(str);
            let date:Date|null = new Date(dnumber);
            if (date) {
                return date;
            }
        }
        return null;
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    /**
     * Validierung des Inputs.
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {            
            let value:Date | null = this.state.value;
            let required = this.props.el.mandatory || false;
            //let error: boolean = false;
            if (!value && required) {
                log('!value && required');
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]) || 'Bitte geben Sie einen Wert an.';
                this.setState({error: valiMsgMandatory}, () => {
                    //error = true;
                    resolve(false);
                });
            } else if (value && value.toString() === 'Invalid Date') {                
                log('Invalid Date');
                //error = true;
                this.setState({error: 'Bitte geben Sie ein Datum im Format TT.MM.JJJJ an.'}, () => {
                    resolve(false);
                });
            } else {
               // error = false;
                this.setState({error: null}, () => {
                    resolve(true);
                });
            }
            /*
            // Nötig, weil die setState() asynchron ist, und wir trotz error hier landen können.
            if (error === false) {
                log('error === false');
                try {
                    let date:Date|null = value;
                    if  (!(date && date.toISOString())) {
                        log('!(date && date.toISOString())');
                        error = true;
                        this.setState({error: 'Ungültiges Datum!'}, () => {
                            resolve(false);
                        });
                    }
                } catch (e) {
                    log('catch');
                    error = true;
                    this.setState({error: 'Ungültiges Datum!'}, () => {
                        resolve(false);
                    });
                }
                if (error === false) {
                    this.setState({error: null}, () => {
                        this.props.handleChange(this.props.el);
                        resolve(true);
                    });
                }
            }*/
        });
    }

    /**
     * Das Datum kann erst beim Verlassen des Feldes sinnvoll validiert und der State ans Parent
     * gereicht werden. 
     * 
     * @param event 
     */
    onBlur(event?:any) {
        this.validate().then( (success) => {
            if (success) {
                let isoDateStr:string = (this.state.value !== null && this.state.value.toISOString()) || '';                
                this.props.handleChange(isoDateStr, this.props.el.externalid);
            }
        });        
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(date:Date | null) {        
        this.setState({value: date}, () => {
            this.onBlur();
        });
        
    }

}
export default AnswerElementTextInput