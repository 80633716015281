import { AppState, DefaultProps } from "../../../types/types"

export interface ConfirmErrorProps extends DefaultProps {
    appState:AppState
}

function ConfirmError(props:ConfirmErrorProps) {
    return (
        <>
                <h3>Da ist etwas schiefgegangen.</h3>
                <p>Wir haben keine ausstehende Bestätigung gefunden - wir sehen uns das an.</p>
                <p>Sie können dieses Fenster nun schließen.</p>
        </>
    );
}

export default ConfirmError