import {
  CheckAnswerCompleteRequest, CheckAnswerCompleteResponse,
  GetContactConfirmationRequest,
  GetContactConfirmationResponse,
  GetContactInformationConfirmedRequest,
  GetDeferredLinkPartRequest,
  GetDeferredLinkPartResponse,
  GetFieldValidationResultRequest,
  GetFieldValidationResultResponse,
  GetKundenpostfachLinkRequest,
  GetKundenpostfachLinkResponse,
  GetLinkRequest,
  GetLinkResponse,
  GetShortLinkRequest,
  GetShortLinkResponse,
  IsTosAcceptedRequest,
  IsTosAcceptedResponse,
  SaveContactConfirmationRequest,
  SaveContactConfirmationResponse,
  SaveLinkRequest,
  SaveLinkResponse,
  SaveLinkTemporaryRequest,
  SaveLinkTemporaryResponse,
  SendContactConfirmationRequest,
  SendContactConfirmationResponse,
  SendTosAcceptedRequest,
  SendTosAcceptedResponse,
  SendTransactionConfirmationRequest,
  SendTransactionConfirmationResponse
} from './apimodel';

export class LinkedAppJSONHTTPBinding {
  baseURL: URL;
  basePath: string = '';


  public constructor(basePath: string) {
    this.baseURL = new URL(window.document.URL);
    this.basePath = basePath;
  }

  public getLink(arg0: GetLinkRequest): Promise<GetLinkResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getLink', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public checkAnswerComplete(arg0: CheckAnswerCompleteRequest): Promise<CheckAnswerCompleteResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/checkAnswerComplete', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res =>
        {
          return res.json().then(json => {
            return res.ok ? json : Promise.reject(json);
          });
        }
    );
  }

  public getKundenakteRedirectURL(): Promise<string> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/kundenakte/redirect', this.baseURL);

    return fetch(url.toString(), {method: 'POST'}).then(res => 
      {
        return res.text().then(text => {
          return res.ok ? text : Promise.reject(text);
        });
      }
    );
  }

  public getShortLink(arg0: GetShortLinkRequest): Promise<GetShortLinkResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getShortLink', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public getDeferredLinkPart(arg0: GetDeferredLinkPartRequest): Promise<GetDeferredLinkPartResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getDeferredLinkPart', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public saveLink(arg0: SaveLinkRequest): Promise<SaveLinkResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/saveLink', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public saveLinkTemporary(arg0: SaveLinkTemporaryRequest): Promise<SaveLinkTemporaryResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/saveLinkTemporary', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public sendContactConfirmation(arg0: SendContactConfirmationRequest): Promise<SendContactConfirmationResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/sendContactConfirmation', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public sendTosAccepted(arg0: SendTosAcceptedRequest): Promise<SendTosAcceptedResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/tos/sendTosAccepted', this.baseURL);

    return  fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }
  
  public sendIsTosAccepted(arg0: IsTosAcceptedRequest): Promise<IsTosAcceptedResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/tos/IsTosAccepted', this.baseURL);

    return  fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public getContactConfirmation(arg0: GetContactConfirmationRequest): Promise<GetContactConfirmationResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getContactConfirmation', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public pullKundenakteLink(arg0: GetKundenpostfachLinkRequest): Promise<GetKundenpostfachLinkResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/kundenpostfach/link', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }


  public getContactInfoConfirmed(arg0: GetContactInformationConfirmedRequest): Promise<GetContactConfirmationResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getContactInfoConfirmed', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public saveContactConfirmation(arg0: SaveContactConfirmationRequest): Promise<SaveContactConfirmationResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/saveContactConfirmation', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }
  
  public getFieldValidationResult(arg0: GetFieldValidationResultRequest): Promise<GetFieldValidationResultResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/getFieldValidationResult', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }

  public sendTransactionConfirmation(arg0: SendTransactionConfirmationRequest): Promise<SendTransactionConfirmationResponse> {
    const url = new URL(this.basePath + '/services/linkedapp/rest/api/sendTransactionConfirmation', this.baseURL);

    return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(arg0)}).then(res => 
      {
        return res.json().then(json => {
          return res.ok ? json : Promise.reject(json);
        });
      }
    );
  }
  

}

