import { Box, Grid, Button } from '@mui/material';
import IconCheck from './../../../assets/images/check.svg';

import { AppState, DefaultProps } from '../../../types/types';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { KEY_AUTH_CONTEXT, loadSessionData } from '../../../util/store';
import LinkedappBackend from '../../../services/LinkedappBackend';
import { AuthContext } from '../../../types/apimodel';
import { log } from '../../../util/util';


export interface ContactConfirmationThankYouProps extends DefaultProps {
    appState: AppState
}

function ContactConfirmationThankYou(props: ContactConfirmationThankYouProps) {
    //const history = useHistory();

    /* const underline: CSS.Properties = {
         textDecoration: 'underline'
     };
 
     const isSmallScreen = false; // useMediaQuery(theme.breakpoints.down("md"));
     let windowUrl = window.location.search;
     let params = new URLSearchParams(windowUrl);
     let linkId:string = params.get('linkid') || '';
     let cid:string = params.get('cid') || '';*/

    const [postfachLink, setPostfachLink] = useState(undefined as string | undefined);
    let windowUrl = window.location.search;

    let params = new URLSearchParams(windowUrl);
    let clientemail: string = params.get('clientemail') || '';

    let alleDatenAktualisiert = (params.get('alleDatenAktualisiert') || '') === 'true';
    let postfachButtonAnzeigen = false;

    const openPostfachLink = () => {
        window.location.href = postfachLink + "";
    };


    const pullKundenakteLink = () => {
        if (!postfachLink) {
            let linkid = loadSessionData('linkid');
            if (!linkid) {
                linkid = params.get('linkid') || '';
            }
            let authContext: AuthContext = loadSessionData(KEY_AUTH_CONTEXT);
            if (postfachButtonAnzeigen) {
                LinkedappBackend.pullKundenakteLink(linkid, authContext).then((data) => {
                    log("Retrieved data: ", data);
                    setPostfachLink(data.kundenpostfachlink);
                }).catch(error => {
                    console.log('error in getCustomerContactStatus: ' + JSON.stringify(error));
                    //TODO unauthenticate if 401
                });
            }
        }
    }

    useEffect(() => {
        pullKundenakteLink();
        // Interval setzen, um die Methode alle 10 Sekunden aufzurufen
        const intervalId = setInterval(pullKundenakteLink, 10000);
        // Cleanup-Funktion für das Aufräumen bei Komponenten-Unmount
        return () => {
            clearInterval(intervalId);
        };
    });

    return <Box sx={{ padding: 3 }}>

        <Grid container spacing={3}>
            <Grid item xs={12} className="answer-element-heading" sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                paddingBottom: '1rem',

            }}>
                <div>Herzlichen Glückwunsch</div>
            </Grid>
        </Grid>
        <br />

        <div className='control-wrapper'>
            {!alleDatenAktualisiert && <Grid container spacing={2} className='control-wrapper underline'
                justifyContent="space-between" alignItems="center"
                sx={{
                    background: props.appState?.customizing?.colors?.backgroundColor || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    paddingBottom: '10px',
                    marginBottom: '17px',
                    borderBottom: '1px solid ' + props.appState?.customizing?.colors?.primaryContrast
                }}>
                <Grid item xs={10} sm={10} md={10} lg={10} >
                    <Box>
                        Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.
                        <br />
                        <b>{clientemail}</b>
                    </Box>

                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} alignItems="center" justifyContent="center">

                    <Box component="img" sx={{
                        width: { xs: '15px', sm: '15px', md: '15px', lg: '15px' }, marginLeft: '1rem', marginRight: '0.5rem', alignItems: 'center',
                        justifyContent: 'center', display: 'flex'
                    }} alt="Postfach" src={IconCheck}></Box>

                </Grid>

            </Grid>}

            {alleDatenAktualisiert && <Grid container spacing={2} className='control-wrapper underline'
                justifyContent="space-between" alignItems="center"
                sx={{
                    background: props.appState?.customizing?.colors?.backgroundColor || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    paddingBottom: '10px',
                    marginBottom: '17px',
                    borderBottom: '1px solid ' + props.appState?.customizing?.colors?.primaryContrast
                }}>
                <Grid item xs={10} sm={10} md={10} lg={10} >
                    <Box>
                        Sie haben Ihre Kontaktdaten erfolgreich aktualisiert.
                        <br />
                       
                    </Box>

                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} alignItems="center" justifyContent="center">

                    <Box component="img" sx={{
                        width: { xs: '15px', sm: '15px', md: '15px', lg: '15px' }, marginLeft: '1rem', marginRight: '0.5rem', alignItems: 'center',
                        justifyContent: 'center', display: 'flex'
                    }} alt="Postfach" src={IconCheck}></Box>

                </Grid>

            </Grid>}



            {!alleDatenAktualisiert && <Grid container spacing={2} className='control-wrapper'
                justifyContent="space-between" alignItems="center"
                sx={{
                    background: props.appState?.customizing?.colors?.backgroundColor || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
                    paddingBottom: '10px',
                }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box>
                        Sie haben Ihr Digitales Postfach erfolgreich aktiviert. Ab sofort erhalten Sie Ihre Dokumente schnell, einfach und direkt.<br />
                        In Kürze erhalten Sie eine E-Mail mit Ihrem direkten Zugang zu ihrem Digitalen Postfach.
                    </Box>
                </Grid>



                <Grid item xs={12} sm={12} md={5} lg={5}>
                    {postfachButtonAnzeigen && <Box>
                        {postfachLink && <Button onClick={openPostfachLink}
                            type="submit"
                            sx={{
                                minWidth: '200px',
                                maxWidth: '200px',
                                maring: 'auto',

                                float: { xl: 'right', lg: 'right', md: 'right' },
                                marginRight: { xl: '10px', lg: '10px', md: '10px' },
                            }}
                        >ZUM POSTFACH</Button>}
                        <br />
                        <br />
                        {!postfachLink && <Button onClick={openPostfachLink}
                            disabled={true}
                            type="submit"
                            sx={{
                                minWidth: '200px',
                                maxWidth: '200px',
                                maring: 'auto',
                                float: { xl: 'right', lg: 'right', md: 'right' },
                                marginRight: { xl: '10px', lg: '10px', md: '10px' },
                            }}
                        >   <CircularProgress /></Button>}

                    </Box>}
                </Grid>

            </Grid>}

        </div>


    </Box>
}

export default ContactConfirmationThankYou;