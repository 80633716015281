import { Box, FormControl, FormControlLabel, MenuItem, Select, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig, log } from '../../../../util/util';
import DOMPurify from 'dompurify';

interface AnswerElementSingleChoiceProps extends AnswerElementDefaultProps {
    type: string | 'radio',
}

interface AnswerElementSingleChoiceState extends AnswerElementDefaultState {
    value: string | null,
    choices: SelectItem[],
    type: string
}

/**
 * 
 * Stellt eine Auswahlmöglichkeit bereit (entweder als Radiobutton (type="radio") oder als Dropdown (type="select")), aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */
class AnswerElementSingleChoice extends React.Component<AnswerElementSingleChoiceProps, AnswerElementSingleChoiceState> {
    
    constructor(props:any) {
        super(props);
        let value = this.props.value; 
        let type:string = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.SELECT_TYPE]) || this.props.type;
        let choices:SelectItem[] = this.findChoicesInConfig();
        this.state = {value: value, error: null, type: type, choices: choices};
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
    }

    /**
     * In Material-UI ist keine Validierungsnachricht für Checkboxen vorgesehen.
     * Wir wollen aber trotzdem, dass bei einer nicht ausgefüllten Pflich-Checkbox eine Nachricht angezeigt wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && (this.state.value === null || this.state.value.length < 1)) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }

    render() {    
        let errorMsg = this.getErrorMessage();
        let label = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL]);
        let cleanLabel:string = (label !== undefined && label !== null) ? DOMPurify.sanitize( label, { ADD_ATTR: ['target'] } ) : '';
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    {/* Falls Typ=Radio-Group */}
                    {
                        this.state.type === "radio" && 
                        <>
                            <div id="label" dangerouslySetInnerHTML={{__html: cleanLabel}}/><br />
                            <FormControl component="fieldset" className="radio" disabled={this.props.readonly === true}>
                                <RadioGroup aria-label="select" value={this.state.value} >
                                    {this.state.choices.map(element => {
                                    let elLabel = (element.label !== undefined && element.label !== null) ? DOMPurify.sanitize( element.label, { ADD_ATTR: ['target'] } ) : '';
                                    return (<FormControlLabel key={element.value} value={element.value} control={<Radio />} label={<Box component="span" className="innerlabel" dangerouslySetInnerHTML={{__html: elLabel}} />} onChange={this.handleChange} className="outerlabel" sx={{paddingBottom:{xs: 'inherit', md:'inherit'}}} />);
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </>
                    }
                    {/* Falls Typ=Dropdown */}
                    {
                        this.state.type !== "radio" && 
                        <FormControl variant="standard" className="select">
                            <div id="demo-simple-select-standard-label" dangerouslySetInnerHTML={{__html: cleanLabel}}/><br />
                            <Select labelId="label" id="select" value={this.state.value} onChange={this.handleChange} className="select" disabled={this.props.readonly === true} >
                                {this.state.choices.map(element => {
                                    let elLabel = (element.label !== undefined && element.label !== null) ? DOMPurify.sanitize( element.label, { ADD_ATTR: ['target'] } ) : '';
                                    return (<MenuItem key={element.value} value={element.value}><Box component="span" dangerouslySetInnerHTML={{__html: elLabel}} /></MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    }
                    {errorMsg}
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig():SelectItem[] {
        let items:SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {
            if (this.props.el.mandatory === true && (this.state.value === null || this.state.value.length < 1)) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
                if (valiMsgMandatory) {
                    this.setState({error: valiMsgMandatory}, () => {
                        log('AnswerElementSingleChoice: valid resolve false');
                        resolve(false);
                    });
                } else {
                    this.setState({error: 'Bitte wählen Sie eine Option aus!'}, () => {
                        log('AnswerElementSingleChoice: valid resolve false');
                        resolve(false);
                    });
                }
            } else {
                this.setState({error: null}, () => {
                    log('AnswerElementSingleChoice: valid resolve true');
                    resolve(true);                    
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {        
       this.setState({value: event.target.value});
       this.props.handleChange(event.target.value, this.props.el.externalid);
       
    }

}
export default AnswerElementSingleChoice