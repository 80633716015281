import { Input, InputLabel, Chip, LinearProgress, Button } from '@mui/material';
import React from 'react';
import { TemplateElementConfigKey, TemplateElementFileUpload, TemplateElementFileUploadFile } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig, log } from '../../../../util/util';
import EllipsisChipLabel from './EllipsisChipLabel';

interface AnswerElementFileUploadProps extends AnswerElementDefaultProps {
    type: string | 'file',
}

interface AnswerElementFileUploadState extends AnswerElementDefaultState {
    value: TemplateElementFileUpload,
    fileSizeTotal: number,
    fileSizeExceeded: boolean,
    fileError: boolean,
    maxFileSize: number,
    maxTotalFileSize: number,
    allowedFileTypes: string[],
    allowedFileTypesString: string
}

// Max. Dateigröße pro File: 8 MB (default)
const DEFAULT_MAX_FILE_SIZE = 8 * 1024 * 1024;
// Max. Dateigröße in Summe: 24 MB (default)
const DEFAULT_MAX_TOTAL_FILE_SIZE = 24 * 1024 * 1024;

class AnswerElementFileUpload extends React.Component<AnswerElementFileUploadProps, AnswerElementFileUploadState> {
    
    constructor(props:any) {
        super(props);
        let maxFileSize = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.UPLOAD_MAX_FILESIZE]) || DEFAULT_MAX_FILE_SIZE;
        let maxTotalFileSize = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.UPLOAD_MAX_FILESIZE_TOTAL]) || DEFAULT_MAX_TOTAL_FILE_SIZE;
        let fileTypesStr:string = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.UPLOAD_ALLOWED_FILETYPES]) || "";        
        let fileTypes:string[] = fileTypesStr.replace(new RegExp("\\.","g"),"").split(",");        
        let files:TemplateElementFileUpload = {files: []};
        this.state = {value: files, error: null, fileSizeTotal: 0, fileSizeExceeded: false, fileError: false, maxFileSize: Number(maxFileSize), maxTotalFileSize: Number(maxTotalFileSize), allowedFileTypes:fileTypes, allowedFileTypesString: fileTypesStr};
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.dragEnter = this.dragEnter.bind(this);
        this.dragLeave = this.dragLeave.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.drop = this.drop.bind(this);
        this.deleteFromFileList = this.deleteFromFileList.bind(this);
        this.updateAnswerObject = this.updateAnswerObject.bind(this);
    }

    /**
     * Nachricht, wenn Pflichtfeld und keine Datei hochgeladen wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && this.props.el.mandatory && this.state.value.files.length <=0) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }

    dragEnter(e:any) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.add('highlight');
      }

    dragLeave(e:any) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.remove('highlight');
     }

     dragOver(e:any) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.add('highlight');
     }

     drop(e:any) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.remove('highlight');
        let dt = e.dataTransfer
        let files = dt.files
        this.fileUpload(files).then( () => this.updateAnswerObject());
    }

    deleteFromFileList(el:TemplateElementFileUploadFile) {
        let val = this.state.value.files.filter(function(value){ 
            return el.filename !== value.filename;
        });
    
        // Gesamtgröße neu berechnen
        let totalFileSize:number = 0;
        val.forEach(el => {
            let size:number = el.size || 0;
            totalFileSize += size;
        });

        this.setState({value: {files: val}, fileSizeTotal: totalFileSize}, () => {
            this.updateAnswerObject();
        });
    }

    getFileList() {
        const normalisedTotalSize = this.state.fileSizeTotal * 100 / (this.state.maxTotalFileSize);
        if (this.state.value.files.length > 0) {
            return <div className='control-wrapper'>
                <div>
                    {Math.round(this.state.fileSizeTotal / 1024 / 1024 * 10) / 10} MB von {this.state.maxTotalFileSize / 1024 / 1024} MB insgesamt verbraucht.
                    <LinearProgress variant="determinate" value={normalisedTotalSize} />
                </div>
                <p>Ihre Dateien:</p>
                {
                    this.state.value.files.map((el:TemplateElementFileUploadFile, index:number) => {
                        return(                        
                                <div className="chip" key={index}>
                                    <Chip label={<EllipsisChipLabel>{el.filename}</EllipsisChipLabel>} onDelete={() => {this.deleteFromFileList(el)}} color="primary" variant="outlined" />
                                </div>
                            );
                        }
                    )
                }
            </div>
        }
    }


    render() {    
        let errorMsg = this.getErrorMessage();
        let filelist = this.getFileList();
        let sizewarningClass = this.state.fileSizeExceeded ? "sizewarning" : "";
        let fileErrorClass = this.state.fileError ? "sizewarning" : "";
        if (this.state && this.props.el) {
            return (
                <div>
                    <div id="drop-area" className={"drop-area "+fileErrorClass} onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop} >
                    <div className="padding-bottom-3"><b>Ziehen Sie Ihre Dateien einfach per Drag & Drop auf diese Fläche oder nutzen Sie den Button, um Dateien auszuwählen.</b>
                        <div className={"filesize "+sizewarningClass}>Max. Größe pro Datei: {Math.round(this.state.maxFileSize / 1024 / 1024)} MB</div>
                        <div className={"filetype "+fileErrorClass}>Erlaubte Dateiendungen: {this.state.allowedFileTypesString}</div>
                    </div>
                    <Input inputProps={{accept: this.state.allowedFileTypesString, multiple: "multiple"}} className="fileupload" type="file" color="primary" onChange={this.handleChange} id={"file_"+this.props.el.externalid} name={"file_"+this.props.el.externalid} disabled={this.props.readonly}  />
                    <Button sx={{pointerEvents: 'none'}}><InputLabel id={"label_"+this.props.el.externalid} htmlFor={"file_"+this.props.el.externalid}>Datei(en) hochladen</InputLabel></Button>                    
                    <br />
                        {filelist}
                    </div>
                    {errorMsg}
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {
            if (this.state.value.files.length <= 0 && this.props.el.mandatory === true) {
                let valiMsgMandatory = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY])
                if (valiMsgMandatory) {
                    this.setState({error: valiMsgMandatory}, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({error: 'Bitte laden Sie Ihr Dokument hoch!'}, () => {
                        resolve(false);
                    });
                }
            } else {
                this.setState({error: null}, () => {
                    resolve(true);
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Nimmt eine Liste von Dateien entgegen und lädt sie in den Browser (auslesen).
     * Behandelt auch den Fall von doppelten Dateinamen.
     * 
     * @param files 
     * @returns 
     */
    fileUpload(files:any[]):Promise<void> {
        let fileSizeExceeded:boolean = false;
        let fileError:boolean = false;
        return new Promise((resolve) => {
            let sizeSum = this.state.fileSizeTotal;
            if (files !== undefined && files.length > 0) {
                let fileList:TemplateElementFileUploadFile[] = [];
                let promises:Promise<string>[] = [];
                for (var i = 0; i < files.length; i++) {
                    let currentFile = files[i];
                    let regex = "("+this.state.allowedFileTypes.join('|')+")";
                    let filetype:string[] = currentFile.name.split(".");
                    if (!RegExp(regex).test(filetype[filetype.length-1].toLowerCase())) {
                        fileError = true;
                        continue;
                    }
                    if (currentFile.size > this.state.maxFileSize) {
                        fileError = true;
                        fileSizeExceeded = true;
                        continue;
                    }
                    if (sizeSum + currentFile.size > this.state.maxTotalFileSize) {
                        fileError = true;
                        continue;
                    }
                    if (this.state.allowedFileTypes)
                    sizeSum += currentFile.size;
                    const promise:Promise<string> = new Promise<string>((resolve, reject) => {
                        const reader = new FileReader();        
                        reader.addEventListener("load", function () {
                            // convert to base64 string
                            resolve(String(reader.result));
                        }, false);
                        if (currentFile) {
                            reader.readAsDataURL(currentFile);
                        }
                    });
                    promises.push(promise);
                    // Umgang mit doppelt vorhandenen Dateinamen
                    let filename:string = this.uniqueFilename(currentFile.name, this.state.value.files);
                    let file:TemplateElementFileUploadFile =  {
                        filename: filename,
                        size: currentFile.size,
                        checksum: '',
                        data: ''
                    }           
                    log('file: '+JSON.stringify(file));         
                    fileList.push(file);
                };
                // Wenn alle Files eingelesen sind, den State aktualisieren.
                Promise.all(promises).then((results:any[]) => {
                    for (var i=0;i < results.length; i++) {
                        fileList[i].data = results[i];
                    }
                    this.setState({value: {files: [...fileList, ...this.state.value.files]}, fileSizeTotal: sizeSum, fileSizeExceeded: fileSizeExceeded, fileError: fileError}, () => resolve());
                    setTimeout(() => this.setState({fileSizeExceeded: false, fileError: false}), 1000);
                });            
            } else {
                this.setState({fileSizeExceeded: fileSizeExceeded, fileError: fileError});
                setTimeout(() => this.setState({fileSizeExceeded: false, fileError: false}), 1000);
                resolve();
            }
        });
    }

    uniqueFilename(filename : string, files: TemplateElementFileUploadFile[]):string {
        if (files !== undefined && files !== null && files.findIndex(el => el.filename === filename) > -1) {
            return this.uniqueFilename("copy_"+filename, files);
        } else {
            return filename;
        }
    }

    /*
    * Delegiert die Änderung ans Parent-Element.
    */
    updateAnswerObject() {
        let str=JSON.stringify(this.state.value);
        log('str: '+str);
        this.props.handleChange(str, this.props.el.externalid);
    }

    /**
     * Change-Handler für das Input-Field.
     * 
     * @param event 
     */
    handleChange(event: any) {        
        this.fileUpload(event.target.files).then( () => {
            this.updateAnswerObject();
        })
    }
}
export default AnswerElementFileUpload