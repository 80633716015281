import { useMediaQuery } from "@mui/material";

// https://stackoverflow.com/questions/59057057/material-ui-how-to-specify-maxwidth-of-chip

const EllipsisChipLabel = (props:any) => {
  const { children } = props;
  // sehr kleine Handys
  const mini = useMediaQuery('(max-width:400px)');
  // ab Pixel 2 / iPhone 5
  const midi = useMediaQuery('(max-width:600px)');

  let width = 230;
  if (mini){
      width = 140;
  } else if (!midi) {
      width = 500;
  }

  return (
    <div style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: width,
      }}>
      {children}
    </div>
  )
}    

export default EllipsisChipLabel;