import { Grid, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useAnswerCtx from './useAnswerCtx';
import { SHARE_MENU_STATE } from '../../../pages/answerLink/AnswerReducer';

interface ShareDialogProperties {
    saveLinkTemporary: any
}

function ShareDialog(props: ShareDialogProperties) {

    const {state, dispatch} = useAnswerCtx();

    const handleShareDialogueCloseClick = function(event:any) {
        dispatch({type: SHARE_MENU_STATE, visible:false, shareElementId: null, shareMessage: ""});
    }

    const handleShareDialogueMessageChange = function(event:any) {
        dispatch({type: SHARE_MENU_STATE, shareElementId: state.shareDialogueContext.shareElementId, visible: state.shareDialogueContext.visible, shareMessage: event.target.value});
    }

    const handleSave = function(event:any) {
        let elementid = state.shareDialogueContext.shareElementId;
        let msg = state.shareDialogueContext.shareMessage;
        dispatch({type: SHARE_MENU_STATE, visible:false, shareElementId: null, shareMessage: ""});
        props.saveLinkTemporary(elementid, msg);
    }

    return (
        <Dialog
        open={state.shareDialogueContext.visible}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
        <DialogTitle id="customized-dialog-title" sx={{display: 'flex', flexDirection: 'row'}}>
            <Box onClick={ handleShareDialogueCloseClick} sx={{display: 'flex', flexGrow: 1, paddingTop: 0.5}}>Teilen</Box>
            <IconButton
                aria-label="close"
                onClick={ handleShareDialogueCloseClick}
                sx={{
                    bgcolor: '#fff',
                    right: 10,
                    top: 0,
                    color: 'grey',
                }}
                size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
                <p>
                    Eine Nachricht für den Kunden, die über dem ausgewählten Element angezeigt wird:
                </p>
                <Box sx={{
                    '& .MuiInputBase-inputMultiline ': { p: '20px 0 0 12px', width: '100%' },
                }}>
                    <TextField multiline label="Nachricht" rows={4} sx={{width: '100%'}} value={state.shareDialogueContext.shareMessage} onChange={handleShareDialogueMessageChange} />
                </Box>
        </DialogContent>
        <DialogActions>
            <Grid container spacing="3">
                <Grid item xs={12} sm={6} className="center">
                    <Button onClick={handleShareDialogueCloseClick} title="Abbrechen">Abbrechen</Button>
                </Grid>
                <Grid item xs={12} sm={6} className="center">
                    <Button onClick={handleSave } title="Zwischenspeichern und teilen">Zwischenspeichern und teilen</Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog >
    );
}

export default ShareDialog