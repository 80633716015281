// import DOMPurify from 'dompurify';
// import { CustomerContext } from '../../../types/apimodel';
import {useState, useEffect, useCallback} from 'react';
import LinkedappBackend from '../../../services/LinkedappBackend';
import { AppState, DefaultProps } from '../../../types/types';
import { Box } from '@mui/material';

interface KundenakteProps extends DefaultProps {
    appState: AppState;
    search: string;
}

function Kundenakte(props: KundenakteProps) {

    const [targetUrl, setTargetUrl] = useState(undefined as string | undefined);

    const getRedirectUrl = () => {
        LinkedappBackend.getKundenakteRedirectURL().then((targetUrl) => {
            if (targetUrl?.substring(targetUrl.length - 1, targetUrl.length) === '/') {
                targetUrl = targetUrl.substring(0, targetUrl.length - 1);
            }
        
            targetUrl = targetUrl! + props.search;
            console.log("Target URL", targetUrl);
            setTargetUrl(targetUrl);
        });
    }

    getRedirectUrl();

    const redirektKundenakte = useCallback(() => {
        //console.log("props.search: ",props.search );
        console.log("REDIRECT")
        if(targetUrl) {
            console.log("GoTo Kundenakte");
            if (targetUrl) {
                window.open(targetUrl, "_self");
            }
        }
        //console.log("targetUrl: ",targetUrl );
        //let targetUrl =   process.env.REACT_APP_KUNDENAKTE || "";
    }, [targetUrl]);

    useEffect(() => {
        redirektKundenakte();
    }, [targetUrl, redirektKundenakte]);

    console.log("KUNDENPOSTFACH")
    return (
        <Box>
            <button onClick={(e) => redirektKundenakte()}>Sie werde zur Ihrer Kundenakte weitergeleitet oder klicken Sie hier {targetUrl} .</button>
        </Box>
    )
}
export default Kundenakte