import { AnswerElement, AnswerElementKey, AnswerKeyValue, AnswerStateContextKeyValue, ArrayOfAnswerKeyValue, ArrayOfAnswerStateContextKeyValue, KeyValue, TemplateConfigKeyValue, TemplateElement } from "../types/apimodel";


export function deepCopy(obj:any):any {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function convertDateStringToDate(str:string|null):Date | null {
    if (str && str.length === 10) {
        // zerlegen in YYYY-MM-DD
        let datestr = str.substr(6,4) + "-" + str.substr(3,2) + "-" + str.substr(0,2);
        return new Date(datestr);
    } else {
        return null;
    }
}

export function formatDateDE(d:Date):string {
    if (d) {
        let month:string = String(d.getMonth()+1);
        if (month.length < 2)     {
            month = '0'+month;
        }
        let day:string = String(d.getDate());
        if (day.length < 2)     {
            day = '0'+day;
        }
        return [ day, month, d.getFullYear()].join('.');
    }
    return '';
}


export function formatDate(d:Date):string {
    if (d) {
        let month:string = String(d.getMonth()+1);
        if (month.length < 2)     {
            month = '0'+month;
        }
        let day:string = String(d.getDate());
        if (day.length < 2)     {
            day = '0'+day;
        }
        return [d.getFullYear(), month, day].join('-');
    }
    return '';
}

export function dateFromComponents(
	year: string,
	month: string,
	day: string
): string {
    if (month.length < 2)     {
        month = '0'+month;
    }
    if (day.length < 2)     {
        day = '0'+day;
    }
    return [year, month, day].join('-');
}

export function extractValueFromArrayOfAnswerKeyValue(kv: ArrayOfAnswerKeyValue) {
    if (kv && kv !== null && kv.value && kv.value !== null && kv.value.length > 0) {
        return kv.value[0].value || '';
    } 
    return '';
}

export function extractValueFromAnswerElement(el:AnswerElement) {
    let kv = el && el.input;
    if (kv && kv !== null && kv.value && kv.value !== null && kv.value.length > 0) {
        return kv.value[0].value || '';
    } 
    return '';
}

/**
 * Ausschließlich für AnswerElements vom Typ PdfDocument
 * 
 * @param el 
 * @returns 
 */
export function extractFilenameFromAnswerElement(el:AnswerElement) {
    let kv = el && el.input;
    if (kv && kv !== null && kv.value && kv.value !== null && kv.value.length > 1) {
        return kv.value[1].value;
    } 
    return '';
}


export function findValueInAnswerStateContextConfig(asckv:ArrayOfAnswerStateContextKeyValue, key:string):string {
    let val:string = '';
    if (asckv && asckv.value && asckv.value.length > 0) {
        let confVal:AnswerStateContextKeyValue[] | null = asckv.value.filter(c => String(c.key) === key);
        if (confVal && confVal.length > 0) {
            val = confVal[0].value || '';
        }
    }
    return val;
}

export function findValueInTemplateConfig(tkv:TemplateConfigKeyValue[], key:string):string {
    let val:string = '';
    if (tkv && tkv.length > 0) {
        let confVal:TemplateConfigKeyValue[] | null = tkv.filter(c => String(c.key) === key);
        if (confVal && confVal.length > 0) {
            val = confVal[0].value || '';
        }
    }
    return val;
}

export function findValueInConfig(el:TemplateElement, key:string):string {
    let val:string = '';
    if (el && el.templateElementConfig) {            
        let confVal:KeyValue[] | null = el.templateElementConfig.value.filter(c => c.key === key);
        if (confVal && confVal.length > 0) {
            val = confVal[0].value;
        }
    }
    return val;
}

export function findValueInConfigAnswerElement(el:AnswerElement, key:string):string {
    let val:string = '';
    if (el && el.element && el.element.templateElementConfig) {            
        let confVal:KeyValue[] | null = el.element.templateElementConfig.value.filter(c => c.key === key);
        if (confVal && confVal.length > 0) {
            val = confVal[0].value;
        }
    }
    return val;
}

export function setSimpleValueOnAnswerElement(val:string|null, el:AnswerElement):AnswerElement {
    // Hier in den State packen
    let enumkey:any = AnswerElementKey[AnswerElementKey.SIMPLE_VALUE];
    let akv:AnswerKeyValue  = {
        key: enumkey,
        value: val || ''
    }
    let kv:ArrayOfAnswerKeyValue = {
        value: [akv]
    };
    el.input = kv;
    return el;
}

/**
 * Das String-Array muss im AnswerElement bereits serialisiert werden (z.B. bei handleChange() ).
 * 
 * @param val 
 * @param el 
 * @returns 
 */
export function setMultiChoiceValueOnAnswerElement(val:string|null, el:AnswerElement):AnswerElement {
    // Hier in den State packen
    let enumkey:any = AnswerElementKey[AnswerElementKey.MULTI_VALUE];
    let akv:AnswerKeyValue  = {
        key: enumkey,
        value: val || ''
    }
    let kv:ArrayOfAnswerKeyValue = {
        value: [akv]
    };
    el.input = kv;
    return el;
}


/**
 * Das Address-OBjekt muss im AnswerElement bereits als JSON serialisiert werden (z.B. bei handleChange() ).
 * 
 * @param val 
 * @param el 
 * @returns 
 */
 export function setAddressValueOnAnswerElement(val:string|null, el:AnswerElement):AnswerElement {
    // Hier in den State packen
    let enumkey:any = AnswerElementKey[AnswerElementKey.ADDRESS];
    let akv:AnswerKeyValue  = {
        key: enumkey,
        value: val || ''
    }
    let kv:ArrayOfAnswerKeyValue = {
        value: [akv]
    };
    el.input = kv;
    return el;
}


export function setMultiFileValueOnAnswerElement(val:string, el:AnswerElement):AnswerElement {
    // Hier in den State packen
    let enumkey:any = AnswerElementKey[AnswerElementKey.MULTI_FILES];
    
    let akv:AnswerKeyValue  = {
        key: enumkey,
        value: val || ''
    }
    let kv:ArrayOfAnswerKeyValue = {
        value: [akv]
    };
    el.input = kv;
    return el;
}

export function generateCorrelationId() {
    var result = '';
    var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( var i = 0; i < 9; i++ ) {
       if (i === 4) {
           result += '-';
       } else {
           result += alphabet.charAt(Math.floor(alphabet.length * Math.random()));
       }
    }
    return result;
 }

 /**
  * Customizing-Parameter für die Steuerung des Themings aus der URL lesen.
  * @param url 
  * @returns 
  */
 export const getCustomizingID = function(url:string):string {
    let params = new URLSearchParams(url);
    return params.get('cid') || 'void';
  }

let isDebugEnabled = false;

export const setDebug = (isDebugEnabledVal:boolean) => {
    isDebugEnabled = isDebugEnabledVal;
}

export const isDebug = ():boolean => {
    return isDebugEnabled;
}

export const initLogger = (isDebugEnabledVal:boolean) => {
	isDebugEnabled = isDebugEnabledVal;
};

export const log = (message?: any, ...params: any[]) => {
	if (isDebugEnabled) {
		console.log(message, ...params);
	}
};
