import { AnswerElement, AnswerStateContextKey, AnswerWizardConfig, AnswerWizardStep, ArrayOfAnswerKeyValue, ElementType, GetLinkResponse, SaveLinkRequest, TemplateConfigKey, TemplateElementConfigKey } from "../../../../types/apimodel";
import { AdditionalAnswerStateContext, AnswerWizardState, InvalidElement } from "../../../../types/types";
import { findValueInAnswerStateContextConfig, findValueInConfig, findValueInTemplateConfig, log } from "../../../../util/util";

/**
 * Extrahier den zusätzlichen Antwortstatus (Anmerkung und Highlighting z.B. des Maklers an einem Antwortelement)
 * @param response 
 * @returns 
 */
export const extractAdditionalAnswerStateContext = function (response: GetLinkResponse): AdditionalAnswerStateContext | undefined {
    let additionalAnswerStateContext: AdditionalAnswerStateContext | undefined = undefined;
    let highlightElement: string = (response.answerStateContext && findValueInAnswerStateContextConfig(response.answerStateContext?.value, AnswerStateContextKey[AnswerStateContextKey.HIGHLIGHT_ELEMENT])) || '';
    let highlightMessage: string = (response.answerStateContext && findValueInAnswerStateContextConfig(response.answerStateContext?.value, AnswerStateContextKey[AnswerStateContextKey.CUSTOMER_MESSAGE_FOR_ELEMENT])) || '';
    if (highlightElement !== '' || highlightMessage !== '') {
        additionalAnswerStateContext = {
            shareMessage: highlightMessage !== '' ? highlightMessage : undefined,
            shareElementid: highlightElement !== '' ? highlightElement : undefined
        }
    }
    return additionalAnswerStateContext;
}

/**
 * Extrahiert die Konfiguration des Wizards aus der Server-Response.
 * @param response 
 * @returns 
 */
export const getWizardState = function (response: GetLinkResponse, additionalAnswerStateContext: AdditionalAnswerStateContext | undefined, page: number | undefined): AnswerWizardState {
    let currentStep: number = 1;
    if (page !== undefined) {
        if (isNaN(+page)) {
            currentStep = 1;
        } else {
            currentStep = +page;
        }
    }
    log('currentStep: ' + currentStep);
    let numberOfSteps = 1;
    let isWizard = true;
    let disabledFirstStep;
    let steps: AnswerWizardStep[] = [];
    let wizardConfAsString: string | null = (response.templateConfig !== null ? findValueInTemplateConfig(response.templateConfig.value, TemplateConfigKey[TemplateConfigKey.WIZARD_CONFIG]) : null);
    let stepMapping: Map<string, number> = new Map<string, number>();
    log('wizardConfAsString:' + wizardConfAsString);
    if (wizardConfAsString === undefined || wizardConfAsString === null || wizardConfAsString === '') {
        isWizard = false;
    } else {
        let wizardConfig: AnswerWizardConfig = JSON.parse(wizardConfAsString);
        numberOfSteps = wizardConfig.numberOfSteps;
        if (wizardConfig.wizardSteps.value !== null) {
            steps = wizardConfig.wizardSteps.value;
            if (currentStep > numberOfSteps) {
                currentStep = numberOfSteps;
            }
        }
        if (wizardConfig.disabledFirstStep != null){
            disabledFirstStep = wizardConfig.disabledFirstStep;
            currentStep = 2;
        }
        // Zuordnung der Elemente zu den Steps
        if (response.answer !== undefined && response.answer !== null) {
            for (let i = 0; i < response.answer.answerElement.value.length; i++) {
                let step: number = +(findValueInConfig(response.answer.answerElement.value[i].element, TemplateElementConfigKey[TemplateElementConfigKey.WIZARD_STEP]) || "1");
                stepMapping.set(response.answer?.answerElement.value[i].element.externalid, step);
            }
        }
    }

    // Wenn ein Element in der Reponse enthalten war, dass markiert werden soll: wizardStep passend setzen, 
    // so dass das Element sichtbar ist.
    if (additionalAnswerStateContext !== undefined && additionalAnswerStateContext !== null) {
        let highlightElement: string | undefined = additionalAnswerStateContext.shareElementid;
        if (highlightElement !== undefined) {
            let highlight: number | undefined = stepMapping.get(highlightElement);
            if (highlight !== undefined) {
                log('spring zu seite ' + highlight + ' zu element ' + highlightElement);
                currentStep = highlight;
            }
        }
    }

    return {
        currentStep: currentStep,
        numberOfSteps: numberOfSteps,
        isWizard: isWizard,
        isUnmounting: false,
        elementStepMapping: stepMapping,
        wizardSteps: steps,
        disabledFirstStep : disabledFirstStep
    };
}

/**
 * Prüft, ob das Element zum Wizard gehört.
 * @param step 
 * @param el 
 * @param wizardState 
 * @returns 
 */
export const isElementIfPartOfWizardPage = function (step: number, el: AnswerElement, wizardState: AnswerWizardState): boolean {
    // Kein Wizard: alle Elemente anzeigen!
    if (wizardState === null || wizardState === undefined || wizardState.isWizard === false) {
        return true;
    }
    return (wizardState.elementStepMapping !== undefined && (wizardState.elementStepMapping?.get(el.element.externalid) === step));
}

/**
 * Pflichtfelder validieren.
 * @param answer 
 * @param elementStepMapping 
 * @returns 
 */
export const validateMandatoryElements = function (answer: SaveLinkRequest | null, elementStepMapping: Map<string, number> | undefined, visible: Map<string, boolean>): InvalidElement {
    let firstInvalidWizardStep = -1;
    let firstInvalidElementId: string | null = null;

    if (answer !== null && answer.answer?.answerElement?.value !== null) {
        for (let i = 0; i < answer.answer.answerElement.value.length; i++) {
            let answerKV: ArrayOfAnswerKeyValue | null = answer.answer.answerElement.value[i].input || null;
            log('Answer von Element ' + answer.answer.answerElement.value[i].element.externalid + ': ' + JSON.stringify(answer.answer.answerElement.value[i].input));
            // Sichtbar und Mandatory validieren
            //Allgemein
            if (answer.answer.answerElement.value[i].element.mandatory === true &&
                true === visible.get(answer.answer.answerElement.value[i].element.externalid)) {
                //Ausnahme für conformation, weil hier Wert nicht ausreicht - er muss true sein
                if (
                    (
                        ((String(answer.answer.answerElement.value[i].element.type) === ElementType[ElementType.CONFIRMATION])
                        || (String(answer.answer.answerElement.value[i].element.type) === ElementType[ElementType.CONFIRMATION_POST_REG]))
                        && answerKV?.value[answerKV.value.length - 1].value === 'false')
                    ||
                    (answerKV === null || answerKV.value === null || answerKV.value.length === 0)
                ) {

                    log('Validierungsfehler in Element ' + answer.answer.answerElement.value[i].element.externalid);
                    if (elementStepMapping !== undefined) {
                        let invalidWizardStep: number | undefined = elementStepMapping.get(answer.answer.answerElement.value[i].element.externalid);
                        // Ersten (kleinsten) fehlerhaften Step im Wizard bestimmen
                        if (invalidWizardStep !== undefined) {
                            if (firstInvalidWizardStep < 0 || invalidWizardStep < firstInvalidWizardStep) {
                                firstInvalidWizardStep = invalidWizardStep;
                                firstInvalidElementId = answer.answer.answerElement.value[i].element.externalid;
                            }
                        }
                    }
                }
            }
        }
    }
    return { wizardStep: firstInvalidWizardStep, elementid: firstInvalidElementId };
}