import { Button, LinearProgress } from "@mui/material";
import { AuthContext, ContactType, SaveContactConfirmationResponse, TemplateElementConfigKey, AnswerElement } from "../../../../types/apimodel";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import LinkedappBackend from '../../../../services/LinkedappBackend';
import { AnswerElementDefaultProps, AnswerElementDefaultState, AnswerState } from "../../../../types/types";
import React from "react";
import { Grid, TextField, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import DOMPurify from "dompurify";
import { findValueInConfig } from '../../../../util/util';

import { AnswerCtx } from './../useAnswerCtx';


interface AnswerElementPhoneInputWithValidationProps extends AnswerElementDefaultProps {
    linkId: string | null,
    authContext: AuthContext | null,
    addButtonDisabler: any,
    removeButtonDisabler: any,
}

interface AnswerElementPhoneInputWithValidationState extends AnswerElementDefaultState {
    value: string | '',
    confirmed: boolean,
    initing: boolean,
    smsSending: boolean,
    pending: boolean,
    expired: boolean,
    textBeforeSend: string | null,
    textWhileSending: string | null,
    textAfterSend: string | null,
    pollingEnabledInConfig: boolean | false,
    buttonHiddenWhileNotValidated: boolean | false,
    showTanField: boolean | false,
    tan: string | '',
    tanTesting: boolean | false,
    tanError: string | null,
    radioValueTextOldValue: string,
}

class AnswerElementPhoneInputWithValidation extends React.Component<AnswerElementPhoneInputWithValidationProps, AnswerElementPhoneInputWithValidationState> {

    constructor(props: any) {
        super(props);
        let value = this.props.value || '';
        let textBeforeSendFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.CELLPHONE_VALI_TEXT_BEFORE_SEND]);
        let cleanTextBeforeSend: string | null = (textBeforeSendFromConfig !== null && textBeforeSendFromConfig !== undefined && DOMPurify.sanitize(textBeforeSendFromConfig, { ADD_ATTR: ['target'] })) || null;
        let textWhileSendingFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.CELLPHONE_VALI_TEXT_WHILE_SENDING]);
        let cleanTextWhileSending: string | null = (textWhileSendingFromConfig !== null && textWhileSendingFromConfig !== undefined && DOMPurify.sanitize(textWhileSendingFromConfig, { ADD_ATTR: ['target'] })) || null;
        let textAfterSendFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.CELLPHONE_VALI_TEXT_AFTER_CONFIRM]);
        let cleanTextAfterSend: string | null = (textAfterSendFromConfig !== null && textAfterSendFromConfig !== undefined && DOMPurify.sanitize(textAfterSendFromConfig, { ADD_ATTR: ['target'] })) || null;
        let pollingEnabledInConfig: boolean = false;
        let buttonHiddenWhileNotValidated: boolean = Boolean(findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.CELLPHONE_VALI_HIDE_SAVE_BUTTON])) || false;
        let showTanField = true;
        this.state = { value: value, initing: true, expired: false, confirmed: false, smsSending: false, pending: false, error: null, showTanField: showTanField, tanError: null, tanTesting: false, textBeforeSend: cleanTextBeforeSend, textWhileSending: cleanTextWhileSending, textAfterSend: cleanTextAfterSend, pollingEnabledInConfig: pollingEnabledInConfig, buttonHiddenWhileNotValidated: buttonHiddenWhileNotValidated, tan: '',radioValueTextOldValue:"" };
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.registerValidation(this.validate);
        this.handleTANChange = this.handleTANChange.bind(this);
        this.onTanBlur = this.onTanBlur.bind(this);
        this.onTanKeyPress = this.onTanKeyPress.bind(this);
        this.confirmContact = this.confirmContact.bind(this);
        this.initContactConfirmationStatus();
    }

    // Siehe https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html

    // Beim Einhängen der Komponente wird direkt ein Status-Update gemacht. 
    // Die Promises der Server-Calls (siehe auch initContactConfirmationStatus() und pollContactConfirmationStatus())
    // kommen aber zurück, wenn die Komponente bereits unmounted wird.    
    // Lösung: entweder cancelbare Callbacks oder halt hier die schnelle Lösung über die Abfrage.
    private mounted: boolean = false;

    componentWillUnmount() {
        this.mounted = false;
        // Disablen des Speichern-Buttons wieder entfernen, weil die Komponente unsichtbar wird.
        this.props.removeButtonDisabler(this.props.el.externalid);
    }

    getPrefillValue(): string | undefined {
        //console.log("#### ", t?.answer?.answer.answerElement.value);
        const t: AnswerState | undefined = this.context?.state;
        let depElementId = this.props.el.dependencyConfig?.dependencies.value[0].elementid;
        let radioElement: AnswerElement | undefined = t?.answer?.answer.answerElement.value.find(element => element.element.externalid === depElementId);

        if (radioElement && radioElement?.input?.value && radioElement?.input?.value.length > 0) {
            let radioValueText = radioElement?.input?.value[radioElement?.input?.value.length - 1]?.value;
            //console.log("##5:  ", radioValueText);
            if (radioValueText && this.state.radioValueTextOldValue !== radioValueText){

                this.setState({radioValueTextOldValue: radioValueText, smsSending: false, pending: false });
            }
            //
            if (radioValueText && radioValueText !== "NOENTRY") {
                
                return radioValueText;
            }
        }

        return undefined;
    }

    componentDidMount() {
        this.mounted = true;
        // Disablen des Speichern-Buttons einhängen, weil ohne Eingabe der Mailadresse der Prozess blockiert werden soll.
        this.props.addButtonDisabler(this.props.el.externalid, this.state.buttonHiddenWhileNotValidated);
    }

    componentDidUpdate(prevProps: Readonly<AnswerElementPhoneInputWithValidationProps>, prevState: Readonly<AnswerElementPhoneInputWithValidationState>, snapshot?: any): void {
        const prefillValue = this.getPrefillValue();
        if (prefillValue !== undefined &&
            (!this.state.value || this.isPrefilledByDependency(this.context?.state))
            && this.state.value !== prefillValue) {
            this.setState({ value: prefillValue, error: null});
        }
    }

    confirmContact() {
        this.setState({ ...this.state, tanTesting: true }, () => {
            if (this.props.linkId !== null && this.state.tan !== null) {
                // Sonderzeichen wegstrippen.
                const regex = /[^a-zA-Z0-9]/ig;
                let tanToTest = this.state.tan.replaceAll(regex, '');
                LinkedappBackend.saveContactConfirmation(this.props.linkId, tanToTest).then((response: SaveContactConfirmationResponse) => {
                    if (true === response.success) {
                        // Button freischalten
                        this.props.removeButtonDisabler(this.props.el.externalid);
                        if (this.mounted === true) {
                            this.setState({ ...this.state, pending: false, confirmed: true, error: null, tanError: null, tanTesting: false });
                        }

                    } else {
                        if (this.mounted === true) {
                            this.setState({ ...this.state, pending: true, confirmed: false, tanError: response.error ? "Die TAN ist nicht korrekt." : null, tanTesting: false });
                        }
                    }
                }).catch((error) => {
                    if (this.mounted === true) {
                        this.setState({ ...this.state, pending: true, confirmed: false, tanError: "Die TAN ist nicht korrekt.", tanTesting: false });
                    }
                });
            }
        });
    }

    handleTANChange(event: any) {
        let tan: string = (event.target.value !== undefined && event.target.value != null) ? event.target.value.trim() : '';
        this.setState({ ...this.state, tan: tan });
    }

    onTanBlur(event: any) {

    }

    onTanKeyPress(event: any) {
        if (event.key === 'Enter') {
            this.setState({ tan: event.target.value.trim() }, () => {
                this.confirmContact();
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }


    isPrefilledByDependency = (t: AnswerState | undefined): boolean => {
        let depElementId = this.props.el.dependencyConfig?.dependencies.value[0].elementid;
        let radioElement: AnswerElement | undefined = t?.answer?.answer.answerElement.value.find(element => element.element.externalid === depElementId);
        const radioValueText = radioElement?.input?.value[0]?.value;
        return !!radioValueText && radioValueText !== "NOENTRY";
    }

    getAusgewaehlteMobilnummer() {
        if (this.props.value) {
            return this.props.value;
        }
        if (this.state.value) {
            return this.state.value
        }
        return "";
    }

    render() {
        return (
            <div className='control-wrapper'>
                {this.state.confirmed === false && this.state.smsSending === false && this.state.pending === false &&
                    <>
                        {this.state.expired === true && <Grid container className="row" sx={{ paddingBottom: 3 }}>
                            <Grid item xs={12} sx={{ color: '#ff0000', fontWeight: 700 }}>
                                Ihr Bestätigungslink ist leider abgelaufen. Bitte fordern Sie den Bestätigungslink erneut an!
                            </Grid>
                        </Grid>}
                        <Grid container className="row" sx={{ paddingBottom: 3 }}>

                            {this.state.textBeforeSend !== null &&
                                <Grid item xs={12} md={12} dangerouslySetInnerHTML={{ __html: this.state.textBeforeSend }}></Grid>
                            }
                        </Grid>
                        <Grid container className="row" sx={{ paddingBottom: 3 }}>
                            <Grid item xs={12} md={8}>
                                <AnswerCtx.Consumer>
                                    {(t) =>
                                        <TextField onChange={this.handleChange} onBlur={this.onBlur} onKeyPress={this.keyPress} disabled={this.isPrefilledByDependency(this.context?.state)} placeholder='Ihre Telefonnummer' value={this.state.value}
                                            sx={{ width: '100%' }}
                                            error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} />
                                    }
                                </AnswerCtx.Consumer>
                                <Box sx={{ paddingTop: '8px', fontSize: '13px' }}>{findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.HINT])}</Box>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ paddingTop: { xs: 3, md: 0.5 }, paddingLeft: { xs: 0, md: 3 }, textAlign: 'center' }}>
                                <Button title="Bestätigungslink anfordern" onClick={this.sendConfirmationLink} endIcon={<SendIcon />} >Jetzt bestätigen</Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {this.state.smsSending === true && <Grid container className="row fade-in" sx={{ paddingBottom: 3 }}>
                    <Grid item xs={12} md={12}>Vielen Dank für Ihre Bestätigung, Ihre Kontaktdaten werden gerade aktualisiert.</Grid>
                    <Grid item xs={11} md={11} sx={{ paddingTop: 3 }}><LinearProgress /></Grid>
                    <Grid item xs={1} md={1} sx={{ paddingTop: 0 }}><IconButton color="primary" title="Abbrechen" onClick={(e) => this.setState({ smsSending: false, pending: false })}><CloseIcon /></IconButton></Grid>
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><Box sx={{ paddingTop: 3 }}>E-Mail wird gesendet.....</Box></Grid>
                </Grid>}
                {this.state.pending === true && <Grid container className="row fade-in" sx={{ paddingBottom: 3 }}>
                    {
                    /*this.state.textWhileSending === null && 
                        <Grid item xs={12} md={12} sx={{paddingBottom: 3}}>Wir warten auf Ihre Bestätigung des Links, den Sie gerade per SMS erhalten haben.</Grid>
                    */}
                    {this.state.textWhileSending !== null &&
                        <Grid item xs={12} md={12} dangerouslySetInnerHTML={{ __html: this.state.textWhileSending }}></Grid>
                    }
                    <Grid item xs={12} md={12}>
                        <Box sx={{ paddingTop: 3, paddingBottom: 2 }}><b>Bitte geben Sie hier die TAN ein, die wir Ihnen soeben per SMS gesendet haben.</b></Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField sx={{ width: '100%' }} label="TAN" type="text" value={this.state.tan} onChange={this.handleTANChange} onBlur={this.onTanBlur} id={this.props.el.externalid + "-tan"} size="medium" onKeyPress={this.onTanKeyPress}
                            error={(this.state.tanError && this.state.tanError !== null) || false} helperText={this.state.tanError} disabled={this.state.tanTesting} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ paddingTop: { xs: 3, md: 0.5 }, paddingLeft: { xs: 0, md: 3 }, textAlign: 'center' }}>
                        <Button sx={{ marginleft: { xs: 0, sm: 0, md: 1, lg: 1 } }} title="TAN bestätigen" onClick={this.confirmContact} endIcon={<SendIcon />} disabled={this.state.tanTesting} >TAN bestätigen</Button>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ paddingTop: 3 }}>
                        <Box>Keine Bestätigungs-SMS erhalten?</Box>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ paddingTop: '1.0em'}}>
                        <Button title="SMS erneut senden" className="button-inline" onClick={(e) => this.sendConfirmationLink(e, true)}>SMS erneut anfordern</Button>
                    </Grid>
                   {/* 
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}>
                        <Box>
                            <Button color="secondary" title="Abbrechen" sx={{ marginLeft: { xs: 0, sm: 0, md: 1, lg: 1 }, height: 30, textTransform: 'unset', marginTop: 1 }} variant="outlined" onClick={(e) => this.setState({ smsSending: false, pending: false })} endIcon={<CloseIcon />}>Abbrechen</Button>
                        </Box>
                    </Grid>
                    */}
                    {!this.state.pollingEnabledInConfig && <Grid item xs={12} md={12} sx={{ paddingTop: 5, fontWeight: 700 }}></Grid>}
                </Grid>}
                {this.state.confirmed === true &&
                    <>
                        {this.state.textAfterSend === null && <><h3>Vielen Dank für Ihre Mithilfe!</h3>Wir haben Ihre Mobilfunknummer ( <b>{this.getAusgewaehlteMobilnummer()}</b> ) hinterlegt.</>}
                        {this.state.textAfterSend !== null && <Box dangerouslySetInnerHTML={{ __html: this.state.textAfterSend }}></Box>}

                    </>
                }
            </div>
        );
    }

    keyPress(event: any): any {
        if (event.key === 'Enter') {
            this.setState({ value: event.target.value.trim() }, () => {
                this.validate(true).then((success) => {
                    if (success) {
                        this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
                        this.sendConfirmationLink(event);
                    }
                });
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onBlur = (event: any) => {
        this.setState({ value: event.target.value.trim() }, () => {
            this.validate(true).then((success) => {
                if (success) {
                    this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
                }
            });
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     */
    validate = (onlyMail?: boolean): Promise<boolean> => {
        return new Promise((resolve) => {
            if (true !== onlyMail) {
                if (this.state.confirmed === true) {
                    resolve(true);
                    return;
                } else {
                    let phoneRegexValidated = new RegExp("^(0049)(0?)((1[5-7])|(2)|(3[^12])|([4-6]))([0-9]{4,20})$").test(this.state.value);
                    if (phoneRegexValidated === false) {
                        this.setState({ ...this.state, error: 'Bitte geben Sie eine gültige Mobilnummer mit Ländervorwahl (0049) an!' }, () => resolve(false));
                        resolve(false);
                    } else {
                        this.setState({ ...this.state, error: 'Bitte fordern Sie zunächst die Bestätigungsnachricht an!' }, () => resolve(false));
                        resolve(false);
                    }
                }
            } else {
                let phoneRegexValidated = new RegExp("^(0049)(0?)((1[5-7])|(2)|(3[^12])|([4-6]))([0-9]{4,20})$").test(this.state.value);
                if (phoneRegexValidated === false) {
                    this.setState({ ...this.state, error: 'Bitte geben Sie eine gültige Mobilnummer mit Ländervorwahl (0049) an!' });
                    resolve(false);
                } else {
                    this.setState({ ...this.state, error: null });
                    resolve(true);
                }
            }
        });
    }

    initContactConfirmationStatus = () => {
        if (this.props.linkId !== null && this.props.authContext !== null) {
            LinkedappBackend.getContactConfirmation(this.props.linkId, this.props.authContext, ContactType.CELLPHONE).then((data) => {
                if (true === data.confirmed) {
                    // Button freischalten
                    this.props.removeButtonDisabler(this.props.el.externalid);
                    this.props.handleChange(this.props.value ?? '', this.props.el.externalid);
                    
                }
                if (this.mounted === true) {
                    const prefillValue = this.getPrefillValue();
                    this.setState({ ...this.state, expired: ('expired' === data.error), confirmed: data.confirmed, initing: false, value: prefillValue ?? '' });
                    if (this.state.pollingEnabledInConfig) {
                        this.pollContactConfirmationStatus();
                    }
                }
            }).catch((error) => {
                // TODO: Fehlermeldung anzeigen
                if (this.mounted === true) {
                    this.setState({ ...this.state, pending: false, confirmed: false, initing: false });
                }
            });
        }
    }

    pollContactConfirmationStatus = () => {
        if (this.props.linkId !== null && this.props.authContext !== null && this.state.pending === true) {
            LinkedappBackend.getContactConfirmation(this.props.linkId, this.props.authContext, ContactType.CELLPHONE).then((data) => {
                if (true !== data.confirmed && this.mounted === true) {
                    window.setTimeout(() => {
                        this.pollContactConfirmationStatus();
                    }, 5000);
                } else if (true === data.confirmed) {
                    // Button freischalten
                    this.props.removeButtonDisabler(this.props.el.externalid);
                    if (this.mounted === true) {
                        this.setState({ ...this.state, pending: false, confirmed: true, error: null });
                       
                    }
                }
            }).catch((error) => {
                if (this.mounted === true) {
                    this.setState({ ...this.state, pending: true, confirmed: false, error: 'Auf unserem Server ist etwas schiefgegangen. Wir probieren es in wenigen Sekunden nochmal...' });
                }
            });
        }
    }

    sendConfirmationLink = (event: any, force?: boolean) => {
        this.validate(true).then((valid) => {
            if (valid === true) {
                let phone: ContactType = ContactType.CELLPHONE;
                if (this.props.linkId !== null && this.props.authContext !== null && (this.state.pending === false || true === force)) {
                    this.setState({ ...this.state, smsSending: true, error: null, expired: false });

                    LinkedappBackend.sendContactConfirmation(this.props.linkId, this.props.authContext, phone, this.state.value).then((data) => {
                        if (data !== null) {
                            if (true !== data.success) {
                                this.setState({ ...this.state, smsSending: false, pending: false, error: 'Da ist etwas schiefgegangen. Bitte probieren Sie es nochmal!' });
                            } else {
                                this.setState({ ...this.state, smsSending: false, pending: true });
                                if (this.state.pollingEnabledInConfig && this.mounted === true) {
                                    window.setTimeout(() => {
                                        this.pollContactConfirmationStatus();
                                    }, 5000);
                                }
                            }
                        }
                    }).catch((error) => {
                        this.setState({ ...this.state, smsSending: false, pending: false, error: 'Auf unserem Server ist etwas schiefgegangen. Bitte probieren Sie es nochmal!' });
                    });
                }
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc: any): any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {
        this.setState({ value: event.target.value });
    }

}

AnswerElementPhoneInputWithValidation.contextType = AnswerCtx;

export default AnswerElementPhoneInputWithValidation;