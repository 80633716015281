import { Box, Container, Grid } from '@mui/material';
import logorheinland from '../../rheinland.svg';
import { AppState, DefaultProps, HeaderLayout } from '../../types/types';
import BrokerInfo from './BrokerInfo';

interface HeaderProps extends DefaultProps {
    appState:AppState,
}

function getHeaderContent(props:HeaderProps) {
    let postfix:string = props.appState?.customizingId || 'rl';
    let tenantlogo = props.appState?.customizing?.logoSrc != null ? <img src={props.appState?.customizing?.logoSrc || logorheinland} className={"logo logo-"+postfix} alt="Logo" /> : '';
    let vermittlerNr:string = (props.appState.vermittlerDaten !== undefined && props.appState.vermittlerDaten !== null && props.appState.vermittlerDaten.agenturnummer !== undefined) ? props.appState.vermittlerDaten.agenturnummer : '0';
    let showBroker:boolean = props.appState.vermittlerDaten !== undefined && props.appState.vermittlerDaten !== null && vermittlerNr !== '0';
    return(
        <Grid container spacing={3} className="container">
            <Grid item xs={12} md={6} className="header-container">
                <span className="vertical-align-helper"></span>
                {tenantlogo}
            </Grid>
            {showBroker && <Grid item xs={12} md={6} className="header-container">
                <BrokerInfo appState={props.appState}></BrokerInfo>
            </Grid>}
        </Grid>
    );
}

function Header(props:HeaderProps) {
    const content = getHeaderContent(props);
    return(
            <Box className="header" sx={{backgroundColor: 'header.main' || '#fafafa', color: 'header.contrastText' || '#009767'}}>
                {HeaderLayout[HeaderLayout.EMBEDDED_IN_CONTAINER] === HeaderLayout[props.appState.customizing.headerLayout] ? <>{content}</> : <Container maxWidth="lg">{content}</Container>}
            </Box>
        );
}
export default Header;