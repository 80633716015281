import DOMPurify from "dompurify";
import { Avatar, Box } from "@mui/material";
import { AppState } from '../../../../types/types';
import useAnswerCtx from "../useAnswerCtx";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { REMOVE_COMMENT_BUBBLE } from "../../../../pages/answerLink/AnswerReducer";

interface HighlightFrameProps {
    children?: React.ReactNode
    elementid: string,
    appState: AppState,
}

function HighlightFrame(props:HighlightFrameProps) {

    const {state, dispatch} = useAnswerCtx();

    const removeCommentBubble = function(event: any) {
        dispatch({type: REMOVE_COMMENT_BUBBLE});
    }

    let clean:string = (state.additionalAnswerStateContext?.shareMessage && state.additionalAnswerStateContext?.shareMessage !== null && DOMPurify.sanitize( state.additionalAnswerStateContext?.shareMessage, { ADD_ATTR: ['target'] } )) || '';
    let brokerId = props.appState.vermittlerDaten !== undefined && props.appState.vermittlerDaten !== null && props.appState.vermittlerDaten.agenturnummer !== undefined ? props.appState.vermittlerDaten.agenturnummer : '0';
    let brokerName:string = props.appState.vermittlerDaten?.name1 || '';
    if (state.additionalAnswerStateContext?.shareElementid !== undefined && state.additionalAnswerStateContext?.shareElementid !== null && state.additionalAnswerStateContext?.shareElementid === props.elementid) {
        return (
            <>            
                <div className="notification" id={"notification-"+props.elementid}>
                {
                    (brokerId !== undefined && brokerId !== '0') 
                    && <Avatar sx={{ width: 60, height: 60, objectFit: 'cover'}} className="user-icon" alt="Hurz" src={(process.env.REACT_APP_BROKER_AVATAR_URL || '') + brokerId + '.jpg'}>
                        <Box sx={{ "& img": {width: 60, height: 60, objectFit: 'cover'}}}>
                            <img src={process.env.REACT_APP_BROKER_FALLBACK_AVATAR_URL} alt="Ansprechpartner" />
                        </Box>
                    </Avatar>
                }
                <div className="bubble-container">
                    <div className="broker-name">{brokerName}   </div>
                    <Box className="bubble" sx={{'&.MuiBox-root': {backgroundColor: 'secondary.main'}, display: 'flex'}} >
                        <Box component="span" dangerouslySetInnerHTML={{__html: clean}}></Box>
                        <Box component="span" sx={{marginLeft: '22px', marginRight: '-12px', marginTop: '-2px'}}>
                            <IconButton aria-label="Kommentar wieder entfernen" size="small" sx={{color: '#ffffff'}} onClick={removeCommentBubble}>
                                <CancelIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="arrowdown" sx={{'&.MuiBox-root': {borderTopColor: 'secondary.main'}}}></Box>
                </div>
            </div>            
            {props.children}
            </>
        );
    } else {
        return (
            <>{props.children}</>
        );
    }
}

export default HighlightFrame