import { Box, TextField } from '@mui/material';
import React from 'react';
import { SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig, log } from '../../../../util/util';

interface AnswerElementPhoneOptInProps extends AnswerElementDefaultProps {
    
}

interface AnswerElementPhoneOptInState  {
    value: string,
    phoneerror: null|string,
}

/**
 * 
 * Stellt eine Auswahlmöglichkeit bereit (entweder als Radiobutton (type="radio") oder als Dropdown (type="select")), aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */
class AnswerElementPhoneOptIn extends React.Component<AnswerElementPhoneOptInProps, AnswerElementPhoneOptInState> {
    
    constructor(props:any) {
        super(props);
        let value:string = this.props.value !== null && this.props.value !== undefined ? this.props.value : "";
        this.state = {value: value, phoneerror: null};
        // Initialwert hochbubblen
        this.props.handleChange(value, this.props.el.externalid);

        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.handleChangePhone = this.handleChangePhone.bind(this);
    }

     render() {    
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                        <>
                            <Box>
                                <TextField label="Ihre Mobil- bzw. Handynummer" type="text" value={this.state.value} required={this.props.el.mandatory}
                                onChange={this.handleChangePhone} id={this.props.el.externalid+"-phone"} 
                                error={(this.state.phoneerror && this.state.phoneerror !== null) || false} helperText={this.state.phoneerror} size="medium" disabled={this.props.readonly} sx={{width: '50%'}} />
                                <Box sx={{paddingTop: '8px', fontSize: '13px'}}>{this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.HINT])}</Box>
                            </Box>
                        </>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig():SelectItem[] {
        let items:SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);        
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {            
            // Wenn Mandatory: ist select ausgewählt?
            // Prüfen: Wenn "JA" => Phone muss ausgefüllt und valid sein
            let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
            let mandatory:boolean = this.props.el.mandatory === true || (valiMsgMandatory !== undefined && valiMsgMandatory !== '');
            let regexStr = /^(\+49|0049|0{1})((1[5-7])|(2)|(3[^12])|([4-6]))([0-9]{4,20})$/;
            let regex = new RegExp(regexStr);
            if (mandatory) {
                if (this.state.value === null || this.state.value === '') {
                    this.setState({phoneerror: 'Bitte geben Sie eine gültige Handynummer an!'}, () => {
                        log('AnswerElementPhoneOptIn: valid resolve false');
                        resolve(false);
                    });                    
                } else if (this.state.value !== null && false === regex.test(this.state.value)) {
                    this.setState({phoneerror: 'Bitte geben Sie eine gültige Handynummer an!'}, () => {
                        log('AnswerElementPhoneOptIn: valid resolve false');
                        resolve(false);
                    });                    
                } else {
                    this.setState({phoneerror: null}, () => {
                        log('AnswerElementPhoneOptIn: valid resolve true');
                        resolve(true);                    
                    });
                }
            } else {
                if (this.state.value !== null && this.state.value !== '' && false === regex.test(this.state.value)) {
                    this.setState({phoneerror: 'Bitte geben Sie eine gültige Handynummer an!'}, () => {
                        log('AnswerElementPhoneOptIn: valid resolve false');
                        resolve(false);
                    });
                } else {
                    this.setState({phoneerror: null}, () => {
                        log('AnswerElementPhoneOptIn: valid resolve true');
                        resolve(true);                    
                    });
                }
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    handleChangePhone(event: any) {
        let value = this.state.value;
        value = event.target.value || '';
        this.setState({value: value}); 
        this.props.handleChange(value, this.props.el.externalid);
    }
 
}
export default AnswerElementPhoneOptIn