import { Box } from '@mui/material';
//import Typography from '@mui/material/Typography';
//import { useHistory } from 'react-router-dom';
//import CSS from 'csstype';

//import Step from '@material-ui/core/Step';
//import StepLabel from '@material-ui/core/StepLabel';
//import { Hidden } from '@material-ui/core';

//import Stepper from '@material-ui/core/Stepper';

function NewContactConfirmationThankYou() {
    //const history = useHistory();

    /* const underline: CSS.Properties = {
         textDecoration: 'underline'
     };
 
     const isSmallScreen = false; // useMediaQuery(theme.breakpoints.down("md"));
     let windowUrl = window.location.search;
     let params = new URLSearchParams(windowUrl);
     let linkId:string = params.get('linkid') || '';
     let cid:string = params.get('cid') || '';*/
    return <Box sx={{ padding: 3 }}>

        <h3>Herzlichen Glückwunsch </h3>

        <Box className="answer-element-heading" sx={{
            fontSize: '1.1rem',
            fontWeight: 600,
            paddingBottom: '1rem'
        }}>
            <div>Wir haben Ihre neue E-Mail-Adresse hinterlegt!</div>
        </Box>
        {/* 
        <Stepper orientation={isSmallScreen ? 'vertical' : 'horizontal'} >
            <Step key="email">
                <StepLabel><Hidden smDown>Ihre E-Mail-Adresse</Hidden></StepLabel>
            </Step>
            <Step key="monil">
                <StepLabel><Hidden smDown>Ihre Mobilfunknummer</Hidden></StepLabel>
            </Step>
            <Step key="Confirmation">
                <StepLabel><Hidden smDown>Bestätigung</Hidden></StepLabel>
            </Step>
        </Stepper>

     
        <Typography variant="body1" sx={{paddingTop: 3, fontWeight: 700}}>
        Sie können diesen Tab Jetzt schließen und mit der Registrierung fortfahren. 
        </Typography>
        <Typography variant="body1" sx={{paddingTop: 3}}>
        Sollten Sie die Registrierung nicht mehr geöffnet haben können Sie <a href={"/link/open?linkid="+linkId+"&cid="+cid} onClick={()=> history.push("/link/open?linkid="+linkId+"&cid="+cid)} title="Zurück zur Registrierung" style={underline}>hier</a> fortfahren
        </Typography>
  

        <Typography variant="body1" sx={{ paddingTop: 3, fontWeight: 700 }}>
       
            Sie haben erfolgreich Ihr Digitales Postfach aktiviert. Ab sofort erhalten Sie Ihre Dokumente schnell,<br />
            einfach und direkt. In Kürze erhalten Sie eine E-Mail mit Ihrem direkten Zugang zu Ihrem Digitalen Postfach.
        </Typography>
          */}
    </Box>
}

export default NewContactConfirmationThankYou;