import { Box, Container, Typography } from '@mui/material';
import { AppState, DefaultProps } from '../../types/types';


import React from "react";
interface FooterProps extends DefaultProps {
    appState: AppState,
}


function Footer(props: FooterProps) {
    let impressum = props.appState?.customizing?.impressumSrc || 'https://www.rheinland-versicherungen.de/impressum/';
    let datenschutz = props.appState?.customizing?.datenschutzSrc || 'https://www.rheinland-versicherungen.de/datenschutz/digitales-postfach';

    let agb = props.appState?.customizing?.agbSrc;
    let kontaktdatenAendern = props.appState?.customizing?.kontaktdatenAendern;
    let faq = props.appState?.customizing?.faq;
    let postfix = props.appState?.customizingId || 'rl';
    let showFlixcheck = (postfix === 'RHEINLAND' || postfix === 'rl');
    let showCopyright = props.appState.customizingId === 'rl';

    const currentYear = new Date().getFullYear();
    return (
        <Box sx={{
            backgroundColor: 'footer.main' || '#0069b4',
            color: 'footer.contrastText' || '#4444',
            bottom: 0,
            padding: 0,
            margin: 0,
            width: '100%',
            paddingBottom: '12px',
            marginBottom: { xs: '-53px', sm: '-53px', md: '-53px', lg: '-20px' },
            paddingTop: '14px',
            textAlign: 'center',
            position: 'absolute',
            overflow:'hidden',
            whiteSpace: 'nowrap',
            "& a:hover": {
                textDecoration: 'underline',
            },
            "& a": {
                textDecoration: 'none',
                color: 'footer.contrastText' || '#4444'
            },
        }}

        >
            <Container maxWidth='lg' sx={{}}>
                <Box sx={{display: {lg: 'flex', xl: 'flex'}, justifyContent: 'center', margin: '0 auto'}}>
                    {showCopyright && <Box sx={{padding: '0.5em'}}><Typography component="span">© RheinLand Versicherungen {currentYear}</Typography></Box>}
                    <Box sx={{padding: '0.5em'}}>
                        <a href={impressum} target="_blank" rel="noreferrer">Impressum</a>
                    </Box>
                    {showFlixcheck && <Box sx={{padding: '0.5em'}}>
                        <a href={("" + process.env.REACT_APP_RHEINLAND_FLIXCHECK_TECHNISCHE_UNTERSTUETZUNG_URL)}
                           target="_blank" rel="noreferrer">Technische Unterstützung</a>
                    </Box>}
                    <Box sx={{padding: '0.5em'}}>
                        <a href={datenschutz} target="_blank" rel="noreferrer">Datenschutz</a>
                    </Box>
                    {!!faq && <Box  sx={{padding: '0.5em'}}>
                        <a href={faq} target="_blank" rel="noreferrer">FAQ</a>
                    </Box>}


                    {!!agb && <Box sx={{padding: '0.5em'}} >
                        <a href={agb} target="_blank" rel="noreferrer">Nutzungsbedingungen</a>
                    </Box>}


                    {!!kontaktdatenAendern && <Box sx={{padding: '0.5em'}} >
                        <a href={kontaktdatenAendern} target="_blank" rel="noreferrer">Kontaktdaten ändern</a>
                    </Box>}
                </Box>

            </Container>

        </Box>
    );
}
export default Footer;