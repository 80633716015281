

import { ReactNode, createContext, useState } from 'react';

interface SuccessReplacementProviderProps {
    children: ReactNode;
}

// Definiere das Interface für Key-Value-Paare in der replacementList
export interface ReplacementItem {
    key: string;
    value: string;
}

// Erweiterte Context-Schnittstelle
export type SuccessReplacementContextInterface = {
    replacementList: ReplacementItem[];
    addReplacementItem: (item: ReplacementItem) => void;
    removeReplacementItem: (key: string) => void;
    updateReplacementItem: (key: string, value: string) => void;
};

// Standardwert für den Context
const defaultContextValue: SuccessReplacementContextInterface = {
    replacementList: [],
    addReplacementItem: () => {},
    removeReplacementItem: () => {},
    updateReplacementItem: () => {},
};

export const SuccessReplacementContext = createContext<SuccessReplacementContextInterface>(defaultContextValue);

// Erstelle den Provider
const SuccessReplacementProvider: React.FC<SuccessReplacementProviderProps> = ({ children }) => {
    const [replacementList, setReplacementList] = useState<ReplacementItem[]>([]);

    // Hinzufügen eines neuen Key-Value-Paars zur Liste
    const addReplacementItem = (item: ReplacementItem) => {
        setReplacementList((prevList) => [...prevList, item]);
    };

    // Entfernen eines Key-Value-Paars anhand des Schlüssels
    const removeReplacementItem = (key: string) => {
        setReplacementList((prevList) => prevList.filter((item) => item.key !== key));
    };

    // Aktualisieren des Werts eines bestimmten Schlüssels
    const updateReplacementItem = (key: string, newValue: string) => {
        setReplacementList((prevList) =>
            prevList.map((item) =>
                item.key === key ? { ...item, value: newValue } : item
            )
        );
    };

    return (
        <SuccessReplacementContext.Provider value={{ replacementList, addReplacementItem, removeReplacementItem, updateReplacementItem }}>
            {children}
        </SuccessReplacementContext.Provider>
    );
};

export default SuccessReplacementProvider;