import DOMPurify from 'dompurify';
import { ContactType, CustomerContext } from '../../../types/apimodel';
import { DefaultProps } from '../../../types/types';
import { Box } from '@mui/material';

interface CustomerContactBlockProps extends DefaultProps {
    el: CustomerContext | null,
    text: string | null | undefined
}

function CustomerContactBlock(props:CustomerContactBlockProps) {

        let block;
        if (props.text !== null && props.text !== undefined && props.el) {
            // Text aus der Datenbank.
            let clean:string = (props.text && DOMPurify.sanitize( props.text, { ADD_ATTR: ['target'] } )) || '';
            block = <Box dangerouslySetInnerHTML={{__html: clean}} className="contact-confirm"></Box>;
        } else if (props.el && String(props.el.contactType) === ContactType[ContactType.EMAIL] && props.el.email) {
            block = <Box/>;
            /*
            <Box className="contact-confirm">
                <Box><b>Diese Nachricht wurde gesendet an: {props.el && String(props.el.contactType) === ContactType[ContactType.EMAIL] && props.el.email}</b></Box>
                Wenn dies nicht Ihre Mailadresse ist, dann ignorieren Sie diese Nachricht bitte.
            </Box>
            */
        } else if (props.el && String(props.el.contactType) === ContactType[ContactType.CELLPHONE] && props.el.cellPhoneNumber) {
            block = 
            <Box className="contact-confirm">
                <Box className="fw-bold">Diese Nachricht wurde gesendet an: {props.el && String(props.el.contactType) === ContactType[ContactType.CELLPHONE] && props.el.cellPhoneNumber}</Box>
                Wenn Sie dies nicht Ihre Telefonnummer ist, dann ignorieren Sie diese Nachricht bitte.
            </Box>
        } 
        return (
            <Box>
                {block}
            </Box>
        )
}
export default CustomerContactBlock