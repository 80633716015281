import { Button, LinearProgress } from "@mui/material";
import { AuthContext, ContactType, SaveContactConfirmationResponse, TemplateElementConfigKey, AnswerElement } from "../../../../types/apimodel";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import LinkedappBackend from '../../../../services/LinkedappBackend';
import { AnswerElementDefaultProps, AnswerElementDefaultState, AnswerState } from "../../../../types/types";
import React from "react";
import { Grid, TextField, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import DOMPurify from "dompurify";
import { findValueInConfig } from '../../../../util/util';


import { AnswerCtx } from './../useAnswerCtx';


interface AnswerElementEmailInputWithValidationProps extends AnswerElementDefaultProps {
    linkId: string | null,
    authContext: AuthContext | null,
    addButtonDisabler: any,
    removeButtonDisabler: any,
}

interface AnswerElementEmailInputWithValidationState extends AnswerElementDefaultState {
    value: string | '',
    confirmed: boolean,
    initing: boolean,
    mailSending: boolean,
    pending: boolean,
    expired: boolean,
    textBeforeSend: string | null,
    textWhileSending: string | null,
    textAfterSend: string | null,
    pollingEnabledInConfig: boolean | false,
    buttonHiddenWhileNotValidated: boolean | false,
    showTanField: boolean | false,
    tan: string | '',
    tanTesting: boolean | false,
    tanError: string | null,
    awaitingConfirmation: boolean
}



class AnswerElementEmailInputWithValidation extends React.Component<AnswerElementEmailInputWithValidationProps, AnswerElementEmailInputWithValidationState> {

    constructor(props: any) {
        super(props);
        let value = this.props.value || '';
        let textBeforeSendFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_TEXT_BEFORE_SEND]);
        let cleanTextBeforeSend: string | null = (textBeforeSendFromConfig !== null && textBeforeSendFromConfig !== undefined && DOMPurify.sanitize(textBeforeSendFromConfig, { ADD_ATTR: ['target'] })) || null;
        let textWhileSendingFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_TEXT_WHILE_SENDING]);
        let cleanTextWhileSending: string | null = (textWhileSendingFromConfig !== null && textWhileSendingFromConfig !== undefined && DOMPurify.sanitize(textWhileSendingFromConfig, { ADD_ATTR: ['target'] })) || null;
        let textAfterSendFromConfig = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_TEXT_AFTER_CONFIRM]);
        let cleanTextAfterSend: string | null = (textAfterSendFromConfig !== null && textAfterSendFromConfig !== undefined && DOMPurify.sanitize(textAfterSendFromConfig, { ADD_ATTR: ['target'] })) || null;
        let pollingEnabledInConfig: boolean = Boolean(findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_POLLING])) || false;
        let buttonHiddenWhileNotValidated: boolean = Boolean(findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_HIDE_SAVE_BUTTON])) || false;
        let showTanField = Boolean(findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.EMAIL_VALI_SHOW_TAN])) || false;
        this.state = { value: value, initing: true, expired: false, confirmed: false, mailSending: false, pending: false, error: null, showTanField: showTanField, tanError: null, tanTesting: false, textBeforeSend: cleanTextBeforeSend, textWhileSending: cleanTextWhileSending, textAfterSend: cleanTextAfterSend, pollingEnabledInConfig: pollingEnabledInConfig, buttonHiddenWhileNotValidated: buttonHiddenWhileNotValidated, tan: '', awaitingConfirmation: false };
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.registerValidation(this.validate);
        this.handleTANChange = this.handleTANChange.bind(this);
        this.onTanBlur = this.onTanBlur.bind(this);
        this.onTanKeyPress = this.onTanKeyPress.bind(this);
        this.confirmContact = this.confirmContact.bind(this);
        this.initContactConfirmationStatus();
    }

    // Siehe https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html

    // Beim Einhängen der Komponente wird direkt ein Status-Update gemacht. 
    // Die Promises der Server-Calls (siehe auch initContactConfirmationStatus() und pollContactConfirmationStatus())
    // kommen aber zurück, wenn die Komponente bereits unmounted wird.    
    // Lösung: entweder cancelbare Callbacks oder halt hier die schnelle Lösung über die Abfrage.
    private mounted: boolean = false;

    componentWillUnmount() {
        this.mounted = false;
        // Disablen des Speichern-Buttons wieder entfernen, weil die Komponente unsichtbar wird.
        this.props.removeButtonDisabler(this.props.el.externalid);
    }

    getPrefillValue(): string | undefined {
        //console.log("#### ", t?.answer?.answer.answerElement.value);
        const t : AnswerState | undefined = this.context?.state;
        let depElementId = this.props.el.dependencyConfig?.dependencies.value[0].elementid;
        let radioElement: AnswerElement | undefined = t?.answer?.answer.answerElement.value.find(element => element.element.externalid === depElementId);

        if (radioElement){
            let radioValueText = radioElement?.input?.value[0]?.value;
            //console.log("##5:  ", radioValueText);
            if (radioValueText && radioValueText !== "NOENTRY"){
                return radioValueText;
            }
        }

        return undefined;
    }

    componentDidMount() {
        this.mounted = true;
        // Disablen des Speichern-Buttons einhängen, weil ohne Eingabe der Mailadresse der Prozess blockiert werden soll.
        this.props.addButtonDisabler(this.props.el.externalid, this.state.buttonHiddenWhileNotValidated);
    }

    componentDidUpdate(prevProps: Readonly<AnswerElementEmailInputWithValidationProps>, prevState: Readonly<AnswerElementEmailInputWithValidationState>, snapshot?: any): void {
        const prefillValue = this.getPrefillValue();
        // console.log("Context State", this.context?.state);
        // console.log("State", this.state?.value);
        if( prefillValue !== undefined &&
            (!this.state.value || this.isPrefilledByDependency(this.context?.state))
            && this.state.value !== prefillValue) {
            // console.log("Update state")
            this.props.handleChange(prefillValue, this.props.el.externalid);
            this.setState({ value: prefillValue, error: null });
        }
    }

    confirmContact() {
        this.setState({ ...this.state, tanTesting: true }, () => {
            if (this.props.linkId !== null && this.state.tan !== null) {
                // Sonderzeichen wegstrippen.
                const regex = /[^a-zA-Z0-9]/ig;
                let tanToTest = this.state.tan.replaceAll(regex, '');
                LinkedappBackend.saveContactConfirmation(this.props.linkId, tanToTest).then((response: SaveContactConfirmationResponse) => {
                    if (true === response.success) {
                        // Button freischalten
                        this.props.removeButtonDisabler(this.props.el.externalid);
                        if (this.mounted === true) {
                            this.setState({ ...this.state, pending: false, confirmed: true, error: null, tanError: null, tanTesting: false });
                        }

                    } else {
                        if (this.mounted === true) {
                            this.setState({ ...this.state, pending: true, confirmed: false, tanError: response.error ? "Die TAN ist nicht korrekt." : null, tanTesting: false });
                        }
                    }
                }).catch((error) => {
                    if (this.mounted === true) {
                        this.setState({ ...this.state, pending: true, confirmed: false, tanError: "Die TAN ist nicht korrekt.", tanTesting: false });
                    }
                });
            }
        });
    }

    handleTANChange(event: any) {
        let tan: string = (event.target.value !== undefined && event.target.value != null) ? event.target.value.trim() : '';
        this.setState({ ...this.state, tan: tan });
    }

    onTanBlur(event: any) {

    }

    onTanKeyPress(event: any) {
        if (event.key === 'Enter') {
            this.setState({ tan: event.target.value.trim() }, () => {
                this.confirmContact();
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }


    isPrefilledByDependency = (t : AnswerState | undefined) : boolean => {
        let depElementId = this.props.el.dependencyConfig?.dependencies.value[0].elementid;
        let radioElement: AnswerElement | undefined = t?.answer?.answer.answerElement.value.find(element => element.element.externalid === depElementId);
        const radioValueText = radioElement?.input?.value[0]?.value;
        return !!radioValueText && radioValueText !== "NOENTRY";
    }

    render() {
        const showInput = this.state.confirmed === false && this.state.mailSending === false && this.state.pending === false;
        return (
            <div className='control-wrapper'>
                {showInput && <>
                    {this.state.expired === true && <Grid container className="row" sx={{ paddingBottom: 3 }}>
                        <Grid item xs={12} sx={{ color: '#ff0000', fontWeight: 700 }}>
                            Ihr Bestätigungslink ist leider abgelaufen. Bitte fordern Sie den Bestätigungslink erneut an!
                        </Grid>
                    </Grid>}
                    <Grid container className="row" sx={{ paddingBottom: 3 }}>
                        
                        {this.state.textBeforeSend !== null &&
                            <Grid item xs={12} md={12} dangerouslySetInnerHTML={{ __html: this.state.textBeforeSend }}></Grid>
                        }
                    </Grid>
                    <Grid container className="row" sx={{ paddingBottom: 3 }}>
                        <Grid item xs={12} md={8}>
                                <TextField onChange={this.handleChange} onBlur={this.onBlur} onKeyPress={this.keyPress} disabled={!showInput || this.isPrefilledByDependency(this.context?.state)} placeholder='Ihre Mailadresse' value={this.state.value}
                                    sx={{ width: '100%' }}
                                    error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} 
                                    />
                            <Box sx={{ paddingTop: '8px', fontSize: '13px' }}>{findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.HINT])}</Box>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ paddingTop: { xs: 3, md: 0.5 }, paddingLeft: { xs: 0, md: 3 }, textAlign: 'center' }}>
                            {/*<Button title="Bestätigungslink anfordern" disabled={!showInput} onClick={this.sendConfirmationLink} endIcon={<SendIcon />} >E-Mail anfordern</Button>*/}
                        </Grid>
                    </Grid>
                </>}
                {this.state.mailSending === true && <Grid container className="row" sx={{ paddingBottom: 3 }}>
                    <Grid item xs={12} md={12}>Vielen Dank für Ihre Bestätigung, Ihre Kontaktdaten werden gerade aktualisiert.</Grid>
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><LinearProgress /></Grid>
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><Box sx={{ paddingTop: 3 }}>E-Mail wird gesendet.....</Box></Grid>
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><Button color="primary" title="Abbrechen" sx={{ height: 30, textTransform: 'unset' }} variant="outlined" onClick={(e) => this.setState({mailSending: false, pending: false})} endIcon={<CloseIcon />}>Abbrechen</Button></Grid>
                </Grid>}
                {this.state.pending === true && <Grid container className="row" sx={{ paddingBottom: 3 }}>

                    {this.state.textWhileSending !== null &&
                        <Grid item xs={12} md={12} dangerouslySetInnerHTML={{ __html: this.state.textWhileSending }}></Grid>
                    }
                    {
                        this.state.pollingEnabledInConfig && <><Grid item xs={12} md={12} sx={{ paddingTop: 3 }}>
                            <LinearProgress color="secondary" />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><Button color="primary" title="Abbrechen" sx={{ height: 30, textTransform: 'unset' }} variant="outlined"  onClick={(e) => this.setState({mailSending: false, pending: false})} endIcon={<CloseIcon />}>Abbrechen</Button></Grid>
                        </>
                    }
                    {(this.state.pollingEnabledInConfig && this.state.showTanField) && <Grid item xs={12} md={12}>
                        <Box sx={{ paddingTop: 3, paddingBottom: 2 }}>Wenn Sie nicht auf den Link in der Mail klicken möchten, dann können Sie hier die TAN aus der Mail eingeben:</Box>
                        <TextField label="TAN" type="text" value={this.state.tan} onChange={this.handleTANChange} onBlur={this.onTanBlur} id={this.props.el.externalid + "-tan"} size="medium" onKeyPress={this.onTanKeyPress}
                            error={(this.state.tanError && this.state.tanError !== null) || false} helperText={this.state.tanError} disabled={this.state.tanTesting}
                            InputProps={{ endAdornment: <IconButton edge="end" color="primary" onClick={this.confirmContact} disabled={this.state.tanTesting}><SendIcon /></IconButton> }} />
                    </Grid>}
                    <Grid item xs={12} md={12} sx={{ paddingTop: 3 }}><Box sx={{ paddingBottom: 1 }}><h3>Keine Bestätigungs-E-Mail erhalten?</h3></Box><Button title="Mail erneut senden" sx={{ height: 30, textTransform: 'unset' }} variant="outlined" onClick={(e) => this.sendConfirmationLink(e, true)} endIcon={<SendIcon />}>Mail erneut senden</Button></Grid>
                    {!this.state.pollingEnabledInConfig && <Grid item xs={12} md={12} sx={{ paddingTop: 5, fontWeight: 700 }}>Sobald Sie die Bestätigungs-E-Mail erhalten haben, können Sie dieses Fenster schließen.</Grid>}
                </Grid>}
                {this.state.confirmed === true &&
                    <>
                        {this.state.textAfterSend === null && <><h3>Danke, Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt!</h3>Wir haben Ihre E-Mail-Adresse ( <b>{this.state.value}</b> ) für das Digitale Postfach hinterlegt.</>}
                        {this.state.textAfterSend !== null && <Box dangerouslySetInnerHTML={{ __html: this.state.textAfterSend }}></Box>}

                    </>
                }
            </div>
        );
    }

    keyPress(event: any): any {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
        }
        /*
        if (event.key === 'Enter') {
            this.setState({ value: event.target.value.trim() }, () => {
                this.validate(true).then((success) => {
                    if (success) {
                        this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
                        this.sendConfirmationLink(event);
                    }
                });
            });
            event.stopPropagation();
            event.preventDefault();
        }
        */
    }

    onBlur = (event: any) => {
        this.setState({ value: event.target.value.trim() }, () => {
            this.validate(true).then((success) => {
                if (success) {
                    this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
                }
            });
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     */
    validate = (onlyMail?: boolean): Promise<boolean> => {
        return new Promise((resolve) => {
            let regexStr = /^([a-zA-ZäÄöÖüÜ0-9-_.]+)@(([a-zA-ZäÄöÖüÜ0-9-])+(.)){1,}(\.)([a-zA-Z]){2,}$/;
            let regex = new RegExp(regexStr);
            onlyMail = true;
            if (true !== onlyMail) {
                if (this.state.confirmed === true) {
                    resolve(true);
                    return;
                } else {
                    let mailRegexValidated = regex.test(this.state.value);
                    if (mailRegexValidated === false) {
                        this.setState({ ...this.state, error: 'Bitte geben Sie eine gültige Mailadresse an!' }, () => resolve(false));
                        return;
                    } else {
                        this.setState({ ...this.state, error: 'Bitte fordern Sie zunächst die Bestätigungsnachricht an!' }, () => resolve(false));
                        return;
                    }
                }
            } else {
                let mailRegexValidated = regex.test(this.state.value);
                if (mailRegexValidated === false) {
                    this.setState({ ...this.state, error: 'Bitte geben Sie eine gültige Mailadresse an!' });
                    resolve(false);
                    return;
                } else {
                    this.setState({ ...this.state, error: null });
                    resolve(true);
                }
            }
        });
    }

    initContactConfirmationStatus = () => {
        if (this.props.linkId !== null && this.props.authContext !== null) {
            LinkedappBackend.getContactConfirmation(this.props.linkId, this.props.authContext, ContactType.EMAIL).then((data) => {
                if (true === data.confirmed) {
                    // Button freischalten
                    this.props.removeButtonDisabler(this.props.el.externalid);
                    this.props.handleChange(this.props?.value??'', this.props.el.externalid);
                }
                if (this.mounted === true) {
                    const prefillValue = this.getPrefillValue();
                    this.setState({ ...this.state, expired: ('expired' === data.error), confirmed: data.confirmed, initing: false, value: prefillValue??this.state.value });
                    if (this.state.pollingEnabledInConfig) {
                        this.pollContactConfirmationStatus();
                    }
                }
            }).catch((error) => {
                // TODO: Fehlermeldung anzeigen
                if (this.mounted === true) {
                    this.setState({ ...this.state, pending: false, confirmed: false, initing: false });
                }
            });
        }
    }

    pollContactConfirmationStatus = () => {
        if (this.props.linkId !== null && this.props.authContext !== null && this.state.pending === true) {
            LinkedappBackend.getContactConfirmation(this.props.linkId, this.props.authContext, ContactType.EMAIL).then((data) => {
                if (true !== data.confirmed && this.mounted === true) {
                    window.setTimeout(() => {
                        this.pollContactConfirmationStatus();
                    }, 5000);
                } else if (true === data.confirmed) {
                    // Button freischalten
                    this.props.removeButtonDisabler(this.props.el.externalid);
                    if (this.mounted === true) {
                        this.setState({ ...this.state, pending: false, confirmed: true, error: null });
                    }
                }
            }).catch((error) => {
                if (this.mounted === true) {
                    this.setState({ ...this.state, pending: true, confirmed: false, error: 'Auf unserem Server ist etwas schiefgegangen. Wir probieren es in wenigen Sekunden nochmal...' });
                }
            });
        }
    }

    sendConfirmationLink = (event: any, force?: boolean) => {
        this.validate(true).then((valid) => {
            if (valid === true) {
                let email: ContactType = ContactType.EMAIL;
                if (this.props.linkId !== null && this.props.authContext !== null && (this.state.pending === false || true === force)) {
                    this.setState({ ...this.state, mailSending: true, error: null, expired: false });

                    LinkedappBackend.sendContactConfirmation(this.props.linkId, this.props.authContext, email, this.state.value).then((data) => {
                        if (data !== null) {
                            if (true !== data.success) {
                                this.setState({ ...this.state, mailSending: false, pending: false, error: 'Da ist etwas schiefgegangen. Bitte probieren Sie es nochmal!' });
                            } else {
                                this.setState({ ...this.state, mailSending: false, pending: true });
                                if (this.state.pollingEnabledInConfig && this.mounted === true) {
                                    window.setTimeout(() => {
                                        this.pollContactConfirmationStatus();
                                    }, 5000);
                                }
                            }
                        }
                    }).catch((error) => {
                        this.setState({ ...this.state, mailSending: false, pending: false, error: 'Auf unserem Server ist etwas schiefgegangen. Bitte probieren Sie es nochmal!' });
                    });
                }
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc: any): any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {
        this.setState({ value: event.target.value });//, () => {
        //this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
        //});
    }

}

AnswerElementEmailInputWithValidation.contextType = AnswerCtx;

export default AnswerElementEmailInputWithValidation;
