import { Box, Grid } from '@mui/material';
//import Typography from '@mui/material/Typography';
//import { useHistory } from 'react-router-dom';
//import CSS from 'csstype';

//import Step from '@material-ui/core/Step';
//import StepLabel from '@material-ui/core/StepLabel';
//import { Hidden } from '@material-ui/core';

//import Stepper from '@material-ui/core/Stepper';

import { Button } from '@mui/material';
import { AppState, DefaultProps } from '../../../types/types';

export interface NewContactConfirmationVerifyProps extends DefaultProps {
    appState:AppState
}

function NewContactConfirmationVerify(props: NewContactConfirmationVerifyProps) {
    //const history = useHistory();

    /* const underline: CSS.Properties = {
         textDecoration: 'underline'
     };
 
     const isSmallScreen = false; // useMediaQuery(theme.breakpoints.down("md"));
     let windowUrl = window.location.search;
     let params = new URLSearchParams(windowUrl);
     let linkId:string = params.get('linkid') || '';
     let cid:string = params.get('cid') || '';*/


    let windowUrl = window.location.search;

    let params = new URLSearchParams(windowUrl);
    let clientemail: string = params.get('clientemail') || '';
    let targeturl: string = atob(params.get('targeturl') || '')+"&clientemail="+clientemail;
    
    const openTargetUrl = () => {
        let decodedTargetUrl = targeturl.replace("MailServerPrefix", "").replace(/#/g, "/");

          // Den Browser-Agenten abrufen
            const userAgent = navigator.userAgent;

            // Den Browser-Agenten in Base64 codieren
            const base64EncodedUserAgent = btoa(userAgent);
        decodedTargetUrl = decodedTargetUrl + "&userAgent="+base64EncodedUserAgent;
        console.log("Opening: " + decodedTargetUrl);
        window.location.href = decodedTargetUrl;
    };
    return <Box sx={{ padding: 3 }}>


        <Grid container spacing={3}>
            <Grid item xs={12} className="answer-element-heading" sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                paddingBottom: '1rem',

            }}>
                <div>E-Mail Bestätigung</div>


            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2} className='control-wrapper' sx={{
            background: props.appState?.customizing?.colors?.backgroundColor || 'linear-gradient(170deg, rgb(255 255 255 / 15%) 0%, rgba(255,255,255,0) 100%)',
            paddingBottom:'20px',
        }}>
            <Grid item xs={12} sm={12} md={7} lg={7} >
                <Box>
                    Bitte bestätigen Sie uns Ihre E-Mail-Adresse <br />
                    <b>{clientemail}</b>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <Box>
                    <Button onClick={openTargetUrl}
                    sx={{minWidth:'290px',
                    maxWidth:'290px',
                    maring:'auto',

                    float:{xl:'right',lg:'right',md:'right'},
                    marginRight:{xl:'20px',lg:'20px',md:'20px'},
            }} 
                >E-MAIL-ADRESSE BESTÄTIGEN</Button>
                </Box>
            </Grid>
        </Grid>



    </Box>
}

export default NewContactConfirmationVerify;