import { Box, TextField } from '@mui/material';
import React from 'react';
import { SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig, log } from '../../../../util/util';

interface AnswerElementEmailOptinProps extends AnswerElementDefaultProps {
    type: string | 'radio',
}

interface AnswerElementEmailOptinState  {
    value: string,
    mailerror: null|string,
}

/**
 * 
 * Stellt eine Auswahlmöglichkeit bereit (entweder als Radiobutton (type="radio") oder als Dropdown (type="select")), aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */
class AnswerElementEmailOptin extends React.Component<AnswerElementEmailOptinProps, AnswerElementEmailOptinState> {
    
    constructor(props:any) {
        super(props);
        let value:string = this.props.value !== null && this.props.value !== undefined ? this.props.value : "";
        this.state = {value: value, mailerror: null};

        // Initialwert hochbubblen
        this.props.handleChange(value, this.props.el.externalid);

        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.handleChangeMail = this.handleChangeMail.bind(this);
    }

    render() {    
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                        <>
                            <Box>
                                <TextField label="Ihre E-Mail-Adresse" type="text" value={this.state.value} required={this.props.el.mandatory}
                                onChange={this.handleChangeMail} id={this.props.el.externalid+"-mail"} 
                                error={(this.state.mailerror && this.state.mailerror !== null) || false} helperText={this.state.mailerror} size="medium" disabled={this.props.readonly} sx={{width: '50%'}} />
                                <Box sx={{paddingTop: '8px', fontSize: '13px'}}>{this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.HINT])}</Box>
                            </Box>
                        </>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig():SelectItem[] {
        let items:SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);        
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {            
            // Wenn Mandatory: ist select ausgewählt?
            // Prüfen: Wenn "JA" => Email muss ausgefüllt und valid sein
            let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
            let mandatory:boolean = this.props.el.mandatory === true || (valiMsgMandatory !== undefined && valiMsgMandatory !== '');
            let regexStr = /^([a-zA-ZäÄöÖüÜ0-9-_.]+)@(([a-zA-ZäÄöÖüÜ0-9-])+(.)){1,}(\.)([a-zA-Z]){2,}$/;
            let regex = new RegExp(regexStr);
            if (mandatory) {
                if (this.state.value === null || this.state.value === '') {
                    this.setState({mailerror: 'Bitte geben Sie eine gültige Mailadresse an!'}, () => {
                        log('AnswerElementEmailOptin: valid resolve false');
                        resolve(false);
                    });                    
                } else if (this.state.value !== null && false === regex.test(this.state.value)) {
                    this.setState({mailerror: 'Bitte geben Sie eine gültige Mailadresse an!'}, () => {
                        log('AnswerElementEmailOptin: valid resolve false');
                        resolve(false);
                    });
                } else {
                    this.setState({mailerror: null}, () => {
                        log('AnswerElementEmailOptin: valid resolve true');
                        resolve(true);                    
                    });
                }
            } else {
                if (this.state.value !== null && this.state.value !== '' && false === regex.test(this.state.value)) {
                    this.setState({mailerror: 'Bitte geben Sie eine gültige Mailadresse an!'}, () => {
                        log('AnswerElementEmailOptin: valid resolve false');
                        resolve(false);
                    });
                } else {
                    this.setState({mailerror: null}, () => {
                        log('AnswerElementEmailOptin: valid resolve true');
                        resolve(true);                    
                    });
                }
            }

        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    handleChangeMail(event: any) {
        let value = this.state.value;
        value = event.target.value || '';
        this.setState({value: value}); 
        this.props.handleChange(value, this.props.el.externalid);
    }
 
}
export default AnswerElementEmailOptin