import { Box, TextField } from '@mui/material';
import React from 'react';
import { ClientFieldValidationResult, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultProps, AnswerElementDefaultState } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';

interface AnswerElementTextInputProps extends AnswerElementDefaultProps {
    serverValidation: any,
}

interface AnswerElementTextInputState extends AnswerElementDefaultState {
    value: string | '',
    hasServerValidation: boolean | false,
}
class AnswerElementTextInput extends React.Component<AnswerElementTextInputProps, AnswerElementTextInputState> {
    
    constructor(props:any) {
        super(props);
        let value = this.props.value || '';
        let hasServerValidation:boolean = Boolean(this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.SERVER_VALIDATED]) || false);
        this.state = {value: value, error: null, hasServerValidation: hasServerValidation};
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.keyPress = this.keyPress.bind(this);
    }

    render() {    
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    <TextField label={this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL])} type={this.props.type} value={this.state.value} required={this.props.el.mandatory}
                    onChange={this.handleChange} onBlur={this.onBlur} id={this.props.el.externalid+"-input"} 
                    error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress} />
                    <Box sx={{paddingTop: '8px', fontSize: '13px'}}>{this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.HINT])}</Box>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Benötigt, weil ansonsten im Eingabefeld beim Druck auf Enter nicht onBlur() gefeuert und damit 
     * die Validierung und die Übernahme des Wertes nicht funktionieren.
     * 
     * @param event 
     * 
     */
    keyPress(event:any):any {
        if(event.key === 'Enter'){
            event.target.blur(); 
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    /**
     * Validierung des Inputs. Durch die chained promises leider
     * nicht sehr übersichtlich.
     * 
     */
    validate():Promise<boolean> {
        return new Promise((resolve) => {
            let value = this.state.value;            
            let regex = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.REGEX]);
            let required = this.props.el.mandatory || false;
            if (!value && required) {
                // Kein Wert, aber Pflichtfeld                
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
                if (valiMsgMandatory) {
                    this.setState({error: valiMsgMandatory}, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({error: 'Bitte geben Sie einen Wert an.'}, () => {
                        resolve(false);
                    });
                }
            } else if (value && regex) {
                // Wert vorhanden und Regex vorhanden
                let regexp = new RegExp(regex);
                if (!regexp.test(value)) {
                    // Regex-Prüfung nicht ok. Entweder Meldung aus der Config oder Standard-Meldung anzeigen.                    
                    let valiMsgRegex:string = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_REGEX]) || 'Ungültiger Wert.';
                    this.setState({error: valiMsgRegex}, () => {
                        resolve(false);
                    });
                }  else {
                    // Regex-Prüfung ok. Nun Server-Validierung, falls vorhanden.
                    this.serverValidation().then((result) => {
                        if (result.valid) {
                            this.setState({error: null},() => {
                                resolve(true);
                            });
                        } else {
                            this.setState({error: result.error},() => {
                                resolve(false);
                            });
                        }
                    });
                }
            } else {
                    // Ansonsten: ok. Nun Server-Validierung, falls vorhanden.
                    this.serverValidation().then((result) => {
                        if (result.valid) {
                            this.setState({error: null},() => {
                                resolve(true);
                            });
                        } else {
                            this.setState({error: result.error},() => {
                                resolve(false);
                            });
                        }
                });
            }
        });
    }

    serverValidation():Promise<{valid:boolean, error:string|null}> {
        return new Promise((resolve) => {
            if (this.state.hasServerValidation && this.state.value !== null) {
                this.props.serverValidation(this.props.el.externalid, this.state.value).then( (result:ClientFieldValidationResult) => {
                    resolve({valid:result.valid, error: result.error || null});
                });
            } else {
                resolve({valid:true, error: null});
            }
        });
    }

    /**
     * Validierung der Werte. 
     * Nur, wenn die Validierung erfolgreich war, wird der Wert weiter an den Parent-Handler gereicht.
     * 
     * @param event 
     */
    onBlur(event:any) {
        this.setState({value: event.target.value.trim()}, () => {
            this.validate().then( (success) => {
                if (success) {
                    this.props.handleChange(event.target.value.trim(), this.props.el.externalid);
                }
            });        
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {        
       this.setState({value: event.target.value});
    }

}
export default AnswerElementTextInput