import { useEffect, useState } from 'react';
import Home from './pages/home/Home';
import Error404 from './pages/error404/Error404';
import './App.css';
import { AppState, HeaderLayout } from './types/types';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { APPLY_THEME, selectTheme } from './themes/BasicTheme';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Answer from './pages/answerLink/Answer';
import OpenShortLink from './pages/answerLink/OpenShortLink';
import { getCustomizingID, initLogger, log, setDebug } from './util/util';
import { loadStorageData } from './util/store';
import { VermittlerDaten } from './types/apimodel';
import './assets/css/google.fonts.css';
import './assets/css/frutiger.fonts.css';
import './assets/css/klavica.font.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { de } from 'date-fns/locale';
import { AnswerProvider } from './components/pages/answerLink/useAnswerCtx';
import ContactConfirmationThankYou from './components/pages/static/ContactConfirmationThankYou';
import NewContactConfirmationThankYou from './components/pages/static/NewContactConfirmationThankYou';
import NewContactConfirmationVerify from './components/pages/static/NewContactConfirmationVerify';
import ConfirmSuccess from './components/pages/confirmoptin/ConfirmSuccess';
import ConfirmError from './components/pages/confirmoptin/ConfirmError';
import Kundenakte from './components/pages/kundenakte/Kundenakte';
import SignupProvider from './components/signup/SignupProvider';
import SuccessReplacementProvider from './components/pages/answerLink/SuccessReplacementProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const locale: Locale = de;

function App() {

  const [state, setState] = useState<AppState>(
    {
      customizingId: 'void',
      customizing: selectTheme('void'),
    }
  );

  // Theme aktualisieren, sobald sich die CustomizingID ändert.
  useEffect(
    () => {
      let customizing = selectTheme(state.customizingId);
      log('customizing: ' + JSON.stringify(customizing));
      if (customizing !== null && customizing.title !== null) {
        document.title = customizing.title || 'LinkedApp';
      }
      setState(prevState => {
        return { ...prevState, customizing: customizing };
      });
    }, [state.customizingId]
  );

  const setVermittlerDaten = function (vermittlerDaten?: VermittlerDaten) {
    setState(prevState => {
      return { ...prevState, vermittlerDaten: vermittlerDaten };
    });
  }

  useEffect(() => {
  }, [state.vermittlerDaten]);

  // Theme initial aus dem URL-Parameter laden
  // Session laden
  // Logger initialisieren
  useEffect(() => {
    console.log("%c Rhein%cLand %c LinkedApp", "background: #0069b4; color: #FFFFFF", "background: #009767; color: #FFFFFF", "background: #ffffff; color: #222222");
    let isDebug = loadStorageData("isDebug") === true;
    setDebug(isDebug);
    initLogger(isDebug);
    setState(prevState => {
      return { ...prevState, customizingId: getCustomizingID(window.location.href) };
    });
  }, []);

  const setCustomizingID = function (cid: string) {
    setState(prevState => {
      return { ...prevState, customizingId: cid };
    });
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={APPLY_THEME(state?.customizingId, state.customizing, locale)}>
        <SignupProvider>
          <SuccessReplacementProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
              <CssBaseline />
              <div className="linked-app">
                {HeaderLayout[HeaderLayout.FIXED_FULL_WIDTH] === HeaderLayout[state.customizing.headerLayout] && <Header appState={state} />}
                <Container maxWidth="lg" className="content-wrapper">
                  {HeaderLayout[HeaderLayout.EMBEDDED_IN_CONTAINER] === HeaderLayout[state.customizing.headerLayout] && <Header appState={state} />}
                  <div className={"content-" + state.customizingId}>
                    <Router basename={process.env.REACT_APP_LINKEDAPP_BASENAME}>
                      <Switch>
                        <Route exact path="/l/:id/(page)?/:page?" render={props => (
                          <OpenShortLink shortLinkId={props.match.params.id} setCustomizingID={setCustomizingID} />
                        )} />
                        <Route path="/link/open/(page)?/:page?" render={props => (
                          <AnswerProvider>
                            <Answer answer={null} contact={null} link={null} appState={state} setVermittlerDaten={setVermittlerDaten} page={props.match.params.page} />
                          </AnswerProvider>
                        )} />
                        <Route path="/contactconfirmation/thankyou">
                          <ContactConfirmationThankYou appState={state} />
                        </Route>
                        <Route path="/newcontactconfirmation/verify">
                          <NewContactConfirmationVerify appState={state} />
                        </Route>
                        <Route path="/newcontactconfirmation/thankyou">
                          <NewContactConfirmationThankYou />
                        </Route>
                        <Route path="/confirmoptin/success">
                          <ConfirmSuccess appState={state} />
                        </Route>
                        <Route path="/confirmoptin/error">
                          <ConfirmError appState={state} />
                        </Route>
                        <Route path="/link/kundenakte" render={props => (
                          <Kundenakte appState={state} search={props.location.search} ></Kundenakte>
                        )} />
                        <Route exact path="/"><Home /></Route>
                        <Route path="/404"><Error404 /></Route>
                        <Route exact path="/*"><Home /></Route>
                      </Switch>
                    </Router>
                  </div>
                </Container>

                <Footer appState={state} />
              </div>
              {/*</LocalizationProvider>*/}
            </MuiPickersUtilsProvider>
          </SuccessReplacementProvider>
        </SignupProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
