import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import LinkedappBackend from "../../services/LinkedappBackend";
import { DefaultProps } from "../../types/types";
import { getCustomizingID } from "../../util/util";


interface OpenShortLinkProps extends DefaultProps {
    shortLinkId: string,
    setCustomizingID: any
}

interface OpenShortLinkState {
    loading: boolean,
    redirectUrl: string | null,
}

const error = function() {
    return (
        <Route path='/' component={() => { 
            window.location.href = process.env.REACT_APP_LINKEDAPP_BASENAME+'/404/shortlinknotfound';
            return <></>;
        }} />
    );
}

function OpenShortLink(props:OpenShortLinkProps) {
    const [state, setState] = useState<OpenShortLinkState>({loading: true, redirectUrl: null});

    useEffect( () => {
        // Theming neu laden, sobald die redirectUrl verändert wird!
        if (state.redirectUrl !== null) {
            props.setCustomizingID(getCustomizingID(state.redirectUrl));
        }
    // eslint-disable-next-line
    },[state.redirectUrl]);

    let errMsg = error();

    useEffect( () => {
        LinkedappBackend.getShortLink(props.shortLinkId).then((data) => {
            if (data !== null && data.redirectUrl !== null) {
               setState({loading: false, redirectUrl: data.redirectUrl});
            } else {
                setState({loading: false, redirectUrl: null});
            }
        }).catch((error) => {
            setState({loading: false, redirectUrl: null});
        });
    },[props.shortLinkId]);
    
    if (state.loading === false && state.redirectUrl !== null) {
        return(
            <Redirect push to={state.redirectUrl} />
        );            
    } else {
        if (state.loading === false) {
            // Geladen, aber nicht gefunden
            return(
                <div>{errMsg}</div>
            );
        } else {
            // Lädt noch
            return <div className="padding-3"><h3>Wir laden Ihren Link...</h3></div>
        }
    }
}

export default OpenShortLink