import useAnswerCtx from '../../../../components/pages/answerLink/useAnswerCtx';
import { Box } from '@mui/material';

function ValidationMessage(props: any) {

  const {state} = useAnswerCtx();

  //console.log("saveValidationMessage: " + state.saveValidationMessage);
  if (state && state.saveValidationMessage) {
    return (
      <Box>
        <div className="errormessage">
          <p><b>{state.saveValidationMessage}</b></p>
        </div>
      </Box>
    );
  } else {
    return (
      <></>
    )
  }

}

export default ValidationMessage;