import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import { TemplateElementConfigKey, AnswerElement, ArrayOfAnswerElement } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';




//import {getWizardState} from '../wizard/WizardHelper';

//import { WIZARD_PREVIOUS_STEP } from "../../../../pages/answerLink/AnswerReducer";

interface AnswerElementDiComUpdateConfirmationProps extends AnswerElementDefaultProps {
    type: string | 'checkbox',
    stateArrayOfAnswerElement :  ArrayOfAnswerElement | undefined,
    jumpToWizardStep?: any | undefined,
}

interface AnswerElementDiComUpdateConfirmationState extends AnswerElementDefaultState {
    value: boolean | false
}

class AnswerElementDiComUpdateConfirmation extends React.Component<AnswerElementDiComUpdateConfirmationProps, AnswerElementDiComUpdateConfirmationState> {

    constructor(props: any) {
        super(props);
        //let value = (this.props.value === 'false');
        let value = false;
        this.state = { value: value, error: null };
        this.handleChange = this.handleChange.bind(this);
        // Initialwert hochbubblen
        this.props.handleChange(String(value), this.props.el.externalid);
        this.registerValidation = this.registerValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.registerValidation(this.validate);
    }

    /**
     * In Material-UI ist keine Validierungsnachricht für Checkboxen vorgesehen.
     * Wir wollen aber trotzdem, dass bei einer nicht ausgefüllten Pflich-Checkbox eine Nachricht angezeigt wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && this.state.value !== true) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }

    changeEmail(){       
        this.setState({ value: false });
        this.props.handleChange("false", this.props.el.externalid);
        let seitenr = findValueInConfig(this.props.el, "WIZARD_SEITE_EMAIL");
        console.log("changeEmail - jump to page " + seitenr);
        this.props.jumpToWizardStep(Number(seitenr));
        
      };
      
/*
      changeMobile(){       
        let seitenr = findValueInConfig(this.props.el, "WIZARD_SEITE_MOBIL");
        console.log("changeMobile - jump to page " + seitenr);
        this.props.jumpToWizardStep(2);
      };
  */  
    getEmail() : string | undefined {
        let elementIdRadio = findValueInConfig(this.props.el, "ID_EMAIL_RADIO_SELECT");
        let elementIdInput = findValueInConfig(this.props.el, "ID_EMAIL_INPUT");
        return  this.getValueFromOtherInputs(elementIdRadio,elementIdInput);
    };  

    getMobileNummer() : string | undefined {

        let elementIdRadio = findValueInConfig(this.props.el, "ID_MOBIL_RADIO_SELECT");
        let elementIdInput = findValueInConfig(this.props.el, "ID_MOBIL_INPUT");
        let mobilGefunden =  this.getValueFromOtherInputs(elementIdRadio,elementIdInput);
        if (mobilGefunden){
            return mobilGefunden;
        }else {
            //Suche nach f9ca1b18c080
            if (this.props.stateArrayOfAnswerElement?.value){
                let antwortElement = this.props.stateArrayOfAnswerElement.value.find(element => element.element.externalid === "f9ca1b18c080")
                if (antwortElement && antwortElement.input?.value){
                    mobilGefunden = antwortElement.input?.value[0].value;
                }
            }
            
            return mobilGefunden;
        }
    } ; 

    getValueFromOtherInputs(elementIdRadio : string|undefined, elementIdInput : string|undefined) : string | undefined {
        let returnValue;
        let radioElement: AnswerElement | undefined = this.props.stateArrayOfAnswerElement?.value.find(element => element.element.externalid === elementIdRadio);
        if (radioElement){
            let radioValueText = radioElement?.input?.value[0]?.value;
            
            if (Number.isFinite(Number(radioValueText))){
                returnValue = radioValueText;
            } else {
                let eingabeElement: AnswerElement | undefined = this.props.stateArrayOfAnswerElement?.value.find(element => element.element.externalid === elementIdInput);
                let valueText = eingabeElement?.input?.value[0]?.value;
                returnValue =  valueText;
            }
        }
        return returnValue;
    }  
    
   

    render() {
       /*
        if (this.getEmail() === undefined){
            this.changeEmail();
        }
        if (this.getMobileNummer() === undefined){
            this.changeMobile();
        }
*/

    
        if (this.state && this.props.el) {
            return (
                <Box>
                    <Grid item xs={12} lg={12} className="answer-element" sx={{
                        borderBottom: '0px solid',
                        borderBottomColor: '#ffffff',
                        padding: '1rem',
                        margin: '-1rem',
                    }}>
                        <Box sx={{ marginBottom:'30px'}}>
                        Bitte überprüfen Sie Ihre Angaben.
                        </Box>
                        
                        <Grid container>

                            <Grid item xs={12} sm={12} md={3} lg={2} >
                                E-Mail-Adresse:
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={10} >
                            <Typography><Typography component="span" sx={{ fontWeight:'bold', paddingRight:'30px'}}>{this.getEmail()}</Typography><Typography component="span" className="textlinkInsteadOfButton"  onClick={() => this.changeEmail()}>ändern</Typography></Typography>    
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={3} lg={2} >
                                Mobilfunknummer: 
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={10} >

                                <Typography><Typography component="span" sx={{ fontWeight:'bold', paddingRight:'30px'}}>{this.getMobileNummer()}</Typography></Typography>    
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Box>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key: string): string {
        return this.state && findValueInConfig(this.props.el, key);
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
    validate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.state.value !== true) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]) || 'Bitte bestätigen Sie die Checkbox!';
                if (this.props.el.mandatory === true) {
                    this.setState({ error: valiMsgMandatory }, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({ error: null }, () => {
                        resolve(true);
                    });
                }
            } else {
                this.setState({ error: null }, () => {
                    resolve(true);
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc: any): any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any) {
        this.setState({ value: event.target.checked });
        this.props.handleChange(String(event.target.checked), this.props.el.externalid);
    }

}
export default AnswerElementDiComUpdateConfirmation