import React from 'react';
import './home.css';
import logo from '../../assets/rheinland-versicherungsgruppe.png';
import { Grid } from '@mui/material';

class Home extends React.Component {

    render() {
        return (
            <Grid container spacing={3} className="padding-3">
                <Grid item xs={12} className="center">
                    <div className="padding-3">
                        <h3>Willkommen bei der RheinLand Versicherungsgruppe!</h3>
                        <p>
                            Hier haben wir keine Inhalte für Sie, aber schauen Sie doch gerne auf der Seite unserer Unternehmensgruppe vorbei:
                        </p>
                        <a href="https://rheinland-versicherungsgruppe.de/"><img src={logo} className="startpage-logo" alt="Logo der RheinLand Versicherungsgruppe"/></a>
                    </div>
                </Grid>
                <Grid item xs={12} className="center">
                    <b>Ihre RheinLand Versicherungsgruppe</b>
                </Grid>
            </Grid>
        )
    }
}

export default Home