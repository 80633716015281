import React from 'react';

class Error404 extends React.Component {

    render() {
        return (
            <div className="padding-3">
                <h3>Seite nicht gefunden :-(</h3>
                <p>
                    Sie können uns helfen, indem Sie uns eine <a href="https://www.rheinland-versicherungen.de/service/formulare/fehler-melden/">kurze Nachricht</a> schreiben und uns mitteilen, welche Seite Sie aufrufen wollten.
                    <br/>
                    Vielen Dank für Ihr Verständnis.
                </p>
                    <p><a href="https://www.rheinland-versicherungen.de">Hier geht's zur Startseite der RheinLand Versicherungen!</a> </p>
            </div>
        )
    }
}

export default Error404