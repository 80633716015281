import { AnswerState } from "../../types/types";
import { log } from "../../util/util";

export const IS_PROCESSING = 'IS_PROCESSING';
export const IS_FETCHING = 'IS_FETCHING';
export const IS_SAVING = 'IS_SAVING';
export const PRE_SAVE_VALIDATION_ERROR = 'PRE_SAVE_VALIDATION_ERROR';
export const VALIDATION_ON_SAVE_FAILED = 'VALIDATION_ON_SAVE_FAILED';
export const SET_ANSWER = 'SET_ANSWER';
export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const ADD_VALIDATION_FUNC = 'ADD_VALIDATION_FUNC';
export const REMOVE_VALIDATION_FUNC = 'REMOVE_VALIDATION_FUNC';
export const ERROR_MSG = 'ERROR_MSG';
export const SET_DATA = 'SET_DATA';
export const SET_ANSWER_COMPLETE = 'SET_ANSWER_COMPLETE';
export const SET_AUTHCONTEXT = 'SET_AUTHCONTEXT';
export const SAVED = 'SAVED';
export const AUTH_WAIT = 'AUTH_WAIT';
export const SET_DEPENDENCIES = 'SET_DEPENDENCIES';
export const HANDLE_DEPENDENCIES = 'HANDLE_DEPENDENCIES';
export const SET_DEPENDENCIES_AND_VISIBILITIES = 'SET_DEPENDENCIES_AND_VISIBILITIES';
export const INIT_DEPENDENCIES_AND_VISIBILITIES = 'INIT_DEPENDENCIES_AND_VISIBILITIES';
export const INIT_VISIBILITIES = 'INIT_VISIBILITIES';
export const WIZARD_NEXT_STEP = 'WIZARD_NEXT_STEP';
export const WIZARD_PREVIOUS_STEP = 'WIZARD_PREVIOUS_STEP';
export const UNMOUNT_WIZARD_STEP = 'UNMOUNT_WIZARD_STEP';
export const WIZARD_STEP_VALIDATION_ERROR = 'WIZARD_STEP_VALIDATION_ERROR';
export const RESET_SCROLL_TO = 'RESET_SCROLL_TO';
export const SHARE_MENU_STATE = 'SHARE_MENU_STATE';
export const SET_ADDITIONAL_SECURITY_TOKEN = 'SET_ADDITIONAL_SECURITY_TOKEN';
export const SET_LAST_VALUE_BEFORE_UNMOUNT = 'SET_LAST_VALUE_BEFORE_UNMOUNT';
export const ADD_DISABLE_BUTTON = 'ADD_DISABLE_BUTTON';
export const REMOVE_DISABLE_BUTTON = 'REMOVE_DISABLE_BUTTON';
export const REMOVE_UNMOUNTING_ELEMENTS = 'REMOVE_UNMOUNTING_ELEMENTS';
export const REMOVE_COMMENT_BUBBLE = 'REMOVE_COMMENT_BUBBLE';

/**
 * Reducer für die Answer-Seite. 
 * Gibt den letzten State, aktualisiert um die übergebenen Werte zurück.
 * 
 *  @param prevState 
 * 
 * @param action 
 * @returns 
 */
export const answerReducer = function(prevState:AnswerState, action:any):AnswerState {
    try {
        log('action: '+ JSON.stringify(action));
    } catch (exception) {
        log('action type '+ action.type);
    }
    
    switch (action.type) {
        
        case AUTH_WAIT: {
            let waitingTime:number = 0;
            if (prevState.link !== null && prevState.link.waitUntil !== null) {
                let timeDiff:number = new Date(prevState.link.waitUntil).getTime() - new Date().getTime();
                if (timeDiff > 0) {
                    waitingTime = timeDiff;
                } 
            } 
            return {
                ...prevState,
                waitingTimeLeft: waitingTime
            }    
        }
        case IS_PROCESSING: {
            return {
                ...prevState,
                isProcessing: action.value !== undefined ? action.value : true,
            }
        }
        case SET_ANSWER_COMPLETE: {
            return {
                ...prevState,
                answerComplete: action?.answerComplete
            }
        }
        case IS_FETCHING: {
            return {
                ...prevState,
                linkId: action.linkId !== undefined ? action.linkId : prevState.linkId,
                isFetching: action.value !== undefined ? action.value : true
            }
        }
        case IS_SAVING: 
            return {
                ...prevState,
                isSaving: action.value !== undefined ? action.value : true,
                mandatoryValidationError: undefined
                
            }
        case VALIDATION_ON_SAVE_FAILED: 
            return {
                ...prevState,
                isSaving: false,
                //isFetching: false,

               // errormessage: action.value,
               
                saveValidationMessage: action.saveValidationMessage || null
     /*           wizardState: {
                    ...prevState.wizardState,
                    currentStep: action.value
                }
                */
                ,wizardState: {
                    ...prevState.wizardState,
                    isUnmounting: false
                }
            }    
        case SET_LAST_VALUE_BEFORE_UNMOUNT: {
                return {
                    ...prevState,
                    lastValueBeforeUnmount: action.lastValueBeforeUnmount
                }
        }
        case SET_ANSWER: {
            return {
                ...prevState,
                answer: action.value
            }
        }
        case HANDLE_CHANGE:
            if (prevState.isProcessing) {
                return {
                    ...prevState,
                    answer: action.value,
                    isProcessing: false
                }
            } else {
                return {
                    ...prevState
                }
            }
        case ADD_VALIDATION_FUNC:            
            return {
                    ...prevState,
                    validationFunc: prevState.validationFunc.set(action.id, action.value),
                    revalidate: action.revalidate === true ? Date.now() : prevState.revalidate
                }
        case ERROR_MSG:
            return {
                    ...prevState,
                    errormessage: action.value
                }
        case SET_DATA:
            let waitingTime:number = 0;
            if (action?.data && action.data !== null && action.data.waitUntil !== null) {
                let timeDiff:number = new Date(action.data.waitUntil).getTime() - new Date().getTime();
                if (timeDiff > 0) {
                    waitingTime = timeDiff;
                }
            } 
 
            return {
                ...prevState,
                answer: action.answer,
                authContext: action.authContext !== undefined ? action.authContext : prevState.authContext,
                link: action.data,
                contact: action.data.customerContext,
                wasAlreadyAnswered: action.data.answered,
                isFetching: false,
                isReadonly: (action.data.answered && !action.data.updateAllowed) || false,
                needsAuth: action.data.needsAuth,
                updateAllowed: action.data.updateAllowed || false,
                message: action.data.message,
                authType: action.data.authType,
                linkId: action.answer.id,
                waitingTimeLeft: waitingTime,
                config: action.data.templateConfig !== null ? action.data.templateConfig.value : [],
                redirect: action.data.redirect,
                wizardState: action.wizardState || {isWizard: false, numberOfSteps: 1, currentStep: 1},
                additionalAnswerStateContext: action.additionalAnswerStateContext,
                tanToken: action.tanToken,
                isHiddePostfachLink : action.isHiddePostfachLink,
                scrollTo: (action.additionalAnswerStateContext?.shareElementid !== undefined && action.additionalAnswerStateContext.shareElementid !== null ? "notification-"+action.additionalAnswerStateContext.shareElementid : prevState.scrollTo)
            }
        case REMOVE_COMMENT_BUBBLE: 
            return {
                ...prevState,
                additionalAnswerStateContext: {shareMessage: undefined, shareElementid: undefined},
            }
        case SET_AUTHCONTEXT:
            return {
                ...prevState,
                authContext: action.value,
                needsAuth: action.needsAuth !== undefined ? action.needsAuth : prevState.needsAuth,
                isFetching: action.isFetching !== undefined ? action.isFetching : prevState.isFetching
            }
        case SAVED:
            return {
                ...prevState,
                errormessage: action.errorMsg || null,
                answerComplete: action.complete || false,
                shareDialogueContext: {
                    shareElementId: null,
                    visible: false,
                    shareMessage: null
                }
            }
        case INIT_DEPENDENCIES_AND_VISIBILITIES: {
            return {
                ...prevState,
                initDependencies: true
            }
        }
        case SET_DEPENDENCIES_AND_VISIBILITIES: {
            return {
                ...prevState,
                validationFunc: action.validationFunc !== undefined ? action.validationFunc : prevState?.validationFunc,
                dependencies: action.dependencies !== undefined ? action.dependencies : prevState?.dependencies,
                answer: action.answer !== undefined ? action.answer : prevState?.answer,
                initDependencies: action.initDependencies !== undefined ? action.initDependencies : prevState?.initDependencies,
                visible: action.visible,
                lastValueBeforeUnmount: action.lastValueBeforeUnmount !== undefined ? action.lastValueBeforeUnmount : prevState?.lastValueBeforeUnmount,
            }
        }
        case HANDLE_DEPENDENCIES:
            return {
                ...prevState,
                recalcDependency: action.recalcDependency,
                answer: action.answer !== undefined ? action.answer : prevState.answer,
                visible: action.visible !== undefined ? action.visible : prevState.visible,
                isProcessing: action.isProcessing !== undefined ? action.isProcessing : prevState.isProcessing,
                elementsUnmounting: action.elementsUnmounting !== undefined ? action.elementsUnmounting : prevState.elementsUnmounting,
                lastValueBeforeUnmount: action.lastValueBeforeUnmount !== undefined ? action.lastValueBeforeUnmount : prevState.lastValueBeforeUnmount,
            }
        case REMOVE_UNMOUNTING_ELEMENTS: {
            if (action.elementsUnmounting !== undefined && prevState.elementsUnmounting.length > 0) {
                let newUnmounting = prevState.elementsUnmounting.filter(el => action.elementsUnmounting.indexOf(el) === -1);
                return {
                    ...prevState,
                    elementsUnmounting: newUnmounting
                }
            } else {
                return {
                    ...prevState,
                }
            }
        }
        case UNMOUNT_WIZARD_STEP: {
            return {
                ...prevState,
                wizardState: {
                    ...prevState.wizardState,
                    isUnmounting: true
                }
            }
        }
        case WIZARD_NEXT_STEP:
            return {
                ...prevState,
                validationFunc: action.resetValidation ? new Map<string, any>() : prevState.validationFunc,
                wizardState: {
                    ...prevState.wizardState,
                    isUnmounting: false,
                    currentStep: action.value
                }
                ,saveValidationMessage : null
            }
        case WIZARD_PREVIOUS_STEP:
            return {
                ...prevState,
                validationFunc: action.resetValidation ? new Map<string, any>() : prevState.validationFunc,
                wizardState: {
                    ...prevState.wizardState,
                    isUnmounting: false,
                    currentStep: action.value
                }
                ,saveValidationMessage : null
            }
        case WIZARD_STEP_VALIDATION_ERROR:
            return {
                ...prevState,
                mandatoryValidationError: true,
                scrollTo: action.scrollTo,
                wizardState: {
                    ...prevState.wizardState,
                    currentStep: action.value
                }
            }
        case PRE_SAVE_VALIDATION_ERROR:
            return {
                ...prevState,
                scrollTo: action.scrollTo ? action.scrollTo : prevState.scrollTo,
            }
        case RESET_SCROLL_TO:
            return {
                ...prevState,
                scrollTo: undefined
            }
        case SHARE_MENU_STATE:
            return {
                ...prevState,
                shareDialogueContext: {
                    shareElementId: action.shareElementId,
                    visible: action.visible,
                    shareMessage: action.shareMessage
                }
        }        
        case SET_ADDITIONAL_SECURITY_TOKEN:
            return {
                ...prevState,
                additionalSecurityToken: action.additionalSecurityToken
            }
        case ADD_DISABLE_BUTTON: {
            // Prüfen, ob nicht schon vorhanden
            if (prevState.buttonDisabledBy.indexOf(action.value) < 0) {
                return {
                    ...prevState,
                    buttonDisabledBy: [...prevState.buttonDisabledBy, action.value],
                    hideDisabledButton: action.hideButton || false
                }
            } else {
                return {
                    ...prevState
                }
            }
        }
        case REMOVE_DISABLE_BUTTON: {
            // Prüfen, ob überhaupt vorhanden
            if (prevState.buttonDisabledBy.indexOf(action.value) > -1) {
                return {
                    ...prevState,
                    buttonDisabledBy: prevState.buttonDisabledBy.filter(obj => obj !== action.value)
                }
            } else {
                return {
                    ...prevState
                }
            }
        }
        default: {
            return {...prevState};
        }   
    }
}