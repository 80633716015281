import DOMPurify from 'dompurify';
import { AppState, DefaultProps } from '../../../types/types';
import { ReplacementItem } from './SuccessReplacementProvider';



export interface SuccessProps extends DefaultProps {
    appState: AppState,
    html?: any,
    container?: boolean,
    replacementList: ReplacementItem[];
}

/**
 * Diese Komponente wird angezeigt, wenn der
 * Link erfolgreich bearbeitet wurde.
 * 
 * @param props 
 * @returns 
 */
function Success(props: SuccessProps) {
    let url: string | undefined = props.appState?.customizing.homepage;
    let homepageTitle: string | undefined = props.appState?.customizing.homepageTitle;
    if (props.html !== undefined && props.html !== null) {
        // Text aus der Datenbank.
        let clean: string = (DOMPurify.sanitize(props.html, { ADD_ATTR: ['target'] }));
        if (props.replacementList?.length !== 0) {
            // Durchlaufe jedes Element in replacementList und ersetze key durch value
            props.replacementList.forEach(({ key, value }) => {
                const regex = new RegExp(key, 'g'); // globaler Regex für den key
                clean = clean.replace(regex, value);
            });
        }
        return <div dangerouslySetInnerHTML={{ __html: clean }} className={props?.container ? "success-container" : ""}></div>;
    } else return (
        <div className={props?.container ? "success-container" : ""}>
            <h3>Vielen Dank!</h3>
            <p>Wir haben Ihre Antwort erhalten und kümmern uns nun um alles Weitere.</p>
            {(url && homepageTitle) &&
                <p>Hier geht's zu unserer Homepage: <a href={url}>{homepageTitle}!</a> </p>
            }
        </div>
    );
}

export default Success

