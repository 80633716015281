import { log } from "./util";

export const KEY_APPLICATION_STORAGE = "LINKEDAPP_APPLICATION_STORAGE";
export const KEY_APPLICATION_SESSION = "LINKEDAPP_SESSION";
export const KEY_AUTH_CONTEXT = "authContext";
export const KEY_CUSTOMER_HISTORY = "customerHistory";

export const loadStorageData:any = (key: string) => {
	try {
		const serializedState = localStorage.getItem(KEY_APPLICATION_STORAGE);
		if (!serializedState) {
			return undefined;
		}
		
		return JSON.parse(serializedState)[key];
	} catch (error) {
		return undefined;
	}
};

export const saveStorageData:any = (key: string, value: any) => {
	try {
		let session:any = localStorage.getItem(KEY_APPLICATION_STORAGE);
		if (!session) {
			session = "{}";
		}
		let deserializedSession = JSON.parse(session);
		deserializedSession[key] = value;
		let serializedSession = JSON.stringify(deserializedSession);
		localStorage.setItem(KEY_APPLICATION_STORAGE, serializedSession);
	} catch (error) {
		log(error);
	}
};


export const loadSessionData:any = (key: string) => {
	try {
		const serializedState = sessionStorage.getItem(KEY_APPLICATION_SESSION);
		if (!serializedState) {
			return undefined;
		}
		
		return JSON.parse(serializedState)[key];
	} catch (error) {
		return undefined;
	}
};

export const saveSessionData:any = (key: string, value: any) => {
	try {
		let session:any = sessionStorage.getItem(KEY_APPLICATION_SESSION);
		if (!session) {
			session = "{}";
		}
		let deserializedSession = JSON.parse(session);
		deserializedSession[key] = value;
		let serializedSession = JSON.stringify(deserializedSession);
		sessionStorage.setItem(KEY_APPLICATION_SESSION, serializedSession);
	} catch (error) {
		log(error);
	}
};

export const clearSessionValue:any = (key: string) => {
		try {
			let session:any = sessionStorage.getItem(KEY_APPLICATION_SESSION);
			if (!session) {
				session = "{}";
			}
			let deserializedSession = JSON.parse(session);
			delete deserializedSession[key];
			let serializedSession = JSON.stringify(deserializedSession);
			sessionStorage.setItem(KEY_APPLICATION_SESSION, serializedSession);
		} catch (error) {
			log(error);
		}
};
