import { AnswerElement, DependencyComparisonOperator, DependencyOperator, GetLinkResponse, SaveLinkRequest } from "../../../types/apimodel";
import { log } from "../../../util/util";


/**
 * Erzeugt die initiale Liste der Abhängigkeiten der Elemente untereinander.
 * Initialisiert die Sichtbarkeits-Liste der Elemente.
 * 
 * @param initialAnswer 
 */
export const initVisibilityAndDependencies = function(initialAnswer : GetLinkResponse | null):[Map<string, Array<string>>, Map<string, boolean>] {
    // Dependencies eintragen in der Form: elementid hat dependencies zu elementid[]
    let dependencies:Map<string, string[]> = new Map<string, string[]>();
    let visibilities:Map<string, boolean> = new Map<string, boolean>();
    if (initialAnswer !== null && initialAnswer.answer !== null && initialAnswer.answer !== undefined) {
        // Den Werten aus den Dependencies muss dier eigene Elementid zugeordnet werden
        for (let i=0; i < initialAnswer.answer?.answerElement?.value?.length; i++) {
            log('initialisiere visible für element '+initialAnswer.answer?.answerElement.value[i].element.externalid+' mit true');
            visibilities.set(initialAnswer.answer.answerElement.value[i].element.externalid, true);
            let deps:number|undefined = initialAnswer.answer.answerElement.value[i].element.dependencyConfig?.dependencies.value.length;
            if (deps !== undefined && deps > 0) {
                let dependenciesForElement:string[] = [];
                for (let d=0; d < deps; d++) {                        
                    // Dubletten vermeiden!              
                    let dep = initialAnswer.answer.answerElement.value[i].element.dependencyConfig?.dependencies?.value[d];
                    if (dep !== undefined && dependenciesForElement.indexOf(dep.elementid) === -1){
                        dependenciesForElement.push(dep.elementid);
                    }
                }
                if (dependenciesForElement.length > 0) {
                    for (let d=0; d < dependenciesForElement.length; d++) {
                        let newDeps:string[] = dependencies.get(dependenciesForElement[d]) || [];
                        newDeps.push(initialAnswer.answer.answerElement.value[i].element.externalid);
                        dependencies.set(dependenciesForElement[d], newDeps);
                        log('adding dependency for '+dependenciesForElement[d]+": "+JSON.stringify(newDeps));
                    }
                }
            }
        }
    }
    return [dependencies, visibilities];
}

/**
 * Wertet die Dependencies aus und zeigt das Element entsprechend an
 * oder blendet es aus.
 * 
 * @param el 
 * @returns true wenn sichtbar, false wenn ausgeblendet
 */
export const evalVisibility = function(el:AnswerElement, answer : SaveLinkRequest | null):boolean {
    log('evalVisibility: '+el.element.externalid);
    // Dependency-Config laden
    if (el?.element?.dependencyConfig !== undefined && el?.element?.dependencyConfig !== null && el?.element?.dependencyConfig?.dependencies?.value !== null) {
        // UND-Verknüpfung
        let isAnd: boolean = (String(el.element.dependencyConfig.operator) === DependencyOperator[DependencyOperator.AND]);
        log('dependencies found: '+el?.element?.dependencyConfig?.dependencies?.value.length);
        for (let depIndex=0; depIndex < el.element.dependencyConfig.dependencies.value.length; depIndex++) {
            log('checking dependency '+(depIndex+1));
            let dep = el.element.dependencyConfig.dependencies.value[depIndex];
            // Wert (also eine eingegebene Antwort) im State suchen zur Dependency
            if (answer !== null) {
                for (let answerIndex=0; answerIndex < answer.answer.answerElement.value.length; answerIndex++) {
                    let comp = answer.answer.answerElement.value[answerIndex];
                    // TODO: Typen prüfen? Oder nur bei der Anlage?
                    if (comp.element.externalid === dep.elementid) {
                        // Achtung, wenn mehrere Values, dann klappt das nicht (komplexe Elemente).
                        let foundVal = comp?.input?.value[0]?.value;
                        log('Found dependency '+dep.elementid+" with value "+foundVal+". Comparing to "+dep.value);
                        // Prüfen, ob die am Element definierte Condition (EQUAL/NOT_EQUAL gegeben ist)
                        let conditionMet = false;
                        if ((String(dep.comparison) === DependencyComparisonOperator[DependencyComparisonOperator.EQUAL]) && foundVal === dep.value) {
                            conditionMet = true;
                        } 
                        if ((String(dep.comparison) === DependencyComparisonOperator[DependencyComparisonOperator.NOT_EQUAL]) && foundVal !== dep.value) {
                            conditionMet = true;
                        } 
                        if ((String(dep.comparison) === DependencyComparisonOperator[DependencyComparisonOperator.CONTAINS]) && foundVal !== undefined && foundVal?.indexOf(dep.value) > -1) {
                            conditionMet = true;
                        }
                        if ((String(dep.comparison) === DependencyComparisonOperator[DependencyComparisonOperator.EXISTS]) && foundVal !== undefined) {
                            conditionMet = true;
                        }
                        if ((String(dep.comparison) === DependencyComparisonOperator[DependencyComparisonOperator.NOT_EXISTS]) && (foundVal === undefined || foundVal !== '')) {
                            conditionMet = true;
                        }
                        log('conditionMet:' + conditionMet);
                        if (conditionMet === false && isAnd) {
                            log('return false');
                            return false;
                        }
                        if (conditionMet === true && !isAnd) {
                            log('return true');
                            return true;
                        }
                    }
                }
            }
        }
        if (isAnd) {
            log('ende: return true');
            return true;
        } else {
            log('ende: return false');
            return false;
        }
    }
    log('fallback: return true');
    return true;
}

export const updateLastValueBeforeUnmount = function(lastValueBeforeUnmount: Map<string, any>, elementsUnmounting:string[], answer : SaveLinkRequest):Map<string, any> {
    for (let e=0; e < elementsUnmounting.length; e++) {
        // Value des Elements suchen
        for (let i=0; i < answer.answer?.answerElement?.value?.length; i++) {
            if (elementsUnmounting[e] === answer?.answer.answerElement.value[i].element.externalid) {
                lastValueBeforeUnmount.set(elementsUnmounting[e], answer?.answer.answerElement.value[i].input);
            }
        }
    }
    return lastValueBeforeUnmount;
}
    /**
     * Berechnet die Sichtbarkeit der Elemente unter Betrachtung der Abhängigkeiten neu.
     * 
     * @param visible Sichtbarkeits-Map mit einem Boolean-Wert pro ElementID
     * @param startFrom neu Berechnen für ein bestimmtes Element und alle abhängigen Elemente.
     * @returns 
     */
     
export const recalcVisibility = function(visibleArg:Map<string, boolean>, answer : SaveLinkRequest, prevDependencies: Map<string, string[]>, startFrom?:string | null):[Map<string, boolean>, SaveLinkRequest] {
    if (answer === undefined || answer === null) {
        return [visibleArg, answer];
    }
    let visible:Map<string, boolean>;
    if (visibleArg === undefined) {
        visible = new Map<string, boolean>();
    } else {
        visible = new Map<string, boolean>(visibleArg);
    }
    // dependency-map holen und darüber iterieren
    let dependencies:Map<string, string[]> = new Map<string, string[]>(prevDependencies);
    let elementIds:string[] | undefined = [];
    // Liste der IDs von Elementen, die von irgendeinem (oder mehreren) anderen Element(en) abhängen, holen
    if (startFrom === undefined || startFrom === null) {
        // Alle Elemente
        log ('startFrom INIT: ' + startFrom);
        // Alle vorhandenden Elemente in die Liste einhängen
        elementIds = Array.from(dependencies.keys());
    } else {
        log ('startFrom TEILBAUM: ' + startFrom);
        // Teilbaum aktualisieren
        elementIds = dependencies.get(startFrom);
    }        
    log ('Visibility neu berechnen. Wir betrachten folgende Elemente, von denen '+startFrom+' abhängig ist: '+elementIds);
    if (elementIds === null || elementIds === undefined) {
        return [visible, answer];
    }
    // Über alle Elemente, die von irgendetwas abhängen, iterieren
    for (let e=0; e < elementIds.length; e++) {
        let currentElementId = elementIds[e];
        log ('currentElementId: '+currentElementId);
        // Nach dem EL mit der passenden ElementId in der Answer suchen
        for (let i=0; i < answer.answer?.answerElement?.value?.length; i++) {
            if (currentElementId === answer?.answer.answerElement.value[i].element.externalid) {
                // Sichtbarkeit neu berechnen
                let elementVisibility = evalVisibility(answer?.answer.answerElement.value[i], answer);
                log ('setze vis für currentElementId: '+elementVisibility);
                visible.set(currentElementId, elementVisibility);                
                // unsichtbare Werte aus der Antwort entfernen
                answer = discardInvisibleValues(visible, answer);
                // In den Teilbaum runtersteigen
                if (dependencies.get(currentElementId) !== null) {
                    log ('rufe rekursiv auf für : '+currentElementId);
                    // Rekursiver Aufruf für Teilbaum
                    [visible, answer] = recalcVisibility(visible, answer, dependencies, currentElementId);
                }
            }
        }
    }
    return [visible, answer];
}

/**
 * Validiert alle zur Zeit eingeblendeten Elemente.
 * @returns 
 */
export const validateVisibleElements = function(validationFunc: Map<string, any>):Promise<{valid: boolean, invalidElementIds: string[]}> {
    return new Promise((resolve) => {
        let isValid:boolean = true;
        let validationPromises:Promise<{valid: boolean, elementid: string}>[] = [];        
        let invalidElementIds: string[] = [];
        log('keys: '+JSON.stringify(Array.from(validationFunc.keys())));
        for (let key of Array.from(validationFunc.keys())) {
            validationPromises.push(
                //validationFunc.get(key)()
                new Promise((resolve) => {
                    validationFunc.get(key)().then((success:boolean) => resolve({valid: success, elementid: key}));
                })
            );
        }
        log('validationPromises.size '+validationPromises.length);
        // Wenn alle Promises durch sind, das Ergebnis prüfen.
        Promise.all(validationPromises).then((success) => {
            log('alle promises zurück');
            success.forEach(validationResult => {
                log(validationResult.elementid+': valid: '+(validationResult.valid));                
                if (false === validationResult.valid) {
                    invalidElementIds.push(validationResult.elementid);
                }
            });
            if (invalidElementIds.length > 0) {
                resolve({valid: false, invalidElementIds: invalidElementIds});
            }

            if (isValid === true) {
                resolve({valid: true, invalidElementIds: invalidElementIds});
            }
        });
    });
}

/**
 * Wenn Felder ausgeblendet werden, deren Werte wegwerfen
 * @param visible 
 * @param answer 
 * @returns 
 */
export const discardInvisibleValues = function(visible:Map<string, boolean>, answer:SaveLinkRequest):SaveLinkRequest {
    if (answer !== null && answer.answer && answer.answer.answerElement && answer.answer.answerElement.value) {
        for (let i=0; i < answer.answer.answerElement.value.length; i++) {
            let isVisible:boolean | undefined = visible.get(answer.answer.answerElement.value[i].element.externalid);
            if (isVisible === false) {
                log('discarding value from element '+answer.answer.answerElement.value[i].element.externalid);
                answer.answer.answerElement.value[i].input = {value:[]};
            }
        }
    }
    return answer;
}

    /**
     * 
     * Hängt aus der Map der Validierungsfunktionen (Schlüssel: elementID) diejenigen Validierungsfunktionen aus,
     * wo das Element gerade nicht sichtbar ist.
     * 
     * @param validationFromState 
     * @param visible 
     * @returns 
     */
export const recalcValidationFuncs = function(validationFromState: Map<string, any>, visible:Map<string, boolean>):Map<string, any> {
    let validationFunc: Map<string, any> = validationFromState;
    let elementIds:string[] = Array.from(visible.keys());
    log('validationFunc: '+JSON.stringify(validationFunc));
    log('visible: '+JSON.stringify(visible));
    for (let i=0; i < elementIds.length; i++) {            
        if (visible.get(elementIds[i]) === false && validationFunc.get(elementIds[i]) !== undefined) {
            // vorhanden, aushängen
            validationFunc.delete(elementIds[i]);
        }
    }
    return validationFunc;
}
