import { InputLabel, TextField, Grid, Hidden } from '@mui/material';
import React from 'react';
import { Address, SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';

interface AnswerElementAddressProps extends AnswerElementDefaultProps {
    type: string | 'address',
}

interface AnswerElementAddressState extends AnswerElementDefaultState {
    value: Address,
    errorStreet: string | null,
    errorNumber: string | null,
    errorPostcode: string | null,
    errorCity: string | null,
    errorCountry: string | null
}

/**
 * 
 * Stellt eine Auswahlmöglichkeit bereit (entweder als Radiobutton (type="radio") oder als Dropdown (type="select")), aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */
class AnswerElementAddress extends React.Component<AnswerElementAddressProps, AnswerElementAddressState> {
    
    constructor(props:any) {
        super(props);
        let value:Address = {
            city: '',
            country: 'Deutschland',
            number: '',
            postcode: '',
            street: ''
        };
        if (this.props.value != null) {
            try {
                value = JSON.parse(this.props.value);
            } catch (e) {

            }
        }
        this.state = {value: value, error: null, errorStreet:null, errorNumber:null, errorPostcode: null, errorCity: null, errorCountry: null};
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.keyPress(this.keyPress.bind(this));
    }

    /**
     * Validierungsnachricht
     * @returns 
     */
    getErrorMessage(field:string) {
        if (this.state) {            
            let error:string|null = null;
            if (field === "street") {
                error = this.state.errorStreet;
            }
            if (field === "number") {
                error = this.state.errorNumber;
            }
            if (field === "postcode") {
                error = this.state.errorPostcode;
            }
            if (field === "city") {
                error = this.state.errorCity;
            }
            if (field === "country") {
                error = this.state.errorCountry;
            }
            if (error !== null) {
                return (<p className="MuiFormHelperText-root validation-error">{error}</p>);
            }
        }
    }


    render() {    
        let errorMsgStreet = this.getErrorMessage("street");
        let errorMsgNumber = this.getErrorMessage("number");
        let errorMsgPostcode = this.getErrorMessage("postcode");
        let errorMsgCity = this.getErrorMessage("city");
        let errorMsgCountry = this.getErrorMessage("country");
        // z.B. "Adresse des Versicherungsnehmers"
        let label = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL]);
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    <Grid container spacing={3}>
                        <Grid item md={12} lg={12} xs={12}>
                            <InputLabel id="label">{label}</InputLabel><br />
                        </Grid>
                        <Grid item md={6} xs={12} lg={6}>
                            <TextField label="Straße" type="text" value={this.state.value?.street} required={this.props.el.mandatory}
                                onChange={(event) => this.handleChange(event, "street")} id={this.props.el.externalid} 
                                error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress}
                                className="stretch-100" />
                            {errorMsgStreet}
                        </Grid>
                        <Grid item md={2} xs={12} lg={2}>
                            <TextField label="Hausnr." type="text" value={this.state.value?.number} required={this.props.el.mandatory}
                                onChange={(event) => this.handleChange(event, "number")} id={this.props.el.externalid} 
                                error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress}
                                className="stretch-100" />
                            {errorMsgNumber}
                        </Grid>
                        <Hidden lgDown>
                            <Grid item md={4} xs={false} lg={4}></Grid>
                        </Hidden>
                        <Grid item md={2} xs={12} lg={2}>
                            <TextField label="Postleitzahl" type="text" value={this.state.value?.postcode} required={this.props.el.mandatory}
                                onChange={(event) => this.handleChange(event, "postcode")} id={this.props.el.externalid} 
                                error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress}
                                className="stretch-100" />
                            {errorMsgPostcode}
                        </Grid>
                        <Grid item md={6} xs={12} lg={6}>
                            <TextField label="Ort" type="text" value={this.state.value?.city} required={this.props.el.mandatory}
                                onChange={(event) => this.handleChange(event, "city")} id={this.props.el.externalid} 
                                error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress}
                                className="stretch-100" />
                            {errorMsgCity}
                        </Grid>
                        <Hidden lgDown>
                            <Grid item md={4} xs={false} lg={4}></Grid>
                        </Hidden>
                        <Grid item md={8} xs={12} lg={8}>
                            <TextField label="Land" type="text" value={this.state.value?.country} required={this.props.el.mandatory}
                                onChange={(event) => this.handleChange(event, "country")} id={this.props.el.externalid} 
                                error={(this.state.error && this.state.error !== null) || false} helperText={this.state.error} size="medium" disabled={this.props.readonly} onKeyPress={this.keyPress} 
                                className="stretch-100" />
                            {errorMsgCountry}
                        </Grid>
                        <Hidden lgDown>
                            <Grid item md={4} xs={false} lg={4}></Grid>
                        </Hidden>
                    </Grid>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

        /**
     * Benötigt, weil ansonsten im Eingabefeld beim Druck auf Enter nicht onBlur() gefeuert und damit 
     * die Validierung und die Übernahme des Wertes nicht funktionieren.
     * 
     * @param event 
     * 
     */
    keyPress(event:any):any {
        if(event.key === 'Enter'){
            event.target.blur(); 
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig():SelectItem[] {
        let items:SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {
            console.log('Validating!');

            let errorStreet = null;
            let errorNumber = null;
            let errorPostcode = null;
            let errorCity = null;
            let errorCountry = null;

            let regexp = new RegExp("^[0-9]{5}$");

            if (this.props.el.mandatory === true && (this.state.value === null 
                || this.state.value?.city === null || this.state.value?.city?.length < 1 
                || this.state.value?.country === null || this.state.value?.country?.length < 1
                || this.state.value?.number === null || this.state.value?.number?.length < 1 
                || this.state.value?.postcode === null || !regexp.test(this.state.value?.postcode) 
                || this.state.value?.street === null || this.state.value?.street?.length < 1)) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
                if (this.state.value?.country === null || this.state.value?.country?.length < 1) {
                    errorCountry = "Bitte geben Sie ein Land an!";
                }
                if (this.state.value?.number === null || this.state.value?.number?.length < 1 ) {
                    errorNumber = "Bitte geben Sie eine Hausnummer an!";
                }
                let regexp = new RegExp("^[0-9]{5}$");
                if (this.state.value?.postcode === null || !regexp.test(this.state.value?.postcode)) {
                    errorPostcode = "Bitte geben Sie eine fünfstellige Postleitzahl an!";
                }
                if (this.state.value?.street === null || this.state.value?.street?.length < 1) {
                    errorStreet = "Bitte geben Sie einen Straßennamen an!";
                }
                if (this.state.value?.city === null || this.state.value?.city?.length < 1) {
                    errorCity = "Bitte geben Sie einen Ort an!";
                }
                if (valiMsgMandatory) {
                    this.setState({error: valiMsgMandatory, errorStreet: errorStreet, errorNumber: errorNumber, errorPostcode: errorPostcode, errorCity: errorCity, errorCountry: errorCountry}, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({errorStreet: errorStreet, errorNumber: errorNumber, errorPostcode: errorPostcode, errorCity: errorCity, errorCountry: errorCountry}, () => {
                        resolve(false);
                    });
                }
            } else {
                this.setState({errorStreet: null, errorNumber: null, errorPostcode: null, errorCity: null, errorCountry: null}, () => {
                    resolve(true);                    
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(event: any, field:string) {
        let address = this.state.value;
        if (field === 'street') {
            address.street = event.target.value;
        }
        if (field === 'number') {
            address.number = event.target.value;
        }
        if (field === 'city') {
            address.city = event.target.value;
        }
        if (field === 'country') {
            address.country = event.target.value;
        }
        if (field === 'postcode') {
            address.postcode = event.target.value;
        }
        this.setState({value: address}, () => {
            this.props.handleChange(JSON.stringify(this.state.value), this.props.el.externalid);
        });
    }

}
export default AnswerElementAddress