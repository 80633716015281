import { LinkedAppJSONHTTPBinding } from "../types/restapi";
import {
    GetLinkRequest,
    GetLinkResponse,
    SaveLinkRequest,
    AuthContext,
    GetDeferredLinkPartRequest,
    GetDeferredLinkPartResponse,
    GetShortLinkResponse,
    GetShortLinkRequest,
    SaveLinkTemporaryRequest,
    SendContactConfirmationResponse,
    SendContactConfirmationRequest,
    ContactType,
    GetContactConfirmationResponse,
    GetContactConfirmationRequest,
    ArrayOfKeyValue,
    ElementType,
    AuthKey,
    AnswerStateContext,
    AnswerStateContextKey,
    SaveContactConfirmationRequest,
    SaveContactConfirmationResponse,
    GetFieldValidationResultRequest,
    ClientFieldValidationResult,
    SendTransactionConfirmationRequest,
    ExtendedAuthKey,
    GetContactInformationConfirmedResponse,
    GetContactInformationConfirmedRequest,
    CheckAnswerCompleteRequest, 
    CheckAnswerCompleteResponse,
    GetKundenpostfachLinkResponse,
    GetKundenpostfachLinkRequest,
    SendTosAcceptedRequest,
    IsTosAcceptedRequest,
    IsTosAcceptedResponse
} from "../types/apimodel";
import { generateCorrelationId, log, deepCopy } from "../util/util";

const baseURL : string = String(process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT);

const LinkedappBackend = {

    getLink: function(linkId:string, authContext:AuthContext): Promise<GetLinkResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetLinkRequest = {
            id : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId()
        };
        return backend.getLink(request);
    },

    checkAnswerComplete: function(linkId:string, authContext:AuthContext): Promise<CheckAnswerCompleteResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : CheckAnswerCompleteRequest = {
            id : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId()
        };
        return backend.checkAnswerComplete(request);
    },

    getKundenakteRedirectURL: function(): Promise<string> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        return backend.getKundenakteRedirectURL();
    },

    getShortLink: function(shortLinkId:string): Promise<GetShortLinkResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetShortLinkRequest = {
            id : shortLinkId,
            correlationId: generateCorrelationId()
        };
        return backend.getShortLink(request);       
    },

    getDeferredLinkPart: function(linkId:string, templateElementExternalId:string, authContext:AuthContext): Promise<GetDeferredLinkPartResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetDeferredLinkPartRequest = {
            id : linkId,
            templateElementExternalId: templateElementExternalId,
            authContext: authContext,
            correlationId: generateCorrelationId()
        };
        return backend.getDeferredLinkPart(request);
    },

    sendContactConfirmation(linkId:string, authContext:AuthContext, contactType:ContactType, contact:string): Promise<SendContactConfirmationResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : SendContactConfirmationRequest = {
            linkId : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId(),
            contactType: contactType,
            contact: contact
        };
        return backend.sendContactConfirmation(request);
    },

    getContactConfirmation: function(linkId:string, authContext:AuthContext, contactType: ContactType): Promise<GetContactConfirmationResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetContactConfirmationRequest = {
            linkId : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId(),
            contactType: contactType
        };
        return backend.getContactConfirmation(request);
    },


    pullKundenakteLink: function(linkId:string, authContext:AuthContext): Promise<GetKundenpostfachLinkResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetKundenpostfachLinkRequest = {
            linkId : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId()
        };
        return backend.pullKundenakteLink(request);
    },

    getContactInfoConfirmed: function(linkId:string, authContext:AuthContext): Promise<GetContactInformationConfirmedResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : GetContactInformationConfirmedRequest = {
            linkId : linkId,
            authContext: authContext,
            correlationId: generateCorrelationId()
        };
        return backend.getContactInfoConfirmed(request);
    },

    saveContactConfirmation: function(linkId:string, token: string): Promise<SaveContactConfirmationResponse> {
        let backend = new LinkedAppJSONHTTPBinding(baseURL);
        let request : SaveContactConfirmationRequest = {
            linkId: linkId,
            token: token,
            correlationId: generateCorrelationId(),
        };
        return backend.saveContactConfirmation(request);
    },

    saveLink: function(answer: SaveLinkRequest): Promise<any> {
        return new Promise((resolve,reject) => {
            let backend = new LinkedAppJSONHTTPBinding(baseURL);
            let request : SaveLinkRequest = deepCopy(answer);
            // Rückantwort ein bisschen kleiner halten
            let emptyTemplateConf: ArrayOfKeyValue = {
                value: []
            };
            request.answer.answerElement.value.forEach(el => {
                el.element.templateElementConfig = emptyTemplateConf;
                el.element.informationtext = '';
                el.element.htmlAfter = '';
                el.element.templateElementConfig = {value:[]};
                // Ungetestet, unbenutzt: Falls ein eingebettetes PDF vorhanden war, dieses hier entfernen.
                if (String(el.element.type) === ElementType[ElementType.DOCUMENT_DOWNLOAD] && true !==  el.element.deferred) {
                    el.input = {value: []};
                }
            });
            if (request !== null) {
                log('request savelink: '+JSON.stringify(request));
                backend.saveLink(request).then((data) => {
                    log('response of savelink: '+JSON.stringify(data));
                    if (data !== undefined && data !== null) {
                        log('response of savelink (inner): '+JSON.stringify(data));
                        if (data.success){
                            resolve({complete: true, errorMsg:null, valid:data.valid});
                        } else {
                            log('Save nicht valid');
                            reject({complete: false, errorMsg:data.message, valid:data.valid});
                        }
                    } 
                }).catch(error => {
                    log('error in savelink: '+JSON.stringify(error));
                    let errorMsg = error.message ? error.message : 'Leider ist ein Fehler beim Speichern aufgetreten';
                    if (error.details) {
                        errorMsg += ': '+error.details;
                    }                    
                    errorMsg += '. Wir haben den Fehler zu folgender ID protokolliert: '+request.correlationId + '.';
                    resolve({complete: false, errorMsg: errorMsg});
                });
            }
        });
    },

    sendTransactionConfirmation: function(linkid:string, tanType: ExtendedAuthKey):Promise<any> {
        return new Promise((resolve) => {
            let backend = new LinkedAppJSONHTTPBinding(baseURL);
            let request: SendTransactionConfirmationRequest = {
                linkId: linkid,
                correlationId: generateCorrelationId(),
                tanType: tanType
            }
            backend.sendTransactionConfirmation(request).then((data) => {
                log('response of sendTransactionConfirmation: '+JSON.stringify(data));
                resolve({success: data.success});
            }).catch(error => {
                resolve({success: false});
            });
        });
    },



    
    sendTosAccepted: function(request:SendTosAcceptedRequest):Promise<any> {
        return new Promise((resolve) => {
            let backend = new LinkedAppJSONHTTPBinding(baseURL);
           
            backend.sendTosAccepted(request).then((data) => {
                log('response of sendTosAccepted: '+JSON.stringify(data));
                resolve({success: data.success});
            }).catch(error => {
                resolve({success: false});
            });
        });
    },
    

    sendIsTosAccepted: function(request:IsTosAcceptedRequest):Promise<IsTosAcceptedResponse> {
            let backend = new LinkedAppJSONHTTPBinding(baseURL);
            return backend.sendIsTosAccepted(request);
    },


    saveLinkTemporary: function(shareElementId:string|null, shareMessage: string|null, answer: SaveLinkRequest | null, authContextParam?: AuthContext, additionalSecurityToken?:string):Promise<any> {
        return new Promise((resolve) => {
            let backend = new LinkedAppJSONHTTPBinding(baseURL);
            if (answer !== undefined && answer !== null) {
                let authContext: AuthContext | null | undefined = authContextParam;
                authContext?.value.value.push({key: AuthKey.POS_SECURITY_TOKEN, value: additionalSecurityToken || ''});
                let answerStateContext:AnswerStateContext = {
                    value: {
                        value: []
                    }
                }
                if (shareElementId !== null) {
                    answerStateContext.value.value.push(
                        {
                            key: AnswerStateContextKey.HIGHLIGHT_ELEMENT,
                            value: shareElementId
                        }
                    );
                }
                if (shareMessage !== null) {
                    answerStateContext.value.value.push(
                        {
                            key: AnswerStateContextKey.CUSTOMER_MESSAGE_FOR_ELEMENT,
                            value: shareMessage
                        }
                    );
                }
                let request : SaveLinkTemporaryRequest = {
                    answer: answer.answer,
                    authContext: authContext,
                    correlationId: answer?.correlationId,
                    id: answer?.id,
                    answerStateContext: answerStateContext
                }
                // Rückantwort ein bisschen kleiner halten
                let emptyTemplateConf: ArrayOfKeyValue = {
                    value: []
                };
                request.answer.answerElement.value.forEach(el => {
                    el.element.templateElementConfig = emptyTemplateConf;
                    el.element.informationtext = '';
                    el.element.htmlAfter = '';
                    el.element.templateElementConfig = {value:[]};
                // Ungetestet, unbenutzt: Falls ein eingebettetes PDF vorhanden war, dieses hier entfernen.
                    if (String(el.element.type) === ElementType[ElementType.DOCUMENT_DOWNLOAD] && true !==  el.element.deferred) {
                        el.input = {value: []};
                    }
                });
                if (request !== null) {                
                    log('request savelink: '+JSON.stringify(request));
                    backend.saveLinkTemporary(request).then((data) => {
                        log('response of savelink: '+JSON.stringify(data));
                        if (data !== undefined && data !== null) {
                            if (data.success === false) {
                                let errorMsg = data.message ? data.message : 'Leider ist ein Fehler beim Zwischenspeichern aufgetreten';
                                //dispatch({type: SAVED, complete: false, errorMsg: errorMsg});
                                resolve({complete: false, errorMsg: errorMsg});
                            } else {
                                log('response of savelink (inner): '+JSON.stringify(data));
                                resolve({complete: true});
                                //dispatch({type: SAVED, complete: true});
                            }
                        } 
                    }).catch(error => {
                        log('error in savelink: '+JSON.stringify(error));
                        let errorMsg = error.message ? error.message : 'Leider ist ein Fehler beim Zwischenspeichern aufgetreten';
                        if (error.details) {
                            errorMsg += ': '+error.details;
                        }                    
                        errorMsg += '. Wir haben den Fehler zu folgender ID protokolliert: '+request.correlationId + '.';
                        resolve({complete: false, errorMsg: errorMsg});
                        //dispatch({type: SAVED, complete: false, errorMsg: errorMsg});
                    });
                }
            }
        });
    },

    getFieldValidationResult: function(linkId: string, elementId: string, value: string, answer: SaveLinkRequest | null, authContext:AuthContext): Promise<ClientFieldValidationResult> {
        return new Promise((resolve) => {
            let result : ClientFieldValidationResult = {
                valid: true,
                error: undefined
            };
            if (answer !== undefined && answer !== undefined && answer?.answer !== null && answer?.answer !== undefined) {
                let backend = new LinkedAppJSONHTTPBinding(baseURL);
                let request : GetFieldValidationResultRequest = {
                    linkId: linkId,
                    correlationId: generateCorrelationId(),
                    authContext: authContext,
                    elementId: elementId,
                    value: value,
                    answer: answer?.answer
                };
                backend.getFieldValidationResult(request).then((res) => {
                    resolve(res.result);
                }).catch((error) => {
                    result.valid = false;
                    result.error = "Ihre Eingabe kann nicht validiert werden.";
                    resolve(result);
                });
            } else {
                resolve(result);
            }
        });
    },

};

export default LinkedappBackend;
