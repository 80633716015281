import React, {useContext, useReducer, useState, useEffect} from 'react';
import {answerReducer, HANDLE_DEPENDENCIES, IS_PROCESSING, SET_ANSWER_COMPLETE} from '../../../pages/answerLink/AnswerReducer';
import { AnswerElement, ElementType, SaveLinkRequest } from '../../../types/apimodel';
import { AnswerState } from '../../../types/types';
import { deepCopy, generateCorrelationId, log, setAddressValueOnAnswerElement, setMultiChoiceValueOnAnswerElement, setMultiFileValueOnAnswerElement, setSimpleValueOnAnswerElement } from '../../../util/util';
import LinkedappBackend from '../../../services/LinkedappBackend';

/**
 * Diese Kontext hält den Antwort-State.
 */

// *** Answer Context Type
type AnswerCtxType = {
    state: AnswerState,
    dispatch: any,
    handleChange: any
}

// *** init Answer Context

// Der initiale State des Reducers
const initialState: AnswerState = {
    initDependencies: null,
    recalcDependency: null,
    visible: new Map<string, boolean>(),
    lastValueBeforeUnmount: new Map<string, string>(),
    buttonDisabledBy: [],
    hideDisabledButton: false,
    dependencies: new Map(),
    errormessage: null,
    errors: [],
    answer: null,
    link: null,
    contact: null,
    answerComplete: false,
    isReadonly: false,
    isFetching: true,
    validationFunc: new Map(),
    wasAlreadyAnswered: false,
    needsAuth: false,
    updateAllowed: false,
    authType: null,
    message: null,
    linkId: null,
    isProcessing: false,
    isSaving: false,
    waitingTimeLeft: 0,
    config: [],
    revalidate: 0,
    authContext: { value: { value: [] } },
    wizardState: { isWizard: false, numberOfSteps: 1, currentStep: 1, isUnmounting: false },
    shareDialogueContext: { visible: false, shareMessage: null, shareElementId: null },
    elementsUnmounting: [],
    saveValidationMessage: null,
    isHiddePostfachLink : false
};

// *** Create Context
export const AnswerCtx = React.createContext<AnswerCtxType | undefined>(undefined);

// *** Answer Provider
export const AnswerProvider: React.FC = ({ children }) => {

    // *************************
    // State Object / Operation
    // *************************
    const [state, dispatch] = useReducer(answerReducer, initialState);
    const [linkAnswerCompletePolling, setLinkAnswerCompletePolling] = useState(false);

    // Hört auf Änderungen der Kinder-Objekte
    const handleChange = function (value: string, externalId: string) {
        dispatch({ type: IS_PROCESSING });
        // Jetzt die Änderungen aus el in das State-Objekt übernehmen
        if (state.answer && state.answer.answer && state.answer.answer.answerElement && state.answer.answer.answerElement.value) {
            // Das zu aktualisierende AnswerElement im State anhand der externalId des TemplateElements finden.
            let foundEl: AnswerElement | undefined = state.answer.answer.answerElement.value.find(element => element.element.externalid === externalId);
            // Wert aktualisieren
            if (foundEl !== undefined) {
                let el: AnswerElement = deepCopy(foundEl);
                // Wert setzen
                if (String(el.element.type) === ElementType[ElementType.FILE_UPLOAD]) {
                    setMultiFileValueOnAnswerElement(value, el);
                } else if (String(el.element.type) === ElementType[ElementType.MULTI_CHOICE]) {
                    setMultiChoiceValueOnAnswerElement(value, el);
                } else if (String(el.element.type) === ElementType[ElementType.DATA_INPUT_ADDRESS]) {
                    setAddressValueOnAnswerElement(value, el);
                } else {
                    setSimpleValueOnAnswerElement(value, el);
                    //setDependentInputField(value, el, state);
                }
                let newAnswer: SaveLinkRequest = {
                    id: state.answer.id,
                    answer: state.answer.answer,//deepCopy(this.state.answer.answer),
                    correlationId: generateCorrelationId(),
                    authContext: state.authContext,

                }
                log('newstate: ' + JSON.stringify(state));
                newAnswer.answer.answerElement.value.forEach(element => {
                    if (element.element.externalid === el.element.externalid) {
                        element.input = deepCopy(el.input);
                    }
                });
                // Antwort des Elements in den State übernehmen.
                // Dann abhängige Elemente neu berechnen: ID des geänderten Elements übergeben, dann useeffect auf die ID in Answer.tsx
                dispatch({ type: HANDLE_DEPENDENCIES, recalcDependency: externalId, answer: newAnswer, isProcessing: false })
            }
            log('newstate: ' + JSON.stringify(state));
        }
    }

    useEffect(() => {
        const checkAnswerComplete = () => {
            if(state?.linkId && !state?.answerComplete && !state?.link?.expired && !state?.link?.updateAllowed === true) {
                LinkedappBackend.checkAnswerComplete(state?.linkId, state?.authContext).then((data) => {
                    log("Answer complete", `${data?.answered}`)
                    if(!data?.answered) {
                        setTimeout(() => {
                            checkAnswerComplete();
                        }, 5000);
                    }
                    else {
                        if(!state?.answerComplete && !state?.link?.expired) {
                            dispatch({ type: SET_ANSWER_COMPLETE, answerComplete: true});
                        }
                    }
                });
            }
        }

        if(!linkAnswerCompletePolling) {
            checkAnswerComplete();
            setLinkAnswerCompletePolling(false);
        }
    }, [linkAnswerCompletePolling, state?.answerComplete, state?.linkId, state?.authContext, state?.link?.expired, state?.link?.updateAllowed]);

    return <AnswerCtx.Provider value={{
        state,
        dispatch,
        handleChange
    }}>
        {children}
    </AnswerCtx.Provider >
}

// *******************
// React Context
// *******************
const useAnswerCtx = () => {
    const answerContext = useContext(AnswerCtx);
    if (!answerContext)
        throw new Error(
            'Kein AnswerCtx.Provider gefunden beim Aufruf von useAnswerCtx.'
        );
    return answerContext;

}

/*
Searches for dependent input fields and sets the input if necessary

function setDependentInputField(value: string, el: AnswerElement, state: AnswerState): AnswerState {
    let depElementID = el.element.externalid;
    // Nur bei Elementen mit DependencyComparisonOperator.EXISTS
    let elementToChangeInput: AnswerElement | undefined = state?.answer?.answer.answerElement.value.find(element => element.element.dependencyConfig?.dependencies.value[0].elementid === depElementID);
    // Nur bei E-Mail und Mobil Listen
    let comp = elementToChangeInput?.element.dependencyConfig?.dependencies?.value[0].comparison;
    if (comp?.toString() === DependencyComparisonOperator[DependencyComparisonOperator.EXISTS]) {
        if (String(elementToChangeInput?.element.type) === ElementType[ElementType.EMAIL_INPUT_WITH_VALIDATION] || String(elementToChangeInput?.element.type) === ElementType[ElementType.PHONE_INPUT_WITH_VALIDATION]) {
            if (elementToChangeInput) {
                // if (el.input?.value[0].value && el.input?.value[0].value !== "") {

                if (el.input?.value[0].value && el.input?.value[0].value != "NOENTRY") {
                    //console.log("Copying input from " + depElementID + " to " + elementToChangeInput?.element.externalid + " with value: " + el.input?.value[0].value);

                    setSimpleValueOnAnswerElement(el.input?.value[0].value, elementToChangeInput);
                }
                else {
                    setSimpleValueOnAnswerElement("", elementToChangeInput);
                }
            }
        }
    }
    return state;
}
*/
export default useAnswerCtx;


