import { FormControlLabel, InputLabel, Checkbox } from '@mui/material';
import React from 'react';
import { SelectItem, TemplateElementConfigKey } from '../../../../types/apimodel';
import { AnswerElementDefaultState, AnswerElementDefaultProps } from '../../../../types/types';
import { findValueInConfig } from '../../../../util/util';

interface AnswerElementMultiChoiceProps extends AnswerElementDefaultProps {
    type: string | 'select',
}

interface AnswerElementMultiChoiceState extends AnswerElementDefaultState {
    value: string[] | null,
    choices: SelectItem[]
}

/**
 * 
 * Stellt eine Mehrfach-Auswahlmöglichkeit als Checkboxen bereit, aus der der Benutzer auswählen kann.
 * Die Liste der Auswahlmöglichkeiten wird als als JSON serialisiertes SelectItems-Array hereingereicht.
 * 
 */
class AnswerElementMultiChoice extends React.Component<AnswerElementMultiChoiceProps, AnswerElementMultiChoiceState> {
    
    constructor(props:any) {
        super(props);
        let value:string[] = [];
        if (this.props.value != null) {
            try {
                value = JSON.parse(this.props.value);
            } catch (e) {

            }
        }
        let choices:SelectItem[] = this.findChoicesInConfig();
        this.state = {value: value, error: null, choices: choices};
        this.handleChange = this.handleChange.bind(this);
        this.registerValidation = this.registerValidation.bind(this);
        this.registerValidation(this.validate.bind(this));
        this.checked = this.checked.bind(this);
    }

    /**
     * In Material-UI ist keine Validierungsnachricht für Checkboxen vorgesehen.
     * Wir wollen aber trotzdem, dass bei einer nicht ausgefüllten Pflich-Checkbox eine Nachricht angezeigt wird.
     * 
     * @returns 
     */
    getErrorMessage() {
        if (this.state && this.state.error !== null && (this.state.value === null || this.state.value.length < 1)) {
            return (<p className="MuiFormHelperText-root validation-error">{this.state.error}</p>);
        }
    }

    checked(value:string):boolean {
        if (this.state !== null && this.state.value !== null) {
            return this.state.value.indexOf(value) > -1;
        } 
        return false;
    }

    render() {    
        let errorMsg = this.getErrorMessage();
        let label = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.LABEL]);
        if (this.state && this.props.el) {
            return (
                <div className='control-wrapper'>
                    <InputLabel id={this.props.el.externalid+"-label"}>{label}</InputLabel><br />
                        {this.state.choices.map(element => {
                            return (                                
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.checked(element.value)}
                                        onChange={() => this.handleChange(element.value)}
                                        color="primary"
                                        disabled={this.props.readonly}
                                        />
                                    }
                                    key={element.value}
                                    label={element.label}
                                    className="multicheckitem"
                                />
                            );
                        })}
                    {errorMsg}
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    /**
     * Sucht einen Wert zum übergebenen Key in der Config des TemplateElements.
     * 
     * @param key 
     */
    findValueInConfig(key:string):string {
        return findValueInConfig(this.props.el, key);
    }

    findChoicesInConfig():SelectItem[] {
        let items:SelectItem[] = [];
        let choiceArrayAsString = findValueInConfig(this.props.el, TemplateElementConfigKey[TemplateElementConfigKey.SELECT_ITEMS]);
        try {
            items = JSON.parse(choiceArrayAsString);
        } catch (e) {
            console.log('Items konnten nicht geparsed werden!');
        }
        return items;
    }

    /**
     * Validierung des Inputs (hier nur beim Absenden des Formulars).
     * 
     */
     validate():Promise<boolean> {
        return new Promise((resolve) => {
            // todo: if mandatory
            if (this.props.el.mandatory === true && (this.state.value === null || this.state.value.length < 1)) {
                let valiMsgMandatory = this.findValueInConfig(TemplateElementConfigKey[TemplateElementConfigKey.VALI_MSG_MANDATORY]);
                if (valiMsgMandatory) {
                    this.setState({error: valiMsgMandatory}, () => {
                        resolve(false);
                    });
                } else {
                    this.setState({error: 'Bitte wählen Sie einen Wert aus der Liste aus!'}, () => {
                        resolve(false);
                    });
                }
            } else {
                this.setState({error: null}, () => {
                    resolve(true);                    
                });
            }
        });
    }

    /**
     * Registriert den Validator bei der Parent-Komponente,
     * so dass diese ihn aus dem Formular aufrufen kann (z.B. bei Submit).
     * 
     * @param validationFunction 
     */
    registerValidation(validationFunc:any):any {
        this.props.registerValidation(validationFunc);
    }

    /**
     * Macht wieder ein Key-Value-Paar aus dem simplen Feld und packt ihn an das AnswerElement (Feld "input").
     * Delegiert die Änderung nachher ans Parent-Element.
     * 
     * @param event 
     */
    handleChange(value:string) {
        let values = this.state.value;
        if (values !== null && values !== null) {
            let index = values.indexOf(value);
            if (index > -1) {
                values.splice(index,1);
            } else {
                values.push(value);
            }
        } 
        this.setState({value: values});
        this.props.handleChange(JSON.stringify(values), this.props.el.externalid);
    }

}
export default AnswerElementMultiChoice