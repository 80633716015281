import { AppState, DefaultProps } from "../../../types/types"

export interface ConfirmSuccessProps extends DefaultProps {
    appState:AppState
}

function ConfirmSuccess(props:ConfirmSuccessProps) {
    return (
        <>
                <h3>Vielen Dank für Ihre Bestätigung!</h3>
                <p>Sie können dieses Fenster nun schließen.</p>
        </>
    );
}

export default ConfirmSuccess